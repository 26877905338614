import serve from '@/utils/request/http.js'

export function queryKeyLearnDirectoryInfo(params) {
    return serve({
        url: '/refinedNotes/queryKeyLearnDirectory',
        method: 'get',
        params
    })

}
export function getSubjectImgPath(params) {
    return serve({
        url: '/subject/getSubjectImgPath?' + params,
        method: 'get',
    })

}

