<template>
  <div class="body">
    <div class="header">
      <div class="">
                <img src="@/utils/img/logo.png" @click="Returns()"  style="width: 150px;height: 66px;">
            </div>
     <!-- <el-dropdown  style="float: right;">
            <span class="el-dropdown-link" @click="handleCommand()">
              <el-avatar icon="el-icon-back"></el-avatar>
            </span>
          </el-dropdown>-->
    </div>
    <div class="conter">
      <div class="conteritme">
        <span class="conteritme_span1">{{userData.account}}</span><br />
        <span class="conteritme_span2">{{userData.id}}</span>
      </div>
      <div class="logindiv">
        <div class="swiper">
          <h4 style="font-size: 25px;margin: 0 30px;">Exam Board-Level-Subject</h4>
          <div style=" width: 98%;">          <div
              v-for="(item, index) in CourseHome"
              :key="index.chapterId"
              class="box"
            >
              <img
                :src="'https://genztutoringnlabs.com/service/' + item.chapterImgUrl"
                @click="chose(item.chapterId,item.chapterName)"
              />
              <h5>{{ item.chapterName }}</h5>
            </div></div>

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage"  :page-size="pageSize"
                layout=" prev, pager, next" :total="totalCount" style="text-align: right;">
            </el-pagination>
            </div>

        </div>

      </div>
        </div>
</template>
<script>
import { CourseHome } from "@/views/Userside/CourseHomePage/api/index.js";
export default {
  data() {
    return {
      userData: "",
      subjectId: "",
      subjectIds: "",
      chapterId:"",
      chapterName:"",
      totalCount: 0,
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据条数
      CourseHome: [],
    };
  },
  mounted() {
    this.getinfo();
    // 在组件挂载后获取本地存储的数据
    const user = localStorage.getItem("user");
    this.userData = JSON.parse(user);
  },
  methods: {
    handleCommand() {
        this.$router.push('/studentside')
    },
    async getinfo() {
      const subjectIds = localStorage.getItem("subjectId");
    this.subjectId = JSON.parse(subjectIds);
      const num =
        "currentPage=" +
        this.currentPage +
        "&pageSize=" +
        this.pageSize +
        "&subjectId=" +
        this.subjectId;
    CourseHome(num).then((res) => {
        this.totalCount = res.data.data.pageInfo.totalCount
        this.CourseHome = res.data.data.chapterInfoPageData
        this.pageSize = res.data.data.pageInfo.pageSize
      });
    },

    chose(chapterId,chapterName) {
      localStorage.setItem('chapterId', chapterId);
      localStorage.setItem('chapterInfoPageData', JSON.stringify({ chapterId: chapterId, chapterName: chapterName }))
      this.$router.push({ name: 'Directory', params: {chapterId, chapterName }});
    },
    handleSizeChange(val) {
      this.pageSize = val;

      this.getinfo();
    },
    Returns() {
            this.$router.push("/studentside");
        }, //返回
    handleCurrentChange(val) {
      this.currentPage = val;

      this.getinfo();
    },
  },
};
</script>

<style lang="less" scoped>
.body {
  min-height: 100vh;
  position: relative;
}

.header {
  width: 100%;
  height: 15vh;
  background-color: rgb(10, 32, 97);
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .spanitem {
    color: #fff;
    font-size: 35px;
    padding: 5px 0;
  }
  .spanitem_lable {
    color: #fff;
    font-size: 15px;
  }
}
.conter {
  background: linear-gradient(180deg, rgb(214, 226, 254), rgb(255, 255, 255));
  width: 100%;
  height: 105vh;
  .conteritme {
    float: right;
    margin-right: 7%;
    padding-bottom: 5%;
    .conteritme_span1 {
      font-size: 30px;
      line-height: 10svh;
      float: right;
    }
    .conteritme_span2 {
      font-size: 20px;
      float: right;
    }
  }
  .logindiv {
    width: 90%;
    min-width: 300px;
    height: 95%;
    background-color: #fff;
    box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 0 5%;
    position: relative;
    top: 16%;
  }
  .swiper {
    width: 90%;
    margin-left: 5%;
    height: auto;
    position: relative;
    overflow: hidden;
    bottom: 150px;
    display: flex;
    flex-direction: column; /* 将子元素垂直排列 */

    h5 {
      text-align: center;
    }
    .box {
      width: 15%;
      height: 230px;
      margin: 30px 5%;
      border: 1px solid rgb(96, 188, 171);
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease; /* 添加过渡效果 */
      margin-right: 50px;
      float: left; /* 设置子元素向右浮动 */
      img {
        width: 100%;
        height: 70%;
        border-radius: 10px 10px 0 0;
      }
    }
    .box:hover {
      transform: scale(1.1); /* 放大 1.2 倍 */
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
