import service from "@/utils/request/http";

// 获取词汇列表信息
export function selectByIdVocabularyInfo(params){
    return service({
        url:'/vocabulary/backgroundQueryVocabulariesByChapterId',
        method:'get',
        params
    })
}

// 修改词汇列表信息
export function updateVocabularyInfo(data){
    return service({
        url:'/vocabulary/updateVocabulary',
        method:'post',
        data
    })
}

//删除词汇列表信息
export function deleteByIdVocabularyeInfo(params){
    return service({
        url:'/vocabulary/deleteByIdVocabularye',
        method:'get',
        params
    })
}

//添加词汇列表信息
export function addVocabularyInfo(data){
    return service({
        url:'/vocabulary/addVocabulary',
        method:'post',
        data
    })
}

//模糊查询
export function queryLikekeyVocabularies(params){
    return service({
        url:'/vocabulary/queryLikekeyVocabularies',
        method:'get',
        params
    })
}
