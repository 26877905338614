<template>
    <div class="body">
        <div class="header">
            <div class="">
            <img src="@/utils/img/logo.png" @click="Returns()"  style="width: 150px;height: 66px;">
            </div>
            <div>
                <div class="conteritmes">
                    <span class="conteritme_span1">{{ userData.account }}</span><br />
                    <span class="conteritme_span2">{{ userData.id }}</span>
                </div>
            </div>
        </div>
        <div class="conter">
            <div class="conteritme">
                <span style=" font-size: 25px;color: #fff;">{{ chapterInfoPageData.chapterName }}</span><br>
                <span style=" font-size: 25px;color: #fff;">{{this.keyLearnDirectoryName}}</span>
            </div>
            <div class="logindiv">
                <img src="@/utils/img/fanhui.png" @click="chose" style="width: 50px; height: 50px;float: right;opacity: 0.3;">
          
                <div class="content">
                    <div class="box2" style="display: flex">
                        <el-container style="width: 98%;height: 31vb;">
                        <div ref="showdiv" v-html="htmldata" class="show" style="padding: 1% 10%;overflow: auto;overflow: auto;"></div>
                        
                        <div style="display: flex;align-items: center;">
                            <img src="@/utils/img/colorOfJudgment1.png" v-show="img">
                            <img src="@/utils/img/colorOfJudgment2.png" v-show="img_1">
                            <img src="@/utils/img/colorOfJudgment3.png" v-show="img_2">
                        </div>
                    </el-container>
                    </div>
                    <span v-show="isDisabled" style="color: black;margin-left: 10%;">Correct answer：{{this.titleAnswerJson}}</span>
                </div>
                <el-button class="Checks" v-show="next" @click="check()">Next </el-button>
                <el-button class="Check" @click="Answer">Check My Answer </el-button>
            </div>
        </div>
    </div>
</template>
  <script>
import { queryPastTestPaper } from "@/views/Userside/Undirectory/Pastpapers/Aftermode/api/index.js";

export default {
    data() {
        return {
            userData: "",
            chapterInfoPageData: "",
            chapterIds: "",
            chapterName: "",
            user: [],
            keyLearnDirectoryId: "",
            indexNum: -1,
            htmldata: "",
            textdata: "",
            htmlList: [],
            title: "",
            asdlist: "",
            isDisabled: false,
            next:true,
            img: false,
            img_1: false,
            img_2: false,
            titleAnswerJson: "",
            dataObjects: {
                answerData: [],
            },
            swer: "",
            keyLearnDirectoryName:'',
        };
    },
    mounted() {
        this.getinfo();
        this.getinforsider();
        // 在组件挂载后获取本地存储的数据
        const user = localStorage.getItem("user");
        this.userData = JSON.parse(user);
        const chapterInfo = localStorage.getItem("chapterInfoPageData");
        this.chapterInfoPageData = JSON.parse(chapterInfo);
    },
    methods: {
        Returns() {
            this.$router.push("/studentside");
        }, //返回
        setRadio(questionStr) {
            // 存放数组
            var replacements = [];

            const xuan = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ];

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
            }
            const matches = questionStr.match(/\$radio/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="radio" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
                );
            }

            // 当前替换索引
            let currentIndex = 0;

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$radio/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$checkbox变为多选框
        setCheckbox(questionStr) {
            // 替换顺序数组
            const replacements = [];

            const xuan = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
            }

            const matches = questionStr.match(/\$checkbox/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="checkbox" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$checkbox/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$Input变为填空框
        setInput(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Input/g);
            const count = matches ? matches.length : 0;
            this.titlecount = count;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="text" id="qwe${index}" class="qwe" style="text-align: center; border: 0px solid #fff; border-bottom: 1px solid #ccc;height:${fontSize}; width:${
                        fontSize * 4
                    }; font-size:${fontSize}; margin: 0 15px;"/>`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Input/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$Textarea变为问答框
        setTextarea(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Textarea/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<textarea cols="3" id="qwe${index}" class="qwe" rows="3" style="max-width:600px; min-width:300px; font-size:${fontSize}"></textarea>`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Textarea/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },

        // 为单选框绑定事件
        getRadioAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [
                        { content: "" },
                    ];
                    radios.forEach((item) => {
                        item.checked &&
                            (this.dataObjects.answerData[
                                this.indexNum
                            ].titleAnswer[0].content = item.value);
                    });
                });
            });
        },
        // 为复选框绑定事件
        getCheckboxAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [
                        { content: "" },
                    ];
                    var asdlist = [];
                    radios.forEach((item) => {
                        item.checked && asdlist.push(item.value);
                    });
                    this.dataObjects.answerData[
                        this.indexNum
                    ].titleAnswer[0].content = asdlist.join(",");
                });
            });
        },
        // 为填空框绑定事件
        getInputAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [];
                    inputs.forEach((item, index) => {
                        this.dataObjects.answerData[
                            this.indexNum
                        ].titleAnswer.push({
                            content: item.value,
                        });
                    });
                });
            });
        },
        // 为问答框绑定事件
        getTextareaAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [
                        { content: "" },
                    ];
                    inputs.forEach((item) => {
                        this.dataObjects.answerData[
                            this.indexNum
                        ].titleAnswer[0].content = item.value;
                    });
                });
            });
        },
        asdd() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    radio.checked && alert("你选择了答案" + radio.value);
                });
            });
        },
        async getinfo() {
                const keyLearnDirectoryIds = localStorage.getItem(
                    "keyLearnDirectoryId"
                );
                this.keyLearnDirectoryName = localStorage.getItem(
                    "keyLearnDirectoryName"
                );
                console.log(this.keyLearnDirectoryName,'this.keyLearnDirectoryName');
                this.keyLearnDirectoryId = JSON.parse(keyLearnDirectoryIds);
                const num = "keyLearnDirectoryId=" + this.keyLearnDirectoryId;
                var res = undefined;
                res = await queryPastTestPaper(num);
                this.user = res.data.data;
                this.check();
          
        },
        check() {
            this.isDisabled = false;
            this.img = false;
            this.img_1 = false;
            this.img_2 = false;
            this.textdata = this.user; //变量3 某一个小测的所有题目
            if (this.indexNum < this.textdata.length - 1) {
                this.indexNum++;

                switch (this.textdata[this.indexNum].titleType) {
                    //单选题
                    case 0:
                        this.htmldata = this.setRadio(
                            this.textdata[this.indexNum].titleContentJson
                        );
                        this.$nextTick(this.getRadioAnswer);
                        break;
                    //多选题
                    case 1:
                        this.htmldata = this.setCheckbox(
                            this.textdata[this.indexNum].titleContentJson
                        );
                        this.$nextTick(this.getCheckboxAnswer);
                        break;
                    //填空题
                    case 2:
                        this.htmldata = this.setInput(
                            this.textdata[this.indexNum].titleContentJson
                        );
                        this.$nextTick(this.getInputAnswer);
                        break;
                    //问答题
                    case 3:
                        this.htmldata = this.setTextarea(
                            this.textdata[this.indexNum].titleContentJson
                        );
                        this.$nextTick(this.getTextareaAnswer);
                        break;
                    default:
                        this.$message.error("该题型未得到应用");
                }
                const list = {
                    title: this.textdata[this.indexNum].title, // 题目名
                    titleType: this.textdata[this.indexNum].titleType, // 题目类型
                    titleAnswer: [
                        {
                            content: "", // 题目答案
                        },
                    ],
                };
                this.dataObjects.answerData.push(list);
            }else{
                this.next=false;
            }
            if (this.textdata[this.indexNum].titleType == 2) {
                const Answer = this.textdata[this.indexNum].titleAnswerJson;
                const contentValues = Answer.map((obj) => obj.content);

                this.titleAnswerJson = contentValues;
            } else {
                this.titleAnswerJson =
                    this.textdata[this.indexNum].titleAnswerJson[0].content;
            }
        },
        async getinforsider() {},
        chose() {
            this.$router.push("/Undirectory");
        },
        Answer() {
            this.swer =
                this.dataObjects.answerData[
                    this.indexNum
                ].titleAnswer[0].content;
            if (this.textdata[this.indexNum].titleType == 2) {
                const Answers = this.textdata[this.indexNum].titleAnswerJson;
                const contentitme = Answers.map((obj) => obj.content);
                const AnswerJson =
                    this.dataObjects.answerData[this.indexNum].titleAnswer;
                const contentitmes = AnswerJson.map((obj) => obj.content);

                let equalCount = 0;

                // 遍历数组，并比较相同下标位置的元素
                for (let i = 0; i < contentitme.length; i++) {
                    if (contentitmes[i] === contentitme[i]) {
                        equalCount++;
                    }
                }
                if (equalCount == contentitme.length) {
                    this.img = true;
                    this.img_1 = false;
                    this.img_2 = false;
                } else if (equalCount == 0) {
                    this.img = false;
                    this.img_1 = true;
                    this.img_2 = false;
                    this.isDisabled = true;
                } else {
                    this.img = false;
                    this.img_1 = false;
                    this.img_2 = true;
                    this.isDisabled = true;
                }
            } else {
                if (this.swer == this.titleAnswerJson) {
                    this.img = true;
                    this.img_1 = false;
                    this.isDisabled = false;
                } else {
                    this.img = false;
                    this.img_1 = true;
                    this.isDisabled = true;
                }
            }
        },
    },
};
</script>
  <style lang="less" scoped>
.body {
    min-height: 80vh;
    position: relative;
}
.header {
    width: 100%;
    height: 15vh;
    background-color: rgb(10, 32, 97);
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .spanitem {
        color: #fff;
        font-size: 25px;
        padding: 5px 0;
    }
    .spanitem_lable {
        color: #fff;
        font-size: 15px;
    }
}
.conter {
    background-color: rgb(10, 32, 97);
    width: 100%;
    height: 85vh;
    .conteritme {
        margin-left: 5%;

        .conteritme_span1 {
            font-size: 30px;
            line-height: 10svh;
            float: left;
        }
        .conteritme_span2 {
            font-size: 20px;
            float: right;
        }
    }
    .logindiv {
        width: 100%;
        min-height: 300px;
        height: 516px;
        background-color: #fff;
        box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: rgb(210, 233, 254);
        position: relative;
        top: 5%;
    }
}
.return {
    float: right;
}
.content {
    height: 55%;
    position: relative;
    top: 10%;
    background-color: #fff;
}
.return {
    float: right;
}
.Check {
    position: relative;
    left: 75%;
    top: 20%;
    background-color: rgb(10, 32, 97);
    color: #fff;
}
.Checks{
    position: relative;
    left: 75%;
    top: 20%;
    background-color: rgb(10, 32, 97);
    color: #fff;
}
img {
    width: 40px;
    height: 40px;
}
/* 隐藏滚动条，但保持滚动功能 */
.show::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.conteritmes {
    float: right;
    margin-right: 45%;
    .conteritme_span1 {
        float: right;
        font-size: 20px;
        color: #fff;
    }
    .conteritme_span2 {
        float: right;
        color: #fff;
    }
}
</style>
  