<template>
    <div class="page">
        <el-container>
            <el-header style="padding: 0;border-bottom: 2px solid #eee;">
                <div class="headerdiv">
                    <span class="spanitem" @click="tourl()">GENZ {{ $t('homepage.header') }}</span>
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <el-avatar icon="el-icon-user-solid"></el-avatar>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="c">修改密码</el-dropdown-item>
                            <el-dropdown-item command="b">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </el-header>
            <el-container>
                <el-aside width="200px" style="background-color: #fff; margin-right: 20px; height:91vh">
                    <el-menu :default-active="routerPath" class="el-menu-vertical-demo" router>
                        <el-menu-item index="/MangenmentStudent" route="/MangenmentStudent">
                            <i class="el-icon-user"></i>
                            <span slot="title">学生管理</span>
                        </el-menu-item>
                        <el-menu-item index="/MangenmentCourse" route="/MangenmentCourse">
                            <i class="el-icon-menu"></i>
                            <span slot="title">课程管理</span>
                        </el-menu-item>
                        <el-menu-item index="/MangenmentTeacher" route="/MangenmentTeacher" v-if="administratorInfo.roleMark">
                            <i class="el-icon-menu"></i>
                            <span slot="title">老师管理</span>
                        </el-menu-item>
                    </el-menu>
                </el-aside>
                <el-main style="background-color: #fff; margin-top:20px">
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
        <updataDialog ref="updataDialog" @setpasswordFrom="pushPassword" />
    </div>
</template>

<script>
import sm2Util from "@/utils/sm2Util";
import { updateStudentPassword } from "./api/index.js";
import updataDialog from "./components/updataDialog.vue";
export default {
    data() {
        return {
            routerPath: "",
            administratorInfo: {},
        };
    },
    components: {
        updataDialog,
    },
    watch: {
        $route: "getPath",
    },
    methods: {
        handleCommand(command) {
            if (command == "b") {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                this.$router.push("/login");
            }

            if (command == "c") {
                this.$refs.updataDialog.dialogFormVisible = true;
            }
        },
        async pushPassword() {
            const res = await updateStudentPassword({
                id: this.administratorInfo.id,
                scrPassword: sm2Util.sm2Encrypt(
                    this.$refs.updataDialog.passwordFrom.oldPassword
                ),
                password: sm2Util.sm2Encrypt(
                    this.$refs.updataDialog.passwordFrom.newPassword1
                ),
                confPassword: sm2Util.sm2Encrypt(
                    this.$refs.updataDialog.passwordFrom.newPassword2
                ),
            });
            if (res.data.statusCode == 200 && res.data.data) {
                this.$message.success("修改密码成功！");
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                this.$router.push({
                    path: "/login",
                });
                this.$message.info("请重新登录");
            } else {
                this.$message.error("修改密码失败！");
            }
            this.$refs.updataDialog.dialogFormVisible = false;
        },
        getPath() {
            this.routerPath = this.$route.path;
        },
        tourl() {
            this.$router.push("/home");
        }
    },
    mounted() {
        this.administratorInfo = JSON.parse(localStorage.getItem("user"));
        this.routerPath = this.$route.path;
    },
};
</script>

<style lang="less" scoped>
.page {
    width: 100vw;
    height: 100vh;
    background-color: #eee;
}

.headerdiv {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 40px;
}

.spanitem {
    text-decoration: none;
    cursor: pointer;
}

.el-dropdown-link {
    cursor: pointer;
    color: #409eff;
}

.el-icon-arrow-down {
    font-size: 12px;
}
</style>