<template>
    <el-dialog title="设置混合模式总题数" :visible.sync="dialogFormVisible">
        <el-form :model="form">
            <el-form-item label="总题数量" label-width="80px">
                <el-input-number v-model="form.num" :min="1" :max="10" :step="1"
                    label="描述文字"></el-input-number>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="pushFormInfo">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogFormVisible: false,
            form: {
                num: 10
            },
            rules:{
                num:[
                { required: true, message: '请输入总题数量', trigger: 'change' }
                ]
            }
        }
    },
    methods:{
        pushFormInfo(){
            this.$emit('pushInfo')
        }
    }
}
</script>

<style></style>