import { render, staticRenderFns } from "./afterindex.vue?vue&type=template&id=3a6e4336&scoped=true"
import script from "./afterindex.vue?vue&type=script&lang=js"
export * from "./afterindex.vue?vue&type=script&lang=js"
import style0 from "./afterindex.vue?vue&type=style&index=0&id=3a6e4336&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a6e4336",
  null
  
)

export default component.exports