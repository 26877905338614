<template>
  <div>
    <Back class="backdiv" />
    <div class="inquire">
      <el-input placeholder="请输入内容" v-model="inquire" class="inquireInput">
        <template slot="append">
          <el-button type="primary" icon="el-icon-search" @click="queryLikekeyVocabulariesInfo"></el-button>
        </template>
      </el-input>
      <el-button type="primary" @click="openDialog('add')">添加词汇</el-button>
    </div>
    <div class="tablediv">
      <el-table :data="vocabularyList" border style="width: 100%" max-height="477">
        <el-table-column prop="originalText" label="原文" width="180">
        </el-table-column>
        <el-table-column prop="translatedText" label="译文" width="180">
        </el-table-column>
        <el-table-column prop="definition" label="定义">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="openDialog('update', scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteByIdVocabularye(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <vocabularyDialog ref="vocabularyDialog" @pushVocabularyform="pushVocabulary" />
  </div>
</template>

<script>
import { selectByIdVocabularyInfo, updateVocabularyInfo, deleteByIdVocabularyeInfo, addVocabularyInfo, queryLikekeyVocabularies } from './api/index.js'
import vocabularyDialog from './components/vocabularyDialog.vue'
import Back from '../components/index.vue'
export default {
  data() {
    return {
      vocabularyList: [],
      inquire: '',
      chapterId: ''
    }
  },
  components: {
    Back,
    vocabularyDialog
  },
  methods: {
    // 获取词汇
    async selectByIdVocabulary() {
      const res = await selectByIdVocabularyInfo({ chapterId: this.chapterId })
      if (res.data.statusCode == 200) {
        this.vocabularyList = res.data.data
      }
    },
    // 删除词汇
    deleteByIdVocabularye(item) {
      this.$confirm(`确认删除${item.originalText}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteByIdVocabularyeInfo({ vocabularyId: item.vocabulariesId })
        if (res.data.statusCode == 200) {
          this.$message.success('删除成功！')
        }else{
          this.$message.error('删除失败，' + res.data.errors + '！')
        }
        this.selectByIdVocabulary()
      }).catch(() => { });
    },
    // 打开弹框
    openDialog(dialogType, item = { originalText: '', translatedText: '', definition: '' }) {
      this.$refs.vocabularyDialog.dialogType = dialogType
      this.$refs.vocabularyDialog.dialogFormVisible = true
      this.$refs.vocabularyDialog.vocabularyform = { ...item }
      this.$refs.vocabularyDialog.chapterId = this.chapterId
    },
    // 确认提交
    async pushVocabulary() {
      var res = {}
      var vocabularyInfo = {
        chapterId: this.$refs.vocabularyDialog.chapterId,
        originalText: this.$refs.vocabularyDialog.vocabularyform.originalText,
        translatedText: this.$refs.vocabularyDialog.vocabularyform.translatedText,
        definition: this.$refs.vocabularyDialog.vocabularyform.definition
      }
      // 添加操作
      if (this.$refs.vocabularyDialog.dialogType == 'add') {
        res = await addVocabularyInfo(vocabularyInfo)
        if (res.data.statusCode == 200 && res.data.data) {
          this.selectByIdVocabulary()
          this.$refs.vocabularyDialog.dialogFormVisible = false
          this.$message.success('添加成功！')
        }
      }

      // 修改操作
      if (this.$refs.vocabularyDialog.dialogType == 'update') {
        vocabularyInfo.vocabularyId = this.$refs.vocabularyDialog.vocabularyform.vocabulariesId
        res = await updateVocabularyInfo(vocabularyInfo)
        if (res.data.statusCode == 200 && res.data.data) {
          this.selectByIdVocabulary()
          this.$refs.vocabularyDialog.dialogFormVisible = false
          this.$message.success('修改成功！')
        }
      }
    },
    //模糊查询
    async queryLikekeyVocabulariesInfo() {
      const res = await queryLikekeyVocabularies({ chapterId: this.chapterId, where: this.inquire })
      if (res.data.statusCode == 200) {
        this.vocabularyList = res.data.data
      }
    }
  },
  mounted() {
    this.chapterId = this.$route.query.chapterId
    this.selectByIdVocabulary()
  }
}
</script>

<style lang="less" scoped>
.backdiv {
  margin: 20px 0;
}

.inquire {
  display: flex;
  margin-bottom: 40px;

  .inquireInput {
    width: 400px;
    margin-right: 40px;
  }
}
</style>