<template>
  <div class="box1">
    <div v-for="(item, index) in user" :key="index.keyLearnDirectoryId" class="box" style="overflow: hidden;">
      <el-button @click="chose(item.keyLearnDirectoryId, item.keyLearnDirectoryName)">{{ item.keyLearnDirectoryName
        }}</el-button>
    </div>
  </div>
</template>
<script>

import { queryKeyLearnDirectory, updateChapterClickTime } from "@/views/Userside/Undirectory/Undirectory/api/index.js";

export default {
  data() {
    return {
      chapterId: "",
      user: [],
      keyLearnDirectoryId: "",
      keyLearnDirectoryName: "",
    };
  },
  mounted() {
    this.getinfo();
    this.ChapterClickTime();
  },
  methods: {
    async getinfo() {
      const chapterIds = localStorage.getItem("chapterId");
      this.chapterId = JSON.parse(chapterIds);
      const num =
        "chapterId=" +
        this.chapterId;
      queryKeyLearnDirectory(num).then((res) => {
        this.user = res.data.data;
        console.log(res);
      });
    },
    chose(keyLearnDirectoryId, keyLearnDirectoryName) {
      localStorage.setItem('keyLearnDirectoryId', keyLearnDirectoryId);
      localStorage.setItem('date', JSON.stringify({ keyLearnDirectoryId: keyLearnDirectoryId, keyLearnDirectoryName: keyLearnDirectoryName }))
      this.$router.push({ name: 'Compactnote', params: { keyLearnDirectoryId, keyLearnDirectoryName } });
    },
    async ChapterClickTime() {
      var res = undefined
      const num =
        "chapterId=" +
        this.chapterId;
      res = await updateChapterClickTime(num);
    }
  },
};
</script>
<style lang="less" scoped>
.box1 {
  height: 100%;
  /* 设置固定高度 */
  overflow-y: auto;
  /* 启用垂直滚动条，但会隐藏滚动条本身 */
}

/* 隐藏滚动条，但保持滚动功能 */

.box1.show {
  opacity: 1;
  /* Show scrollbar on hover */
}

.box button {
  min-width: 200px;
  margin: 3% 25%;
  background-color: rgb(211, 233, 254);
  color: black;
  width: 50%;
  word-break: normal;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}
</style>