<template>
  <div class="Back" @click="backUrl">
    <i class="el-icon-back icon"></i>
    <span>返回上一页</span>
  </div>
</template>

<script>
export default {
    methods:{
        backUrl(){
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="less" scoped>
.Back{
    display: flex;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    .icon{
        margin-right: 3px;
        font-size: 25px;
    }
}
</style>