const TraditionalChinese = {
    homepage: {
        header: "TUTORING AND LABS",
        language1: "信心從這裡開始",
        navigation1: "關於我們",
        navigation2: "學術支援",
        navigation3: "咨詢服務",
        navigation4: "聯繫我們",
        navigation5: "資源",
        navigation6: "升學準備",
        navigation7: "登錄"
    },
    homeview: {
        header1: "自信——從這裡開始",
        header2: "聯絡我們",
        Lrarn: "服務範疇",
        Lrarn1: "學術支援",
        Lrarn2: "升學準備",
        Lrarn3: "咨詢服務",
        What: "我們的理念,",
        What1: "GenZ堅信每個獨立個體皆有其個別的潛能，透過了解孩子不同的學習需要，我們會選取合適的教學方式，讓他們容易掌握學習內容，提升成就感及逐步累積自信。我們重視每一節的教學輔導；結合科技與心理學的應 用，幫助學生在學術上有所提升。除此以外， 給予學生在學習攻略、時間管理及自律能力 上的輔助，讓學習的成效相得益彰，也是 GenZ的教學理念。課堂外，如有需要，我們 會樂於向學生提供一些留英生活小錦囊，務  求全方位支援更多學生及早適應陌生的環境  ，融入本地社會文化。",
        What2: "更多關於我們...",
        value: "我們的價值",
        value1: "賦予能力",
        value2: "我們相信賦予學生主導自己學習的能力，才是最寶貴的才略。透過教育及提供協助，學生的自信及學習技巧能力得以建立；透過拆解困難，引導他們用堅持的態度面對，全然釋放學生的個人潛能。",
        value3: "專業精神",
        value4: "我們的導師團隊不但熟知課程的內容，掌握應考經驗，更有豐富的教育心得。憑藉優秀的導師團隊，我們能精準地針對學生的弱項，幫助他們提升，並拆解課程內容中常見的誤解，輔助學生理解試題和掌握答題模式，將技巧應用於考試上。除了專業的導師，我們每兩個月會發佈學生的進度報告；分析學生於課堂上的表現和學習進度，讓家長能了解學生學習的每一步，陪伴他們成長。",
        value5: "以學生為中心",
        value6: "我們承諾將每一位學生的不同需求放在首位，讓他們都享有獨特，又適合自己的學習模式。透過度身訂制的教程，每一位學生都能從教學中獲益良多。本著有教無類的教育精神，我們也曾成功幫助有特殊學習需要的學生融入校園生活，並獲得理想的成績",
        Tabbar: "歡迎查詢",
        Tabbar1: "聯絡我們！",
        Tabbar2: "立即聯繫",
    },
    contact: {
        demo: "聯繫我們",
        required: '(必填)',
        title:"如果你對我們所提供的服務（1:1學術支援或線上學習平台）感興趣的話，請於以下位置留言聯絡我們吧！",
        demo1: "姓名",
        demo2: "電郵地址",
        demo3: "主題",
        demo4: "內容",
        demo5: "提交",
        demo6: "姓名爲必填項",
        demo7: "邮箱爲必填項",
        demo8: "電子郵件需要有效的電子郵件地址",
    },
    academic: {
        title: '學術支持',
        introduce: '從幫助學生銜接香港與英國的學習內容、 Key Stage 3、GCSE 到 A-level，我們為各個 年齡層的學生均提供學業支持。我們提供的  科目包括但不限於如下：',
        li1: 'Biology 生物學',
        li2: 'Chemistry 化學',
        li3: 'Computer Science 計算機科學',
        li4: 'Physics 物理學',
        li5: 'English 英語',
        li6: 'English Literature 英國文學',
        li7: 'Psychology 心理學',
        li8: 'Mathematics 數學',
        morediv: '如有需要，請即[查詢]!'
    },
    Search: '搜索',
    examination: {
        title: '升學準備',
        introduce1: '英國的教育系統分為數個階段：Key Stage 1（5-7歲）、Key Stage 2（7-11歲）、Key Stage 3（11-14歲）、Key Stage 4（GCSE） 及Key Stage 5（A-level）。',
        introduce2: '其中學生於Year 8 升讀 Year 9、開始 Key Stage 4 時轉學校將需要通過入學考試才能 獲得取錄。這當中的考試一般包括英文、數 學測試及面試。類似的考試將於升上Key Stage 5時亦會出現。',
        introduce3: '升讀大學則需通過 UCAS 報考，其中可選擇五家大學報考，並需要寫出 personalstatement 以向各家大學展示個人所長。某些學科更需要額外報考其他的測試，例如醫科需報考 UCAT 或 BMAT、法律系需報考LNAT。詳細資料請往各家大學查詢具體要求。',
        introduce4: '我們設有各階段升學準備的教學；其中以Year 9入學試、Sixth Form升學考試、及大學升學為主，務求學生於每一個人生里程碑，我們均能幫助他們備考，陪伴家長及同學們走過這一段關鍵的路程。',
    },
    Consultancy: {
        title: '咨詢服務',
        introduce1: '我們明白初到異地的學生或家長，可能對不熟悉的環境帶來擔憂及顧慮，尤其是中途插班生為追趕學習進度而產生壓力，或對本地答題模式的不熟悉而產生焦慮。為此，我們提供咨詢服務，為移英的家庭和留學生提供相關的支援，以作為學生及家長們的最強後盾。透過自身的經驗，我們能夠給予實際的建議及幫助學生建立良好的學習模式，以助學生能恆久地掌有自習的能力，實現自主學習。',
        introduce2: '其中，升讀大學是踏向人生另一重要階段的關口。在這繁瑣的過程中，我們將陪伴學生經歷每一步——從挑選合適的大學、到撰寫personal statement、甚至面試前應作的準備，務使學生都能升讀各自理想的大學。',
        content1: '查詢',
        content2: '如有需要，請即！'
    },
    about: {
        title: '關於我們',
        introduce1: 'GenZ Tutoring and Labs （簡稱GenZ） 創立 於2023年，旨在為學齡社群在學業上及生活上提供適設協助，從而累積成就感及自信心 ，為他們的未來建立基礎。',
        introduce2: '創辦人Felicia憑藉多個獎學金完成GCSE、 GCE A-Level考獲8A及5A*成績，獲著名大 學頒發The Chancellor’s Scholarship攻讀心理學，亦憑着隻身英國留學的第一身經驗， 透過組織及培訓GenZ導師團隊，幫助學生  更有效學習及盡快融入本地生活。',
        introduce3: 'GenZ成立至今不足半年，已經成功為超過 60人次提供超過700小時之恒常網上課堂，  涵蓋對象程度包括Key Stage 3、GCSE、  GCE A-Level，甚至foundation year及 Advanced diploma。',
        introduce4: 'GenZ導師團隊對不同課程的內容及要求均 瞭如指掌，透過了解學生面對各種日常的挑 戰，讓學生及家長吸取學習心得及小錦囊之  餘，有把握地渡過每一個階段，減少不必要 的憂慮。',
        introduce5:'GenZ希望幫助更多不同學習需要的人士，  協助建立累積成就感及自信心，為未來的社 會棟樑奠定基礎。'
    },
    resources:{
        title:'資源',
        introduce1:'我們正在努力爲我們的學生提供更多的複習資源。請期待我們下面的全套精簡筆記。',
        introduce2:'物理',
        introduce3:'化學',
        introduce4:'生物學（即將推出)',
        },
        questions:{
            title:'常见问题解答',
            lable:'1:1補習',
            problem:'於 GenZ 補習的流程是怎樣的呢？',
            answer:'您（或您的孩子）首先需要回答入學問題，以幫助我們更好地了解您目前的水平。然後我們將安排一次與合適導師的試堂。您可以隨時與我們聯繫反饋或解決行政問題，而導師將專注於準備課程，確保每節課都是優質的。確認定期課堂後，您將被加入到一個WhatsApp群組或者您最方便的社交媒體群組與導師進行聯繫。請在每節課之前進行支付以確認每次課程。',
            problem1:'GenZ 的導師擁有什麼資歷？',
            answer1:'我們的導師於他們所專攻的科目中均獲得過相關考試證明，我們樂意應要求提供證明。',
            problem2:'GenZ 提供哪些科目和程度的補習？',
            answer2:'我們提供廣泛的科目，包括但不限於物理、化學、生物、數學和英語的 GCSE 和 A-level。請參 見【學術支援】了解更多詳情。',
            problem3:'GenZ 補習課程如何的多個性化？',
            answer3:'我們所有的課程都是完全個性化的，這意味著我們的導師將根據您（或您的孩子）的進度為您 準備特定內容。這可能包括教學風格、內容和練習組成、不同考試局指定的內容等。值得注意 的是，我們的導師將根據學生的需求調整課程安排作為最終的決定。例如即使學生要求做練習題，如果學生對某些概念感到不確定，我們的導師亦可能會選擇先與學生澄清概念。',
            problem4:'什麼是雙月進度報告？',
            answer4:'導師將於每兩個月撰寫一份進度報告以向家長更新學生的情況。此報告可能包括他們在兩個月內所涵蓋的具體內容、改進領域和學習態度。我們希望能藉此建立更有效的課程並允許家長在需要時協助學習。',
        },
        education:{
            lable:'教育平台',
            problem:'平台的主要功能是什麼？',
            answer:'我們的平台包括以助學生重點學習的筆記和圖表，並在各個主題之間構建測驗以支持對每個主題的理解。',
            answer_1:'在每個主題下，您可以完成Greenlight test以進行快速知識測試。這能為您客觀的分析目前的水平，提醒您需要多加複習的較弱領域。',
            answer_2:'由於平台是專門為海外華人學生設計的，我們準備了詞彙表，讓學生專注於理解帶有定義和 翻譯的關鍵詞。',
            answer_3:'在獲得每個概念的全面理解後，您可以在我們的 Past Papers 的部分進一步測試您的知識，  以混合或主題模式按照您自己的節奏完成，並提供模型答案。',
            problem1:'平台將如何追蹤及評估我的進度？',
            answer1:'您在 Key learning 的進度將自動保存並顯示在頁面的左側。',
            answer1_1:'Greenlight test 內包括選擇題和短答問題，將自動評估，自動計算客觀分數。而於 Greenlight test 的先前嘗試也將自動保存，以供將來複習時參考。',
            answer1_2:'Past paper questions 將根據問題的模式自動標記或自我評估。',
            problem2:'如果我需要的課程尚未上架，我可以做什麼？',
            answer2:'我們正在努力提供盡可能多的課程和科目。如果您的課程目前不可用，請與我們聯繫，我們將在課程準備好後通知您。',
            problem3:'平台是如何運作的？',
            answer3:'如果您感興趣，請點擊此處聯繫我們。留下一個關於您感興趣的課程的簡短訊息，我們將盡快回覆您。一旦支付完成，您將收到我們為你準備的登錄資料，您便可以隨時準備開始了！',
            answer3_1:'在您的計劃時段內，您將不受任何限制，可24小時使用課程上的所有功能，可以隨時隨地開始您的學習之旅。',
            problem4:'課程的費用多少？',
            answer4:'費用取決於訪問的持續時間，從每月低至20.8英鎊。請點擊此處獲取更多資訊。',
            problem5:'我需要購買其他產品嗎？',
            answer5:'不需要。我們提供您課程所需的一切，包括筆記、主題詞彙表和過去的試題。',
            answer5_1:'如果您需要購買其他課程，請點擊此處與我們聯繫。',
            problem6:'我可以隨時取消我的月費計劃嗎？',
            answer6:'可以，請點擊此處聯繫我們取消計劃。我們將無條件為你辦妥。',
        },
        login:{
            language:'中',
            title:'在此登入',
            username:'用戶名稱',
            userpassword:'請輸入密碼',
            btn1:'登入',
            btn2:'導師登入',
            usernameRules:'用戶名称不能爲空',
            userpasswordRules:'密碼不能爲空'
            
        }  
}
export default TraditionalChinese