<template>
  <div class="body">
    <div class="header">
      <div class="">
        <img
          src="@/utils/img/logo.png"
          @click="Returns()"
          style="width: 150px; height: 66px"
        />
      </div>
      <div>
        <div class="conteritmes">
          <span class="conteritme_span1">{{ userData.account }}</span
          ><br />
          <span class="conteritme_span2">{{ userData.id }}</span>
        </div>
        <div style="position: relative; top: 75px; right: 25px">
          <span style="color: #fff; font-size: 18px; margin: 30px"
            >{{ this.Complete }}% Complete</span
          ><br />
          <el-progress
            :text-inside="false"
            :show-text="false"
            :stroke-width="10"
            :percentage="this.Complete"
            style="width: 200px"
            color="rgb(96,118,171)"
          ></el-progress>
        </div>
      </div>
    </div>
    <div class="conter">
      <div class="conteritme">
        <span style="font-size: 25px; color: #fff">{{
          chapterInfoPageData.chapterName
        }}</span
        ><br />
        <span style="font-size: 25px; color: #fff">{{
          date.keyLearnDirectoryName
        }}</span>
      </div>

      <div class="logindiv">
        <div class="swiper">
          <div class="right">
            <el-col :span="24">
              <el-container style="width: 98%; height: 63vb; padding-top: 0%">
                <el-menu class="el-menu-vertical-demo">
                  <el-menu-item  @click="chose(item.noteOrder)" v-for="item in user" :key="item.noteId">
                    <span slot="title">{{
                      item.noteTitle
                    }}</span>
                  </el-menu-item>
                </el-menu>

                <el-main
                  style="
                    background-color: #fff;
                    width: 95%;
                    height: 95%;
                    margin-top: 20px;
                  "
                >
                  <div
                    style="
                      width: 95%;
                      height: 98%;
                      background-color: rgb(210, 223, 254);
                      position: relative;
                    "
                  >
                    <div class="box" ref="showdiv">
                      <b><span class="box1" v-html="this.noteTitle"></span></b>
                      <span
                        v-show="showButton6"
                        style="
                          float: right;
                          font-weight: bold;
                          position: absolute;
                          top:5%;
                          right: 2%;
                        "
                        >Question {{ this.indexNum + 1 }} out of
                        {{ this.textdata.length }}</span
                      >
                      <div id="box2">
                        <div v-html="htmldata" style="margin: 2%"></div>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          position: relative;
                          bottom: 208px;
                        "
                        v-show="isDisabled"
                      >
                        <div style="position: relative" v-show="isDisabled">
                          <el-progress
                            class="circle"
                            :show-text="false"
                            type="circle"
                            :percentage="this.percentage"
                            :stroke-width="7"
                            :color="'rgb(10 32 97)'"
                            :width="260"
                          ></el-progress>
                          <div
                            class="qualifieddiv"
                            style="
                              width: 200px;
                              position: absolute;
                              top: 30px;
                              left: -140px;
                            "
                          >
                            <p style="margin: 0px">80% Pass</p>
                            <img
                              src="@/utils/img/tupiao(3).jpg"
                              alt=""
                              style="width: 150px; height: 20px"
                            />
                          </div>
                          <div
                            class="progress_p"
                            style="
                              width: 200px;
                              position: absolute;
                              top: 150px;
                              left: 21px;
                            "
                          >
                            <h1
                              style="
                                position: absolute;
                                left: 72px;
                                bottom: 3px;
                              "
                            >
                              {{ this.percentage + "%" }}
                            </h1>
                            <img
                              src="@/utils/img/1.112.png"
                              v-show="ings"
                              style="
                                width: 90px;
                                height: 90px;
                                position: absolute;
                                left: 72px;
                              "
                              alt=""
                            />
                            <img
                              src="@/utils/img/1.113.png"
                              v-show="imgs"
                              style="
                                width: 70px;
                                height: 70px;
                                position: absolute;
                                left: 72px;
                              "
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <el-button
                      type="primary"
                      v-show="showButton"
                      @click="chock()"
                      class="button"
                      >Next</el-button
                    >
                    <el-button
                      type="primary"
                      v-show="showButton1"
                      @click="Return()"
                      class="button1"
                      >Return</el-button
                    >
                    <div class="button3">
                      <i
                        class="el-icon-arrow-left"
                        v-show="showButton3"
                        @click="left()"
                      ></i>
                      <i
                        class="el-icon-arrow-right"
                        v-show="showButton3_1"
                        style="margin-left: 8px"
                        @click="right()"
                      ></i>
                    </div>
                    <el-button
                      type="primary"
                      v-show="showButton4"
                      @click="submit()"
                      class="button4"
                      >submit</el-button
                    >
                    <el-button
                      type="primary"
                      v-show="showButton5"
                      @click="Retry()"
                      class="button5"
                      >Retry</el-button
                    >
                    <el-button
                      type="primary"
                      v-show="showButton7"
                      @click="Next7()"
                      class="button7"
                      >Next</el-button
                    >
                  </div>
                </el-main>
              </el-container>
            </el-col>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
    queryKeyLearn,
    quizAnswerJudgment,
} from "@/views/Userside/Undirectory/Undirectory/Compactnote/Compactnotelist/api/index.js";
import { queryKeyLearnChildrenDirectory } from "@/views/Userside/Undirectory/Undirectory/Compactnote/Compactnote/api/index.js";
export default {
    data() {
        return {
            Complete: "",
            userData: "",
            chapterInfoPageData: "",
            chapterId: "",
            noteId: "",
            chapterName: "",
            user: [],
            date: {},
            activeIndex: "0", // 初始高亮的菜单项
            asdindex: 0, // 自定义的索引，用于条件判断
            labnum1: "",
            labnum2: "",
            chapterId: "",
            chapterIds: "",
            keyLearnDirectoryId: "",
            keyLearnDirectoryIds: "",
            noteContentJson: "",
            noteTitle: "",
            htmldata: ``,
            noteOrder: 0,
            user: [],
            percentage: "", //百分比
            status: "", //对错
            ruturns: true,
            ings: false,
            imgs: false,
            isDisabled: false,
            showButton: false,
            showButton1: false,
            showButton2: false,
            showButton3: false,
            showButton3_1: false,
            showButton4: false,
            showButton5: false,
            showButton6: false,
            showButton7: false,
            textdata: "",
            indexNum: -1, //题目
            htmlList: [],
            title: "",
            asdlist: "",
            dataObjects: {
                quizId: "",
                answerData: [],
            },
            AnswerList: [],
            isProgressVisible: false,
        };
    },
    mounted() {
        this.getinfos();
        this.getinfo();
        // 在组件挂载后获取本地存储的数据
        const user = localStorage.getItem("user");
        this.userData = JSON.parse(user);
        const chapterInfo = localStorage.getItem("chapterInfoPageData");
        this.chapterInfoPageData = JSON.parse(chapterInfo);
    },
    methods: {
        async getinfos() {
            const chapterIds = localStorage.getItem("chapterId");
            this.chapterId = JSON.parse(chapterIds);
            const dates = localStorage.getItem("date");
            this.date = JSON.parse(dates);
            const num =
                "chapterId=" +
                this.chapterId +
                "&keyLearnDirectoryId=" +
                this.date.keyLearnDirectoryId;
            queryKeyLearnChildrenDirectory(num).then((res) => {
                this.user = res.data.data
                    .slice()
                    .sort((a, b) => a.noteOrder - b.noteOrder);
                    console.log("11111111111111111111",this.user)
            });
        }, //显示数据

        setRadio(questionStr) {
            // 存放数组
            var replacements = [];

            const xuan = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ];

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
            }
            const matches = questionStr.match(/\$radio/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="radio" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
                );
            }

            // 当前替换索引
            let currentIndex = 0;

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$radio/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$checkbox变为多选框
        setCheckbox(questionStr) {
            // 替换顺序数组
            const replacements = [];

            const xuan = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
            }

            const matches = questionStr.match(/\$checkbox/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="checkbox" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$checkbox/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$Input变为填空框
        setInput(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Input/g);
            const count = matches ? matches.length : 0;
            this.titlecount = count;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="text" id="qwe${index}" class="qwe" style="text-align: center; border: 0px solid #fff; border-bottom: 1px solid #ccc;height:${fontSize}; width:${
                        fontSize * 4
                    }; font-size:${fontSize}; margin: 0 15px;"/>`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Input/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$Textarea变为问答框
        setTextarea(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Textarea/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<textarea cols="3" id="qwe${index}" class="qwe" rows="3" style="max-width:600px; min-width:300px; font-size:${fontSize}"></textarea>`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Textarea/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        async getinfo() {
            const chapterIds = localStorage.getItem("chapterId");
            this.chapterId = JSON.parse(chapterIds);
            const keyLearnDirectoryIds = localStorage.getItem(
                "keyLearnDirectoryId"
            );
            this.keyLearnDirectoryId = JSON.parse(keyLearnDirectoryIds);
            const num =
                "chapterId=" +
                this.chapterId +
                "&keyLearnDirectoryId=" +
                this.keyLearnDirectoryId;
            queryKeyLearn(num).then((res) => {
                this.user = res.data.data
                    .slice()
                    .sort((a, b) => a.noteOrder - b.noteOrder); //变量1 用来显示获取的所有排序好的数据
                this.labnum1 = this.user.length;
                this.labnum2 = this.noteOrder;
                let labnum = parseInt(this.labnum1);
                let labnums = parseInt(this.labnum2 + 1);
                this.Complete = parseInt((labnums / labnum) * 100);
                this.htmlList = this.user[this.noteOrder]; //变量3 某一个小测的所有题目

                if (this.user.length > 0) {
                    this.noteTitle = this.htmlList.noteTitle;

                    if (this.htmlList.noteType == 1) {
                        document.getElementById("box2").style.backgroundColor =
                            "white";
                        this.dataObjects.quizId =
                            this.htmlList.keyLearnId.toString();
                        this.showButton3 = true; //left
                        this.showButton3_1 = true; //right
                        this.showButton = false; //next
                        var asd = JSON.parse(this.htmlList.noteContentJson);
                        asd.forEach((item, index) => {
                            this.dataObjects.answerData.push({
                                title: item.title,
                                titleType: item.titleType,
                                titleAnswer: [
                                    {
                                        content: "",
                                    },
                                ],
                            });
                        });

                        this.right();
                        this.showButton6 = true; //Q
                    } else if (this.htmlList.noteType == 0) {
                        this.textdata = JSON.parse(
                            this.htmlList.noteContentJson
                        ); //用来显示题目
                        document.getElementById("box2").style.backgroundColor =
                            "rgb(210,223,254)";
                        this.htmldata = this.textdata[0].titleContent;
                        this.showButton = true; //next
                    }
                }
            });
        },
        // 单选框回显
        setRadioAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");

            radios.forEach((item) => {
                if (
                    item.value ==
                    this.dataObjects.answerData[this.indexNum].titleAnswer[0]
                        .content
                ) {
                    item.checked = true;
                }
            });
        },
        // 复选框回显
        setCheckboxAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            var answerlist =
                this.dataObjects.answerData[
                    this.indexNum
                ].titleAnswer[0].content.split(",");
            radios.forEach((item) => {
                answerlist.forEach((answeritem) => {
                    answeritem == item.value && (item.checked = true);
                });
            });
        },
        // 填空框回显
        setInputAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem, i) => {
                inputItem.value =
                    this.dataObjects.answerData[
                        this.indexNum
                    ].titleAnswer[0].content;
            });
        },
        // 问答框回显
        setTextareaAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.value =
                    this.dataObjects.answerData[
                        this.indexNum
                    ].titleAnswer[0].content;
            });
        },
        // 为单选框绑定事件
        getRadioAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [
                        { content: "" },
                    ];
                    radios.forEach((item) => {
                        item.checked &&
                            (this.dataObjects.answerData[
                                this.indexNum
                            ].titleAnswer[0].content = item.value);
                    });
                });
            });
        },
        // 为复选框绑定事件
        getCheckboxAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [
                        { content: "" },
                    ];
                    var asdlist = [];
                    radios.forEach((item) => {
                        item.checked && asdlist.push(item.value);
                    });
                    this.dataObjects.answerData[
                        this.indexNum
                    ].titleAnswer[0].content = asdlist.join(",");
                });
            });
        },
        // 为填空框绑定事件
        getInputAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [];
                    inputs.forEach((item, index) => {
                        this.dataObjects.answerData[
                            this.indexNum
                        ].titleAnswer.push({
                            content: item.value,
                        });
                    });
                });
            });
        },
        // 为问答框绑定事件
        getTextareaAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.addEventListener("change", () => {
                    this.dataObjects.answerData[this.indexNum].titleAnswer = [
                        { content: "" },
                    ];
                    inputs.forEach((item) => {
                        this.dataObjects.answerData[
                            this.indexNum
                        ].titleAnswer[0].content = item.value;
                    });
                });
            });
        },
        asdd() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    radio.checked && alert("你选择了答案" + radio.value);
                });
            });
        },
        chock() {
            if (this.noteOrder == this.user.length - 1) {
                this.showButton1 = true;
                this.showButton = false;
            } else {
                this.noteOrder++;
                this.getinfo();
            }
        }, //下一页
        Next7() {
            this.ruturns = true;
            this.noteOrder++;
            this.getinfo();
            this.isDisabled = false; //百分比
            this.showButton3 = false; //left
            this.showButton3_1 = false; //right
            this.showButton7 = false; //Next
        },
        chose(noteOrder) {
            if (noteOrder<=this.noteOrder) {
                const chapterIds = localStorage.getItem("chapterId");
            this.chapterId = JSON.parse(chapterIds);
            const keyLearnDirectoryIds = localStorage.getItem(
                "keyLearnDirectoryId"
            );
            this.keyLearnDirectoryId = JSON.parse(keyLearnDirectoryIds);
            const num =
                "chapterId=" +
                this.chapterId +
                "&keyLearnDirectoryId=" +
                this.keyLearnDirectoryId;
            queryKeyLearn(num).then((res) => {
                this.user = res.data.data
                    .slice()
                    .sort((a, b) => a.noteOrder - b.noteOrder); //变量1 用来显示获取的所有排序好的数据
                this.labnum1 = this.user.length;
                this.labnum2 = noteOrder;
                let labnum = parseInt(this.labnum1);
                let labnums = parseInt(this.labnum2);
                this.Complete = parseInt((labnums / labnum) * 100);
                this.htmlList = this.user[noteOrder-1]; //变量3 某一个小测的所有题目

                if (this.user.length > 0) {
                    this.noteTitle = this.htmlList.noteTitle;

                    if (this.htmlList.noteType == 1) {
                        document.getElementById("box2").style.backgroundColor =
                            "white";
                        this.dataObjects.quizId =
                            this.htmlList.keyLearnId.toString();
                            this.isDisabled=false;
                        this.showButton3 = true; //left
                        this.showButton3_1 = true; //right
                        this.showButton = false; //next

                        var asd = JSON.parse(this.htmlList.noteContentJson);
                        asd.forEach((item, index) => {
                            this.dataObjects.answerData.push({
                                title: item.title,
                                titleType: item.titleType,
                                titleAnswer: [
                                    {
                                        content: "",
                                    },
                                ],
                            });
                        });

                        this.right();
                        this.showButton6 = true; //Q
                    } else if (this.htmlList.noteType == 0) {
                        this.showButton3 = false; //left
                        this.showButton3_1 = false; //left
                        this.showButton6 = false; //Q
                        this.showButton4=false;
                        this.showButton5=false;
                        this.isDisabled=false;
                        this.textdata = JSON.parse(
                            this.htmlList.noteContentJson
                        ); //用来显示题目
                        document.getElementById("box2").style.backgroundColor =
                            "rgb(210,223,254)";
                        this.htmldata = this.textdata[0].titleContent;
                        this.showButton = true; //next
                    }
                    }
            }
        )}
    },
        Return(){
            this.$router.push("/Undirectory");
        }, //返回
        Returns() {
            this.$router.push("/studentside");
        }, //返回
        async submit() {
            this.ruturns = false;
            var res = undefined;
            res = await quizAnswerJudgment(this.dataObjects);
            this.percentages = res.data.data;
            this.percentage = parseInt(this.percentages.replace("%", ""));
            console.log(res, "百分比");
            this.isDisabled = true;
            document.getElementById("box2").style.backgroundColor =
                "rgb(210, 223, 254)";
            this.title = "Results";
            this.htmldata = "";
            this.showButton6 = false; //Q
            this.showButton3 = false; //left
            this.showButton3_1 = false; //left
            this.showButton4 = false; //submit
            if (this.percentage >= 80) {
                this.ings = true;
                this.imgs = false;
                this.showButton7 = true; //Next
            } else {
                this.ings = false;
                this.imgs = true;
                this.showButton5 = true; //Retry
            }
            this.dataObjects.answerData = [];
        }, //submit
        Retry() {
            this.ruturns = true;
            var asd = JSON.parse(this.htmlList.noteContentJson);
            asd.forEach((item, index) => {
                this.dataObjects.answerData.push({
                    title: item.title,
                    titleType: item.titleType,
                    titleAnswer: [
                        {
                            content: "",
                        },
                    ],
                });
            });

            this.showButton6 = true; //Q
            this.showButton3 = true; //left
            this.showButton3_1 = true; //left
            this.showButton4 = true; //sumint
            this.indexNum = 0;
            this.isDisabled = false;
            this.showButton5 = false; //Retry
            this.textdata = JSON.parse(this.htmlList.noteContentJson); //用来显示题目
            document.getElementById("box2").style.backgroundColor =
                "rgb(255,255,255)";
            this.title = this.textdata[this.indexNum].title;
            switch (this.textdata[this.indexNum].titleType) {
                //单选题
                case 0:
                    this.htmldata = this.setRadio(
                        this.textdata[this.indexNum].titleContent
                    );
                    this.$nextTick(this.getRadioAnswer);
                    break;
                //多选题
                case 1:
                    this.htmldata = this.setCheckbox(
                        this.textdata[this.indexNum].titleContent
                    );
                    this.$nextTick(this.getCheckboxAnswer);
                    break;
                //填空题
                case 2:
                    this.htmldata = this.setInput(
                        this.textdata[this.indexNum].titleContent
                    );
                    this.$nextTick(this.getInputAnswer);
                    break;
                //问答题
                case 3:
                    this.htmldata = this.setTextarea(
                        this.textdata[this.indexNum].titleContent
                    );
                    this.$nextTick(this.getTextareaAnswer);
                    break;
                default:
                    this.$message.error("该题型未得到应用");
            }
        }, //Retry
        left() {
            if (this.indexNum > 0) {
                this.showButton3_1 = true; //left
                this.textdata = JSON.parse(this.htmlList.noteContentJson); //用来显示题目

                this.indexNum--;
                this.title = this.textdata[this.indexNum].title;
                switch (this.textdata[this.indexNum].titleType) {
                    //单选题
                    case 0:
                        this.htmldata = this.setRadio(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getRadioAnswer);
                        this.$nextTick(this.setRadioAnswer);
                        break;
                    //多选题
                    case 1:
                        this.htmldata = this.setCheckbox(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getCheckboxAnswer);
                        this.$nextTick(this.setCheckboxAnswer);
                        break;
                    //填空题
                    case 2:
                        this.htmldata = this.setInput(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getInputAnswer);
                        this.$nextTick(this.setInputAnswer);
                        break;
                    //问答题
                    case 3:
                        this.htmldata = this.setTextarea(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getTextareaAnswer);
                        this.$nextTick(this.setTextareaAnswer);
                        break;
                    default:
                        this.$message.error("该题型未得到应用");
                }
            } else if (this.indexNum < 0) {
                this.showButton3 = false;
            }
        }, //小节题目上一个
        right() {
            this.htmlList = this.user[this.noteOrder]; //变量3 某一个小测的所有题目
            console.log(this.htmlList, "123");
            this.textdata = JSON.parse(this.htmlList.noteContentJson); //用来显示题目
            console.log(this.textdata);
            if (this.indexNum < this.textdata.length - 1) {
                this.indexNum++;
                this.title = this.textdata[this.indexNum].title;
                switch (this.textdata[this.indexNum].titleType) {
                    //单选题
                    case 0:
                        this.htmldata = this.setRadio(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getRadioAnswer);
                        this.$nextTick(this.setRadioAnswer);
                        break;
                    //多选题
                    case 1:
                        this.htmldata = this.setCheckbox(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getCheckboxAnswer);
                        this.$nextTick(this.setCheckboxAnswer);
                        break;
                    //填空题
                    case 2:
                        this.htmldata = this.setInput(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getInputAnswer);
                        this.$nextTick(this.setInputAnswer);
                        break;
                    //问答题
                    case 3:
                        this.htmldata = this.setTextarea(
                            this.textdata[this.indexNum].titleContent
                        );
                        this.$nextTick(this.getTextareaAnswer);

                        break;
                    default:
                        this.$message.error("该题型未得到应用");
                }
                if (this.indexNum > 0) {
                    this.showButton3 = true; //left
                }
                if (this.indexNum == this.textdata.length - 1) {
                    this.showButton4 = true;
                }
                const list = {
                    title: this.textdata[this.indexNum].title, // 题目名
                    titleType: this.textdata[this.indexNum].titleType, // 题目类型
                    titleAnswer: [
                        {
                            content: "", // 题目答案
                        },
                    ],
                };

                this.dataObjects.answerData.push(list);
            }
        }, //小节题目增加下一个
    },
};
</script>
<style lang="less" scoped>
.body {
  min-height: 80vh;
  position: relative;
}
.header {
  width: 100%;
  height: 15vh;
  background-color: rgb(10, 32, 97);
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .spanitem {
    color: #fff;
    font-size: 25px;
    padding: 5px 0;
  }
  .spanitem_lable {
    color: #fff;
    font-size: 15px;
  }
}
.conter {
  background-color: rgb(10, 32, 97);
  width: 100%;
  height: 85vh;
  .conteritme {
    margin-left: 5%;

    .conteritme_span1 {
      font-size: 30px;
      line-height: 10svh;
      float: left;
    }
    .conteritme_span2 {
      font-size: 20px;
      float: right;
    }
  }
  .logindiv {
    width: 100%;
    min-width: 300px;
    height: 75%;
    background-color: #fff;
    box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    top: 5%;
    .swiper {
      height: 100%;
      display: flex;
      .right {
        flex: 2;
        height: 100%;
        .el-menu-vertical-demo {
          width: 30%;
          height: auto;
          margin: 2% 5%;
          text-align: center;
          overflow-y: auto;
        }
        .el-menu-vertical-demo::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .box {
          height: 50%;
          font-size: 25px;
        }
        .el-demo {
          font-size: 25px;
        }
        .el-demo:hover {
          background-color: rgb(10, 32, 97);
          span {
            color: #fff;
          }
        }
      }
      .left {
        flex: 3;
        height: 100%;
        border: 1px solid black;
      }
    }
  }
  .Header_text3 {
    width: 30%;
    height: 50px;
    margin: 0 12%;
    text-align: center;
    background-color: rgb(210, 233, 254);
    position: absolute;
    top: 50px;
    font-size: 20px;
    color: #fff;
    border: 1px solid red;
  }

  .Header_text3_router {
    text-decoration: none;
    border-bottom: 3px solid #fff;
    color: #561717;
    border: 1px solid red;
  }
  .el-menu-item {
    background-color: rgb(210, 223, 254);
    color: black;
    width: 100%;
    margin: 4% 0;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    font-size: large;
  }
  .el-menu-item:hover {
    background-color: rgb(10, 32, 97);
    color: #fff;
  }
}
.changeel-demo {
  font-size: 20px;
  background-color: rgb(10, 32, 97);
  span {
    color: #fff;
  }
}
.box {
  height: 85%;
}

.button {
  margin-left: 45%;
  background-color: rgb(10 32 97);
  color: #fff;
  position: absolute;
  bottom: 20px;
}

.button1 {
  background-color: rgb(10 32 97);
  color: #fff;
  position: absolute;
  left: 43%;
  bottom: 20px;
}
.button2 {
  margin-left: 90%;
  background-color: rgb(10 32 97);
  color: #fff;
  position: absolute;
  bottom: 20px;
}
.button3 {
  margin-left: 85%;
  text-align: center;
  position: relative;
  top: 28%;
}
.button4 {
  margin-left: 88%;
  background-color: rgb(10 32 97);
  position: absolute;
  bottom: 20px;
}
.button5 {
  background-color: rgb(10 32 97);
  position: absolute;
  left: 85%;
  bottom: 20px;
}
.button7 {
  background-color: rgb(10 32 97);
  position: absolute;
  left: 85%;
  bottom: 20px;
}

.box1 {
  font-size: 25px;
  position: absolute;
  top: 5%;
  left: 5%;
}
#box2 {
  font-size: 15px;
  position: relative;
  top: 30%;
  left: 5%;
  width: 92%;
  height: 120%;
  overflow-y: hidden;
}
#box2:hover {
  overflow-y: auto; /* Show scrollbar on hover */
}
.conteritmes {
  position: relative;
  left: 30px;
  float: right;
  .conteritme_span1 {
    float: right;
    font-size: 20px;
    color: #fff;
  }
  .conteritme_span2 {
    float: right;
    color: #fff;
  }
}

.el-icon-arrow-left,
.el-icon-arrow-right {
    font-size: 30px;
}
</style>