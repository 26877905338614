<template>
  <div style="height: 400px;">
    <el-scrollbar style="height:100%;">
      <el-collapse v-model="activeNames" style=" height: 400px" @change="handleChange">
        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 18px;">
              {{ $t('questions.answer') }}
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem1') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 18px;">
              {{ $t('questions.answer1') }}
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem2') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 18px;">
              {{ $t('questions.answer2') }}
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem3') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 18px;">
              {{ $t('questions.answer3') }}
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem4') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 18px;">
              {{ $t('questions.answer4') }}
            </span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style scoped>
.item {
  font-size: 85px;
}
</style>