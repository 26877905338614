<template>
    <div>
        <el-dialog :title="dialogType == 'add' ? '添加课程' : '修改课程基本信息'" :visible.sync="dialogFormVisible" width="500px"
            :before-close="handleClose">
            <el-form :model="courseform" label-width="120px" ref="courseform" :rules="rules">
                <el-form-item label="课程名称：" prop="subjectName">
                    <el-input v-model="courseform.subjectName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="原课程封面：" prop="subjectName" v-if="dialogType == 'updata'">
                    <img :src="'https://genztutoringnlabs.com/service/' + oldImageUrl" class="avatar">
                </el-form-item>
                <el-form-item label="现课程封面：">
                    <div class="uploaddiv">
                        <el-upload class="avatar-uploader" :action="BaseURL + '/file/uploadImgFile'" :headers="token"
                            :show-file-list="false" :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                            <img v-if="courseform.imageUrl"
                                :src="'https://genztutoringnlabs.com/service/' + courseform.imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon" style="font-size: 100px;"></i>
                        </el-upload>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">{{ dialogType == 'add' ? '确认添加' : '确认修改' }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { BaseURL } from '@/utils/request/baseURL.js'
export default {
    data() {
        return {
            courseform: {
                subjectName: '',
                imageUrl: ''
            },
            oldImageUrl: '',
            dialogFormVisible: false,
            rules: {
                subjectName: [
                    { required: true, message: '请输入课程名称', trigger: 'blur' }
                ],
                imageUrl: [
                    { required: true, message: '请上传图片', trigger: 'blur' }
                ]
            },
            token: {
                Authorization: ''
            },
            dialogType: '',
            BaseURL: BaseURL
        }
    },
    methods: {
        handleAvatarSuccess(res, file) {
            this.courseform.imageUrl = URL.createObjectURL(file.raw);
            this.courseform.imageUrl = res.data
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        confirm() {
            this.$refs.courseform.validate((valid) => {
                if (valid) {
                    this.$emit('confirmcourse')
                } else {
                    return false;
                }
            });
        },
        handleClose() {
            this.dialogFormVisible = false
            this.$refs.courseform.resetFields();
        }
    },
    mounted() {
        this.token.Authorization = 'Bearer ' + localStorage.getItem('token')
    }
}
</script>

<style lang="less" scoped>
.uploaddiv {
    width: 120px;
    height: 120px;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar {
    max-height: 100px;
    max-width: 100px;
}
</style>