<template>
    <div class="body">
        <div class="header">
            <span class="spanitem" @click="tourl()">GENZ {{ $t('homepage.header') }}</span>
            <div>
                <el-dropdown @command="handleCommand" trigger="click" style="cursor: pointer; margin-right:50px">
                    <span class="el-dropdown-link" style="color: #fff; font-size:18px">
                        {{ languageType }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <span @click="tologin" style="color: #fff; font-size:18px;margin-left:30px">{{ $t('login.btn1') }}</span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="English">English</el-dropdown-item>
                        <el-dropdown-item command="中文繁體">中文繁體</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <span class="spanitem" style="font-size: 25px; cursor: pointer;" @click="isshow = true">=</span>
            </div>
        </div>
        <!-- 遮挡层 -->
        <div class="barrier-bed" v-if="isshow">
            <span @click="isshow = false" class="close"><i class="el-icon-close"></i></span>
            <div class="navigation">
                <span  class="spanitem" @click="navgin('/about')">{{$t('homepage.navigation1')}}</span>
                <span  class="spanitem" @click="navgin('/academic')">{{$t('homepage.navigation2')}}</span>
                <span  class="spanitem" @click="navgin('/consultancy')">{{$t('homepage.navigation3')}}</span>
                <span  class="spanitem" @click="navgin('/contact')">{{$t('homepage.navigation4')}}</span>
                <span  class="spanitem" @click="navgin('/examination')">{{$t('homepage.navigation6')}}</span>
                <span  class="spanitem" @click="navgin('/tutoring')">{{$t('questions.title')}}</span>   </div>
        </div>
        <router-view></router-view>
        <div style="height: 135px;"></div>
        <div class="copyright">
            <div class="copyright-left">
                <span class="left-top">GENZ {{ $t('homepage.header') }}</span>
                <span class="left-bottom">felicialee.tutoring@gmail.com</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isshow: false,
            languageType: 'English'
        }
    },
    methods: {
        handleCommand(command) {
            if (command == 'English') {
                this.$i18n.locale = 'en'
            } else if (command == '中文繁體') {
                this.$i18n.locale = 'Tw'
            }

            this.languageType = command
        },
        tologin(){
            this.$router.push('/login')
        },
        tourl () {
      if (this.$route.path == '/' || this.$route.path == '/home') {
        return false
      }
      this.$router.push('/home')
    },
    navgin (url) {
      this.$router.push(url)
      this.isshow = false
    },
    }
}
</script>

<style scoped>
.body {
    min-height: 100vh;
    position: relative;
}

.header {
    width: 100%;
    height: 90px;
    background-color: black;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .spanitem {
        color: #fff;
        font-size: 20px;
    }
}

.barrier-bed {
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1000;
    position: fixed;
    top: 0;
    opacity: 0.9
}

.close {
    position: absolute;
    right: 10%;
    top: 20px;
    color: #fff;
    cursor: pointer;
}

.navigation {
    position: absolute;
    right: 10%;
    top: 80px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .spanitem {
        text-decoration: none;
        color: #fff;
        margin-bottom: 20px;
        border-bottom: 1px solid #fff;
    }
}

.copyright {
    width: 100%;
    height: 127px;
    padding: 0 5%;
    box-sizing: border-box;
    background-color: black;
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: space-between;

    .copyright-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .left-top {
            font-size: 20px;
            color: #fff;
            cursor: pointer;
        }

        .left-top:hover {
            border-bottom: 1px solid #eee;
        }

        .left-bottom {
            margin-top: 15px;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            border-bottom: 1px solid #eee;
        }

        .left-bottom:hover {
            border-bottom: 0px;
        }
    }
}
</style>