<template>
    <div class="page">
        <el-row>
            <el-col :span="24" :sm="12">
                <div class="page-left">
                    <div class="page-left-title">{{ $t('academic.title')}}</div>
                    <div class="page-introduce">
                        <span>
                            {{ $t('academic.introduce')}}
                        </span>
                    </div>
                    <div class="subjectlist">
                        <ul>
                            <li>{{ $t('academic.li1')}}</li>
                            <li>{{ $t('academic.li2')}}</li>
                            <li>{{ $t('academic.li3')}}</li>
                            <li>{{ $t('academic.li4')}}</li>
                            <li>{{ $t('academic.li5')}}</li>
                            <li>{{ $t('academic.li6')}}</li>
                            <li>{{ $t('academic.li7')}}</li>
                            <li>{{ $t('academic.li8')}}</li>
                        </ul>
                    </div>
                    <div class="morediv" @click=" tourl ()">
                        {{ $t('academic.morediv')}}
                    </div>
                </div>
            </el-col>
            <el-col :span="24" :sm="{span:10, offset:2}">
                <div class="page-right">
                    <div>{{ $t('Search') }}</div>
                    <div style="display: flex;">
                        <input type="text" style="height: 54px;background-color: rgb(204,232,207);border: 0.8px solid rgb(156 156 156);margin-right: 10px;flex:1">
                        <button style="height: 58px;background-color: #002060;width:100px; border:0px; color:#fff">{{ $t('Search') }}</button>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  data () {
    return {
      isshow: false,
      languageType: 'English'
    }
  },
  methods: {
    tourl () {
      this.$router.push('/contact')
    }
  }
}
</script>

<style scoped>
.page {
    margin: 0 10%;
    margin-top: 80px;
}

.page-left{
    margin-bottom: 60px;
}

.page-left-title {
    font-size: clamp(2.111rem, 2.111rem + ((1vw - 0.2rem) * 3.217), 3.8rem);
    font-weight: 500;
}

.page-introduce{
    margin-top: 20px;
    line-height: 30px;
    font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.476), 18px)
}

.subjectlist{
    margin-top: 30px;
    font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.476), 18px);
    line-height: 1.6;
}

.page-right{
    width: 100%;
}

.morediv{
    width: 225px;
  font-size: 15px;
    cursor: pointer;
}

</style>