<template>
    <div class="body">
        <div class="header">
            <div class="">
                <img src="@/utils/img/logo.png" @click="Returns()" style="width: 150px;height: 66px;">
            </div>
            <div>
                <div class="conteritmes">
                    <span class="conteritme_span1">{{ userData.account }}</span><br />
                    <span class="conteritme_span2">{{ userData.id }}</span>
                </div>
            </div>
        </div>
        <div class="conter">
            <div class="conteritme">
                <span style=" font-size: 25px;color: #fff;">{{ chapterInfoPageData.chapterName }}</span><br>
                <span style=" font-size: 25px;color: #fff;">Vocabularies</span>
            </div>
            <div class="logindiv">
                <div class="swiper">
                    <div class="right">
                        <el-scrollbar style="height:80%;">
                            <el-table :data="user" stripe style="width: 100vw;height: 100%;margin-bottom: 50px;">
                                <el-table-column label="" width="40">
                                    <template v-slot="scope">
                                        <div :style="getCellStyle(scope.row.selfAssessment)" @click="toggleCellColor(scope.row)" style="border: 1px solid rgb(226, 211, 218); box-shadow: 1px 0px 1px 0 rgba(0, 0, 0, 0.1); cursor: pointer;">

                                            <span style="opacity: 0;">.</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="originalText" label="Key term" width="400">
                                </el-table-column>
                                <el-table-column prop="translatedText" label="Translation " width="400">
                                </el-table-column>
                                <el-table-column label="Meaning" width="550">
                                    <template v-slot="scope">
                                        <div class="cell-text-overflow" :title="text">{{ scope.row.definition }}</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-scrollbar>
                        <el-button type="primary" @click=" chose()" style="margin-left: 47%;background-color: rgb(10,32,97)">Back</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  <script>
import { Glossary } from "@/views/Userside/Undirectory/Vocabularies/Glossary/api/index.js";
import { selfAssessment } from "@/views/Userside/Undirectory/Vocabularies/Glossary/api/index.js";
export default {
    data() {
        return {
            userData: "",
            chapterInfoPageData: "",
            chapterId: "",
            chapterName: "",
            user: [],
        };
    },
    mounted() {
        this.getinfo();
        // 在组件挂载后获取本地存储的数据
        const user = localStorage.getItem("user");
        this.userData = JSON.parse(user);
        const chapterInfo = localStorage.getItem("chapterInfoPageData");
        this.chapterInfoPageData = JSON.parse(chapterInfo);
    },
    methods: {
        async getinfo() {
            const user = localStorage.getItem("user");
            this.userData = JSON.parse(user);
            const chapterIds = localStorage.getItem("chapterId");
            this.chapterId = JSON.parse(chapterIds);
            const num =
                "chapterId=" +
                this.chapterId +
                "&studentId=" +
                this.userData.id;
            Glossary(num).then((res) => {
                this.user = res.data.data;
                console.log(" this.user", this.user);
            });
        },
        chose() {
            this.$router.push("/Undirectory");
        },
        Returns(){
            this.$router.push("/studentside");
        },
        getCellStyle(colorState) {
            // 根据colorState值返回不同的背景颜色
            return {
                backgroundColor:
                    colorState === 1
                        ? "green"
                        : colorState === 2
                        ? "orange"
                        : colorState === 3
                        ? "red"
                        : "while",
            };
        },
        async toggleCellColor(row) {
            console.log(row, "row");
            row.selfAssessment = (row.selfAssessment + 1) % 4;
            this.user.selfAssessment = row.selfAssessment;
            var res = undefined;
            var user = {
                studentId: this.userData.id,
                selfAssessment: row.selfAssessment,
                vocabularyId: row.vocabulariesId,
            };
            console.log("user", user);
            res = await selfAssessment(user);
            console.log("res", res);
            this.getinfo();
        },
    },
};
</script>
  <style lang="less" scoped>
.body {
    min-height: 80vh;
    position: relative;
}
.header {
    width: 100%;
    height: 15vh;
    background-color: rgb(10, 32, 97);
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .spanitem {
        color: #fff;
        font-size: 25px;
        padding: 5px 0;
    }
    .spanitem_lable {
        color: #fff;
        font-size: 15px;
    }
}
.conter {
    background-color: rgb(10, 32, 97);
    width: 100%;
    height: 85vh;
    .conteritme {
        margin-left: 5%;

        .conteritme_span1 {
            font-size: 30px;
            line-height: 10svh;
            float: left;
        }
        .conteritme_span2 {
            font-size: 20px;
            float: right;
        }
    }
    .logindiv {
        width: 100%;
        min-width: 300px;
        height: 84%;
        background-color: #fff;
        box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        top: 2%;
        .swiper {
            height: 100%;
            display: flex;
            .right {
                height: 100%;
            }
        }
    }
}
.conteritmes {
    float: right;
    margin-right: 45%;
    .conteritme_span1 {
        float: right;
        font-size: 20px;
        color: #fff;
    }
    .conteritme_span2 {
        float: right;
        color: #fff;
    }
}
</style>
  