<template>
    <div style=" width: 80%; height: 90%;margin: 0 50px;">
        <div class="coursediv">
  
            <div class="subjectImgUrl"  @click="chose()">
              <img :src="'https://genztutoringnlabs.com/service/' +  this.user.subjectImgUrl" alt="" style="max-height: 230px; max-width: 300px;">
            </div>
            <div class="subjectName">
                <span style="padding: 10px 0;">Past Papers</span>
  
            </div>
        </div>
    </div>
  
  </template>
<script>
import { getSubjectImgPath } from "@/views/Userside/Undirectory/Pastpapers/api/index.js";

export default {
    data() {
        return {
            subjectId: "",
            user: [],
        };
    },
    mounted() {
        this.getinfo();
    },
    methods: {
        async getinfo() {
            const subjectIds = localStorage.getItem("subjectId");
            this.subjectId = JSON.parse(subjectIds);
            const num = "subjectId=" + this.subjectId;
            getSubjectImgPath(num).then((res) => {
                this.user = res.data.data;
            });
        },
        chose() {
            this.$router.push("/Subjectpaper");
        },
    },
};
</script>
<style lang="less" scoped>
.coursediv {
        width: 450px;
        height: 350px;
        border: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 10px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

        .subjectName {
            border-bottom: 1px solid #eee;
            display: flex;
            justify-content: center;
        }

        .subjectImgUrl {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5% 0;
        }

        .subjectImgUrl:hover {
            cursor: pointer;
        }


    }

    .coursediv:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
</style>
