<template>
    <div>
        <el-dialog :title="dialogType == 'add' ? '添加老师信息' : '修改老师信息'" :visible.sync="dialogFormVisible" width="400px"
            :before-close="handleClose">
            <el-form :model="teacherform" label-width="100px" ref="teacherform" :rules="rules">
                <el-form-item label="账号名称" prop="account">
                    <el-input v-model="teacherform.account"></el-input>
                </el-form-item>
                <el-form-item label="老师昵称" prop="nickName">
                    <el-input v-model="teacherform.nickName"></el-input>
                </el-form-item>
                <el-form-item label="老师性别：">
                    <el-radio-group v-model="teacherform.userSex">
                        <el-radio :label=1>男</el-radio>
                        <el-radio :label=0>女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="老师职位：" prop="position">
                    <el-input v-model="teacherform.position"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="confirm">{{ dialogType == 'add' ? '确认添加' : '确认修改' }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            teacherform: {},
            dialogFormVisible: false,
            dialogType: '',
            rules: {
                nickName: [
                    { required: true, message: '请输入学生名称', trigger: 'blur' }
                ],
                account: [
                    { required: true, message: '请输入账号名称', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        confirm() {
            this.$refs.teacherform.validate((valid) => {
                if (valid) {
                    this.$emit('confirmTeacher')
                } else {
                    return false;
                }
            });
        },
        handleClose() {
            this.dialogFormVisible = false
            this.$refs.teacherform.resetFields();
        }
    }
}
</script>

<style></style>