import serve from '@/utils/request/http.js'

// 修改密码
export function updateStudentPassword(data){
    return serve({
        url:'/userManage/updateStudentPassword',
        method:'post',
        data
    })
}
