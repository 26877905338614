import serve from '@/utils/request/http.js'

export function queryTestPaperInfo(params) {
    return serve({
        url: '/proficiencyTest/queryTestPaper',
        method: 'get',
        params
    })
}

export function answerJudgmentInfo(data) {
    return serve({
        url: '/proficiencyTest/answerJudgment',
        method: 'post',
        data
    })
}

export function queryKeyLearnDirectoryInfo(params) {
    return serve({
        url: '/refinedNotes/queryKeyLearnDirectory',
        method: 'get',
        params
    })
}

export function getTestTeportInfo(params) {
    return serve({
        url: '/proficiencyTest/getTestTeport',
        method: 'get',
        params
    })
}

export function getTestPaperCallbackInfo(params) {
    return serve({
        url: '/proficiencyTest/getTestPaperCallback',
        method: 'get',
        params
    })
}







