<template>
    <div class="body">
        <div class="header">
            <div class="">
            <img src="@/utils/img/logo.png" @click="Returns()"  style="width: 150px;height: 66px;">
            </div>
            <div>
                <div class="conteritmes">
                    <span class="conteritme_span1">{{ userData.account }}</span><br />
                    <span class="conteritme_span2">{{ userData.id }}</span>
                </div>
            </div>
        </div>
        <div class="conter">
            <div class="conteritme">
                <span style=" font-size: 25px;color: #fff;">{{ chapterInfoPageData.chapterName }}</span><br>
            </div>
            <div class="logindiv">
                <div class="swiper">
                    <div class="right">
                        <el-collapse accordion @change="handleChange">
                            <el-collapse-item v-for="(item, index) in keyLearnDirectoryList" :key="index" :name="index">
                                <template slot="title">
                                    <div :class="['titlediv', index == setindex ? 'settitlediv' : '']">
                                        <div class="item" v-if="item.numberOfAttempts <= 0"></div>
                                        <div class="item item-error"
                                            v-if="item.highestAttemptedScore <= 40 && item.highestAttemptedScore >= 0 && item.numberOfAttempts > 0">
                                        </div>
                                        <div class="item item-warning"
                                            v-if="item.highestAttemptedScore > 40 && item.highestAttemptedScore <= 80">
                                        </div>
                                        <div class="item item-success" v-if="item.highestAttemptedScore > 80"></div>
                                        <div class="titleName">{{ item.keyLearnDirectoryName }}</div>
                                    </div>
                                </template>
                                <div style="padding: 0 50px;">
                                    <h2 style="margin-left: 10px;">Studen Report</h2>
                                    <div class="studendiv">
                                        <div class="studendiv-item">
                                            <p>Number of attempts</p>
                                            <p>{{ item.numberOfAttempts }}</p>
                                        </div>
                                        <div class="studendiv-item">
                                            <p>Last attempted score</p>
                                            <p>{{ item.lastAttemptedScore }}</p>
                                        </div>
                                        <div class="studendiv-item">
                                            <p>Highest attempted score</p>
                                            <p>{{ item.highestAttemptedScore }}</p>
                                        </div>
                                        <div class="studendiv-item">
                                            <p>Key areas for revision</p>
                                            <p>{{ item.vulnerabilityAnalysis }}</p>
                                        </div>
                                    </div>
                                    <div class="btndiv">
                                        <div class="btn-div">
                                            <div @click="tourl(item, '/evaluationPaperview')" class="btn-item" style="margin-right: 20px;"
                                                v-if="item.numberOfAttempts > 0">
                                                Review Paper</div>
                                            <div @click="tourl(item, '/evaluationIndexview')" class="btn-item">Start
                                                Test</div>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { queryKeyLearnDirectoryInfo, getTestTeportInfo } from './api/index.js'
export default {
    data() {
        return {
            userData: "",
            chapterInfoPageData: "",
            keyLearnDirectoryList: [],
            setindex: -1
        }
    },
    methods: {
        async queryKeyLearnDirectory() {
            const res = await queryKeyLearnDirectoryInfo({ chapterId: localStorage.getItem('chapterId') })
            if (res.data.statusCode == 200) {
                res.data.data.forEach((item, index) => {
                    this.keyLearnDirectoryList.push({
                        keyLearnDirectoryName: item.keyLearnDirectoryName,
                        highestAttemptedScore: '',
                        numberOfAttempts: '',
                        lastAttemptedScore: '',
                        vulnerabilityAnalysis: '',
                        keyLearnDirectoryId: item.keyLearnDirectoryId
                    })
                    this.getTestTeport(item, index)
                });
            }
        },
        async getTestTeport(item, index) {
            const res = await getTestTeportInfo({
                studentId: this.userData.id,
                keyLearnDirectoryId: item.keyLearnDirectoryId
            })
            if (res.data.statusCode == 200) {
                this.keyLearnDirectoryList[index].numberOfAttempts = res.data.data.numberOfAttempts
                this.keyLearnDirectoryList[index].lastAttemptedScore = res.data.data.lastAttemptedScore
                this.keyLearnDirectoryList[index].highestAttemptedScore = res.data.data.highestAttemptedScore
                this.keyLearnDirectoryList[index].vulnerabilityAnalysis = res.data.data.vulnerabilityAnalysis
            }
        },
        Returns(){
            this.$router.push("/studentside");
        },
        tourl(item, path) {
            this.$router.push({
                path,
                query: {
                    keyLearnDirectoryId: item.keyLearnDirectoryId,
                    score: item.lastAttemptedScore,
                    vulnerabilityAnalysis: item.vulnerabilityAnalysis
                }
            })
        },
        handleChange(val) {
            if (val === '') {
                this.setindex = -1
            } else {
                this.setindex = val
            }
        }
    },
    mounted() {
        this.userData = JSON.parse(localStorage.getItem('user'))
        this.chapterInfoPageData = JSON.parse(localStorage.getItem('chapterInfoPageData'))
        this.queryKeyLearnDirectory()
    },

}
</script>

<style lang="less" scoped>
.body {
    min-height: 80vh;
    position: relative;
}

.header {
    width: 100%;
    height: 15vh;
    background-color: rgb(10, 32, 97);
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .spanitem {
        color: #fff;
        font-size: 25px;
        padding: 5px 0;
    }

    .spanitem_lable {
        color: #fff;
        font-size: 15px;
    }
}

.conter {
    background-color: rgb(10, 32, 97);
    width: 100%;
    height: 85vh;

    .conteritme {
        margin-left: 5%;

        .conteritme_span1 {
            font-size: 30px;
            line-height: 10svh;
            float: left;
        }

        .conteritme_span2 {
            font-size: 20px;
            float: right;
        }
    }

    .logindiv {
        width: 100%;
        min-width: 300px;
        height: 87%;
        background-color: #fff;
        box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        top: 5%;
        background-color: #f9fafe;

        .swiper {
            height: 100%;
            display: flex;
            justify-content: center;

            .right {
                width: 60%;
                height: 40%;
                margin-top: 30px;
                .titlediv {
                    background-color: #b4bed7;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    color: black;

                    .item {
                        height: 100%;
                        width: 30px;
                        background-color: #b4bed7;
                    }

                    .item-error {
                        background-color: #fd6363;
                    }

                    .item-warning {
                        background-color: #feaa48;
                    }

                    .item-success {
                        background-color: #85e96d;
                    }

                    .titleName {
                        flex: 1;
                        text-align: center;
                    }
                }

                .settitlediv {
                    background-color: #0a1f62;
                    color: #fff;
                }

                .studendiv {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    font-size: 18px;

                    .studendiv-item {
                        display: flex;
                        justify-content: space-between;
                        width: 60%;
                    }

                    p {
                        margin: 0px;
                    }
                }

                .btndiv {
                    width: 100%;
                    display: flex;

                    .btn-div {
                        margin-left: auto;
                        margin-top: 20px;
                        display: flex;

                        .btn-item {
                            width: 150px;
                            height: 40px;
                            background-color: #0a1f62;
                            color: #fff;
                            text-align: center;
                            line-height: 40px;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.cell-text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>

<style lang="less">
.right .el-collapse-item__arrow {
    display: none;
}
.conteritmes {
    float: right;
    margin-right: 45%;
    .conteritme_span1 {
        float: right;
        font-size: 20px;
        color: #fff;
    }
    .conteritme_span2 {
        float: right;
        color: #fff;
    }
}
</style>