<template>
    <el-dialog title="修改密码" :visible.sync="dialogFormVisible" :before-close="handleClose">
        <el-form :model="passwordFrom" ref="passwordFrom" :rules="rules">
            <el-form-item label="原密码" label-width="120px" prop="oldPassword">
                <el-input v-model="passwordFrom.oldPassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码" label-width="120px" prop="newPassword1">
                <el-input v-model="passwordFrom.newPassword1" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" label-width="120px" prop="newPassword2">
                <el-input v-model="passwordFrom.newPassword2" show-password></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="pushPasswordFrom">确 定</el-button>
        </div>
    </el-dialog>

</template>

<script>
export default {
    data() {
        return {
            dialogFormVisible: false,
            passwordFrom: {
                oldPassword: '',
                newPassword1: '',
                newPassword2: ''
            },
            rules: {
                oldPassword: [
                    { required: true, message: '请输入原密码', trigger: 'blur' }
                ],
                newPassword1: [
                    { required: true, message: '请输入新密码', trigger: 'blur' }
                ],
                newPassword2: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        pushPasswordFrom() {
            this.$refs.passwordFrom.validate((valid) => {
                if (valid) {
                    this.$emit('setpasswordFrom')
                } else {
                    this.$message.error('请完善表单信息！')
                    return false;
                }
            });

        },
        handleClose() {
            this.$refs.passwordFrom.resetFields()
            this.dialogFormVisible = false
        }
    }
}
</script>

<style></style>