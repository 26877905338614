import serve from '@/utils/request/http.js'

export function queryKeyLearnDirectory(params) {
    return serve({
        url: '/refinedNotes/queryKeyLearnDirectory?' + params,
        method: 'get',
    })

}

export function updateChapterClickTime(params) {
    return serve({
        url: '/chapter/updateChapterClickTime?' + params,
        method: 'get',
    })

}
