import serve from '@/utils/request/http.js'

// 添加学生信息接口
export function addStudent(data){
    return serve({
        url:'/userManage/addStudent',
        method:'post',
        data
    })
}

// 修改学生信息接口
export function updataStudent(data){
    return serve({
        url:'/userManage/updateStudentInfo',
        method:'post',
        data
    })
}

// 获取学生信息接口
export function getStudent(params){
    return serve({
        url:'/userManage/queryPagingStudent',
        method:'get',
        params
    })
}

// 删除学生信息接口
export function delStudent(params){
    return serve({
        url:'/userManage/deleteStudent',
        method:'get',
        params
    })
}

//重置密码
export function resetPassword(params){
    return serve({
        url:'/userManage/resetStudentPassword',
        method:'get',
        params
    })
}