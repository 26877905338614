<template>
    <div class="loginpage">
        <div class="brand">
            <span class="span1">GenZ</span>
            <span class="span2">Tutoring and Labs</span>
        </div>
        <div class="logindiv">
            <span class="languageType" @click="languageSwitch()">{{ command }}</span>
            <div class="loginform">
                <span class="logintitle">{{ $t('login.title') }}</span>
                <div class="formdiv">
                    <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
                        <el-form-item prop="account" :rules="{ required: true, message: $t('login.usernameRules'), trigger: 'blur' }">
                            <el-input v-model="ruleForm.account" :placeholder="$t('login.username')" class="inputcss">
                                <span slot="prefix" class="iconfont icon-yonghu" style="color: #0A2061;font-size:20px"></span>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password" type style="margin-top: 40px;" :rules="{ required: true, message: $t('login.userpasswordRules'), trigger: 'blur' }">
                            <el-input :placeholder="$t('login.userpassword')" v-model="ruleForm.password" type="password" class="inputcss">
                                <span slot="prefix" class="iconfont icon-mima" style="color: #0A2061;font-size:20px"></span>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="btndiv">
                            <el-row style="width: 100%;">
                                <el-col :span="14">
                                    <el-button type="primary" @click="submitForm('ruleForm')" class="userbtn">
                                        {{ $t('login.btn1') }}
                                    </el-button>
                                </el-col>
                                <el-col :span="9" :offset="1">
                                    <el-button type="primary" @click="submitForm('ruleForm', 'ture')" class="tutorbtn">
                                        {{ $t('login.btn2') }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sm2Util from "@/utils/sm2Util";
import { loginapi } from "./api/index.js";
export default {
    data() {
        return {
            languageType: "Eng",
            ruleForm: {
                account: "",
                password: "",
            },
            command: "Eng",
        };
    },
    methods: {
        submitForm(formName, logintype = false) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    //const res = await loginapi(this.ruleForm)
                    const res = await loginapi({
                        account: sm2Util.sm2Encrypt(this.ruleForm.account),
                        password: sm2Util.sm2Encrypt(this.ruleForm.password),
                    });
                    const { data } = res;
                    if (data.statusCode == 200) {
                        localStorage.setItem("token", data.data.token);
                        localStorage.setItem(
                            "user",
                            JSON.stringify({
                                account: data.data.account,
                                id: data.data.id,
                                roleMark: data.data.roleMark
                            })
                        );
                        if (data.data.roleMark == 0 && logintype) {
                            this.$router.push("/MangenmentHome");
                            this.$message({
                                message: data.data.loginMsg,
                                type: "success",
                            });
                        } else if (data.data.roleMark == 1 && !logintype) {
                            this.$router.push("/studentside");
                        } else if (data.data.roleMark == 2 && logintype) {
                            this.$router.push("/MangenmentHome");
                            this.$message({
                                message: data.data.loginMsg,
                                type: "success",
                            });
                        } else {
                            this.$message({
                                message: "账号或密码错误！",
                                type: "error",
                            });
                        }
                    } else {
                        this.$message({
                            message: "账号或密码错误！",
                            type: "error",
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        languageSwitch() {
            this.$refs.ruleForm.resetFields();
            if (this.command == "Eng") {
                this.$i18n.locale = "Tw";
                this.command = "中";
            } else if (this.command == "中") {
                this.$i18n.locale = "en";
                this.command = "Eng";
            }
        },
    },
};
</script>

<style lang="less" scoped>
.loginpage {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(-45deg, #fff, #0a2061);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand {
    width: 232px;
    height: 115px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
}

.span1 {
    font-size: 50px;
    padding-left: 30px;
}

.span2 {
    font-size: 20px;
    margin-top: 10px;
}

.logindiv {
    width: 25%;
    min-width: 280px;
    height: 54.9%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    padding: 20px 40px;
}

.languageType {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #bdbdbd;
    cursor: pointer;
}

.loginform {
    margin-top: 30px;
}

.logintitle {
    font-size: 24px;
    color: #0a2061;
    font-weight: 600;
}

.formdiv {
    margin-top: 40px;

    .inputcss {
        border: 1px solid #0a2061;
        border-radius: 5px;
    }

    .btndiv {
        margin-top: 40px;
        width: 100%;

        .userbtn {
            background-color: #0a2061;
            width: 100%;
        }

        .tutorbtn {
            background-color: #d2dffe;
            color: #0a2061;
            border: 0px;
            width: 100%;
        }
    }
}
</style>