import Vue from 'vue'
import VueRouter from 'vue-router'
import homePage from '../views/officialWebsite/homePage.vue'
import HomeView from '../views/officialWebsite/HomeView.vue'
import academicview from '../views/officialWebsite/academic.vue'
import contactview from '../views/officialWebsite/contact.vue'
import examinationview from '../views/officialWebsite/examination.vue'
import consultancyview from '../views/officialWebsite/consultancy.vue'
import aboutview from '../views/officialWebsite/about.vue'
import resourcesview from '../views/officialWebsite/resources.vue'
import loginview from '../views/login.vue'
import questionsview from '../views/FAQ/questions.vue'
import tutoringview from '../views/FAQ/Tutoring.vue'
import educationview from '../views/FAQ/education.vue'
import studentsideview from '../views/Userside/Usersidehome/studentside/index.vue'
import CourseHomePageview from '../views/Userside/CourseHomePage/CourseHome/index.vue'


import Directoryview from '../views/Userside/Undirectory/Directory/index.vue'
import Vocabulariesview from '../views/Userside/Undirectory/Vocabularies/components/index.vue'
import Glossaryview from '../views/Userside/Undirectory/Vocabularies/Glossary/components/index.vue'
import Undirectoryview from '../views/Userside/Undirectory/Undirectory/components/index.vue'
import Greenlightestview from '../views/Userside/Undirectory/Greenlightest/components/index.vue'
import Pastpapersview from '../views/Userside/Undirectory/Pastpapers/components/index.vue'
import Compactnoteview from '../views/Userside/Undirectory/Undirectory/Compactnote/Compactnote/index.vue'
import Subjectpaperview from '../views/Userside/Undirectory/Pastpapers/Subjectpaper/index.vue'
import evaluationview from '../views/Userside/Undirectory/Greenlightest/evaluation/evaluation.vue'
import evaluationIndexview from '../views/Userside/Undirectory/Greenlightest/evaluation/index.vue'
import evaluationPaperview from '../views/Userside/Undirectory/Greenlightest/evaluation/reviewPaper.vue'
import Aftermodeview from '../views/Userside/Undirectory/Pastpapers/Aftermode/index.vue'
import Afteindexiew from '../views/Userside/Undirectory/Pastpapers/Aftermode/afterindex.vue'

import MangenmentHome from '../views/Management/home/index.vue'
import MangenmentStudent from '../views/Management/student/index.vue'
import MangenmentCourse from '../views/Management/course/index.vue'
import MangenmentChapter from '../views/Management/chapter/index.vue'
import MangenmentVocabulary from '../views/Management/vocabularyList/index.vue'
import MangenmentRefinednote from '../views/Management/refinedNote/index.vue'
import MangenmentTheme from '../views/Management/theme/index.vue'
import MangenmentLevelTest from '../views/Management/levelTest/index.vue'
import MangenmentTeacher from '../views/Management/teacher/index.vue'
import MangenmentPastPapers from '../views/Management/pastPapers/index.vue'

import { Message } from 'element-ui'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 重写原型上的push方法，统一处理错误信息
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: homePage,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: HomeView
      },
      {
        path: '/academic',
        component: academicview
      },
      {
        path: '/contact',
        component: contactview
      },
      {
        path: '/examination',
        component: examinationview
      },
      {
        path: '/consultancy',
        component: consultancyview
      },
      {
        path: '/about',
        component: aboutview
      },
      {
        path: '/resources',
        component: resourcesview
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: loginview
  },
  {
    path: '/questions',
    name: 'questions',
    component: questionsview,
    redirect: '/questions',
    children: [
      {
        path: '/questions',
        component: questionsview
      },
      {
        path: '/Tutoring',
        component: tutoringview
      },
      {
        path: '/education',
        component: educationview
      }
    ]
  },
  {
    path: '/studentside',
    name: 'studentside',
    component: studentsideview
  },
  {
    path: '/CourseHomePage',
    name: 'CourseHomePage',
    component: CourseHomePageview
  },
  {
    path: '/Glossary',//词汇页面
    component: Glossaryview
  },
  {
    path: '/Directory',//单元目录
    name: 'Directory',
    component: Directoryview,
    redirect: '/Undirectory',
    children: [
      {
        path: '/Undirectory',//笔记
        component: Undirectoryview,
      },
      {
        path: '/Vocabularies',//词汇
        component: Vocabulariesview
      },
      {
        path: '/Greenlightest',//单元测评
        component: Greenlightestview
      },
      {
        path: '/Pastpapers',//过往试卷
        component: Pastpapersview
      }
    ]
  },
  {
    path: '/Compactnote',//精简笔记
    name: 'Compactnote',
    component: Compactnoteview
  },
  {
    path: '/evaluation',//测评
    name: 'evaluation',
    component: evaluationview
  },
  {
    path: '/Subjectpaper',//过往试卷
    name: 'Subjectpaper',
    component: Subjectpaperview
  },  
  {
    path: '/Aftermode',//过往试卷
    name: 'Aftermode',
    component: Aftermodeview,
  },
  {
    path: '/Aftindex',//过往试卷
    name: 'Aftindex',
    component: Afteindexiew,
  },
  {
    path: '/evaluationIndexview',
    component: evaluationIndexview
  },
  {
    path: '/evaluationPaperview',
    component: evaluationPaperview
  },
  {
    path: '/MangenmentHome',
    name: 'MangenmentHome',
    component: MangenmentHome,
    redirect: '/MangenmentStudent',
    children: [
      {
        path: '/MangenmentStudent',
        component: MangenmentStudent,
      },
      {
        path: '/MangenmentCourse',
        component: MangenmentCourse,
      },
      {
        path: '/MangenmentChapter',
        component: MangenmentChapter
      },
      {
        path: '/MangenmentVocabularyList',
        component: MangenmentVocabulary
      },
      {
        path: '/MangenmentRefinednote',
        component: MangenmentRefinednote
      },
      {
        path: '/MangenmentTheme',
        component: MangenmentTheme
      },
      // 水平测试
      {
        path: '/MangenmentLevelTest',
        component: MangenmentLevelTest
      },
      //老师管理
      {
        path: '/MangenmentTeacher',
        component: MangenmentTeacher
      },
      // 过往试卷
      {
        path: '/MangenmentPastPapers',
        component: MangenmentPastPapers
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
document.title="GenZ"
  if (to.path == '/MangenmentTeacher') {
    const userInfo = JSON.parse(localStorage.getItem('user'))
    if (userInfo.roleMark == 2) {
      next()
    } else {
      Message.error('权限不足')
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

export default router
