import { render, staticRenderFns } from "./wangeditor.vue?vue&type=template&id=75bc3f7d&scoped=true"
import script from "./wangeditor.vue?vue&type=script&lang=js"
export * from "./wangeditor.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./wangeditor.vue?vue&type=style&index=1&id=75bc3f7d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75bc3f7d",
  null
  
)

export default component.exports