import axios from "axios";
import router from "@/router/index";
import { Message } from "element-ui";
import { BaseURL } from './baseURL.js'

/**
 * @description 实例化一个axios
 * @author 不吃香菜的小飞飞
 * @date 2023/10/7 11:47
 */

const service = axios.create({
  baseURL: BaseURL, // url = base url + request url
  // withCredentials: true, // 跨域请求时发送cookie
  timeout: 25000, // 请求超时的时长
  headers: { "Content-Type": "application/json" }, // 默认设置请求头 为FormData 数据格式  （JSON数据格式：application/json）
  withCredentials: true, //跨域请求时是否需要使用凭证  简单讲：跨域请求是否提供凭据信息(cookie、HTTP认证及客户端SSL证明等)，也可以简单的理解为，当前请求为跨域类型时是否在请求中协带cookie。
});

/**
 * @description 请求拦截器
 * @author 不吃香菜的小飞飞
 * @date 2023/7/11 23:26
 */
service.interceptors.request.use((config) => {
  config.headers.Authorization = 'Bearer ' + localStorage.getItem("token"); // 携带token
  return config;
});

/**
 * @description 响应拦截器
 * @author 不吃香菜的小飞飞
 * @date 2023/7/11 23:26
 */
service.interceptors.response.use(
  (response, a) => {
    const { data } = response;
    // 判断返回的状态码并做出相应的判断
    const status = data.statusCode;
    switch (status) {
      case 200:
        break;
      case 401:
        router.push("/login");
        break;
      default:
    }
    return response;
  },
  (error) => {
    const { message, response } = error
    if (response.status === 401 || response.status === 403) {
      Message.error('登录超时!')
      localStorage.removeItem("token");
      router.push("/login");
    }
    return
  }
);

export default service;