<template>
    <div class="userpage">
        <div class="inquire">
            <el-input placeholder="请输入内容" v-model="inquire" class="inquireInput">
                <template slot="append">
                    <el-button type="primary" icon="el-icon-search" @click="getStudentinfo"></el-button>
                </template>
            </el-input>
            <el-button type="primary" @click="updata('add')">添加学生</el-button>
        </div>
        <div class="tablediv">
            <el-table :data="studentData" border style="width: 100%" max-height="447">
                <el-table-column prop="nickName" label="学生名称" width="180">
                </el-table-column>
                <el-table-column prop="userSex" label="学生性别" width="180">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.userSex == 0 ? 'success' : ''">{{ scope.row.userSex == 0 ? '女' : '男'
                            }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="account" label="账号名称" width="180">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="updata('update', scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" @click="delstudent(scope.row)">删除</el-button>
                        <el-button size="mini" type="danger" @click="resetStudentPassword(scope.row)">重置密码</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
                layout="sizes, prev, pager, next" :total="totalCount">
            </el-pagination>
        </div>
        <studentDialog ref="student" @confirmStudent="confirmStudentInfo" />
    </div>
</template>

<script>
import studentdialog from './components/studentDialog.vue'
import { addStudent, updataStudent, getStudent, delStudent, resetPassword } from './api/index.js'
export default {
    data() {
        return {
            inquire: '',
            studentData: [
            ],
            pageSize: 5,
            totalCount: 0,
            currentPage: 1
        }
    },
    methods: {
        //打开弹框
        updata(dialogType, item = { nickName: '', account: '', userSex: 1 }) {
            this.$refs.student.dialogType = dialogType
            this.$refs.student.dialogFormVisible = true
            this.$refs.student.studentform = { ...item }
        },
        //修改或添加学生信息
        async confirmStudentInfo() {
            // 添加学生信息
            if (this.$refs.student.dialogType == 'add') {
                console.log(this.$refs.student.studentform);
                const res = await addStudent(this.$refs.student.studentform)
                if (res.data.statusCode == '200') {
                    this.$refs.student.dialogFormVisible = false
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.getStudentinfo()
                } else {
                    this.$message({
                        message: res.data.errors,
                        type: 'error'
                    });
                }
            }
            //修改学生信息
            if (this.$refs.student.dialogType == 'update') {
                console.log(this.$refs.student.studentform);
                const res = await updataStudent(this.$refs.student.studentform)
                if (res.data.statusCode == '200') {
                    this.$refs.student.dialogFormVisible = false
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                    this.getStudentinfo()
                } else {
                    this.$message({
                        message: '修改失败',
                        type: 'error'
                    });
                }
            }
        },
        //请求学生信息
        async getStudentinfo() {
            const res = await getStudent({ currentPage: this.currentPage, pageSize: this.pageSize, where: this.inquire })
            if (res.data.statusCode == '200') {
                this.studentData = res.data.data.userInfoPageData
                this.totalCount = res.data.data.pageInfo.totalCount
                this.pageSize = res.data.data.pageInfo.pageSize
                this.currentPage = res.data.data.pageInfo.currentPage
            }
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.getStudentinfo()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getStudentinfo()
        },
        //删除学生信息
        delstudent(item) {
            this.$confirm(`确认删除${item.nickName}的账号吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await delStudent({ id: item.id })
                if (res.data.statusCode == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.currentPage = 0
                    this.getStudentinfo()
                }
            }).catch(() => { });
        },
        //重置密码
        async resetStudentPassword(item) {
            this.$confirm(`确认重置${item.nickName}的密码吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await resetPassword({ id: item.id })
                if (res.data.statusCode == 200 && res.data.data) {
                    this.$message({
                        message: '密码重置成功',
                        type: 'success'
                    });
                }
            }).catch(() => { });
        }
    },
    components: {
        studentDialog: studentdialog
    },
    mounted(item) {
        this.getStudentinfo()
    }
}
</script>

<style lang="less" scoped>
.userpage {
    height: 100%;
}

.inquire {
    display: flex;
    margin-bottom: 40px;

    .inquireInput {
        width: 400px;
        margin-right: 40px;
    }
}
</style>