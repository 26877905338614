import service from "@/utils/request/http";

// 获取精制笔记信息
export function queryKeyLearnBychapterIdInfo(params) {
    return service({
        url: '/refinedNotes/queryKeyLearn',
        method: 'get',
        params
    })
}


// 修改精制笔记信息
export function updateVocabularyInfo(data) {
    return service({
        url: '/refinedNotes/updateKeyLearn',
        method: 'post',
        data
    })
}

//删除精制笔记信息
export function deleteKeyLearnInfo(params) {
    return service({
        url: '/refinedNotes/deleteKeyLearn',
        method: 'get',
        params
    })
}

//添加精制笔记信息
export function addKeyLearnInfo(data) {
    return service({
        url: '/refinedNotes/addKeyLearn',
        method: 'post',
        data
    })
}

//上传图片
export function uploadPictures(data) {
    return service({
        url: '/file/uploadImgFile',
        method: 'post',
        data
    })
}