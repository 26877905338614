<template>
  <div>
    <!--Confidence starts here-->
    <el-row class="Header">
      <el-col :span="24" :sm="24">
        <img src='../../uits/img/blue (1).png' alt="" class="Header_img">
      </el-col>
      <el-col :span="24" :sm="24" class="Header_text1">
        <p>GENZ</p>
      </el-col>
      <el-col :span="24" :sm="24" class="Header_text2">
        <p>{{ $t('homeview.header1') }}</p>
      </el-col>
      <el-col :span="24" :sm="24" class="Header_text3">
        <p>
          <strong>
            <router-link class="Header_text3_router" to="/contact">{{ $t('homeview.header2') }}</router-link>
          </strong>
        </p>
      </el-col>
    </el-row>
    <!--Learn with us-->
    <el-row class="Lrarn">
      <el-row :span="24">
        <p class="Lrarn_text">{{ $t('homeview.Lrarn') }}</p>
      </el-row>
      <el-col :span="24" :sm="8">
        <img src='../../uits/img/Lrarn(2).jpg' alt="" class="Lrarn_img">
        <p class="span">
          <router-link class="Lrarn_text_lint" to="/academic">{{ $t('homeview.Lrarn1') }}</router-link>
        </p>
      </el-col>
      <el-col :span="24" :sm="8">
        <img src='../../uits/img/Lrarn(1).jpg' class="Lrarn_img" alt="">
        <p class="span">
          <router-link class="Lrarn_text_lint" to="/examination">{{ $t('homeview.Lrarn2') }}</router-link>
        </p>
      </el-col>
      <el-col :span="24" :sm="8">
        <img src='../../uits/img/Lrarn.jpg' class="Lrarn_img" alt="">
        <p class="span">
          <router-link class="Lrarn_text_lint" to="/consultancy">{{ $t('homeview.Lrarn3') }}</router-link>
        </p>
      </el-col>
    </el-row>
    <!--What we do-->
    <el-row class="What">
      <el-col :span="24" :sm="12" class="What_lable">
        <p>{{ $t('homeview.What') }}</p>
      </el-col>
      <el-col :span="24" :sm="12" class="What_text">
        <p style="line-height:150%; font-size: 18px;">{{ $t('homeview.What1') }}</p>
        <el-col :sm="24" class="what-introduce_botton">
          <router-link class="What_button" to="/contact">{{ $t('homeview.What2') }}</router-link>

        </el-col>
      </el-col>

    </el-row>
    <!--What we value-->
    <el-row class="value">
      <el-col :span="24" class="value_box">
        <img class="value_img" src='../../uits/img/value.jpeg' alt="">
      </el-col>
      <el-col :span="24" :sm="24" class="value_what">
        <p>{{ $t('homeview.value') }}</p>
      </el-col>
      <el-col :span="24" :sm="8">
        <h3 class="value_h3">{{ $t('homeview.value1') }}</h3>
        <p class="value_p">
          {{ $t('homeview.value2') }}</p>
      </el-col>
      <el-col :span="24" :sm="8">
        <h3 class="value_h3">{{ $t('homeview.value3') }}</h3>
        <p class="value_p">
          {{ $t('homeview.value4') }}
        </p>
      </el-col>
      <el-col :span="24" :sm="8">
        <h3 class="value_h3">{{ $t('homeview.value5') }}</h3>
        <p class="value_p">
          {{ $t('homeview.value6') }}
        </p>
      </el-col>
    </el-row>
    <!--轮播-->
    <div style="display: flex; justify-content:center;margin-bottom:30px;">
      <el-carousel style="width: 90%;" :height="carouselheight" class="carousel" id='carousel'>
        <el-carousel-item v-for="(item, index) in imglist" :key="index">
          <div style="width: 100%; display:flex; justify-content:center;">
            <img :src="item.url" alt="" style="width: 80%;">
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!--Tabbar-->
    <el-row class="Tabbar">
      <el-col :span="24" :sm="24">
        <img src='../../uits/img/blue.png' alt="" class="Tabbar_img">
      </el-col>
      <el-col :span="24" :sm="24" class="Tabbar_text1">
        <p> {{ $t('homeview.Tabbar') }}</p>
      </el-col>
      <el-col :span="24" :sm="24" class="Tabbar_text2">
        <p>{{ $t('homeview.Tabbar1') }}</p>
      </el-col>
      <el-col :span="24" :sm="24" class="Tabbar_text3">
        <p>
          <strong>
            <router-link class="Header_text3_router" to="/contact">{{ $t('homeview.Tabbar2') }}</router-link>
          </strong>
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import sm2Util from "@/utils/sm2Util";

export default {
  name: 'HomeView',
  data() {
    return {
      isshow: false,
      languageType: 'English',
      imglist: [
        { url: require('../../uits/img/img1.png') },
        { url: require('../../uits/img/img2.png') },
        { url: require('../../uits/img/img3.png') },
        { url: require('../../uits/img/img4.png') },
        { url: require('../../uits/img/img5.png') }
      ],
      carouselheight: '600px'

    }
  },
  methods: {
  },
  components: {
  },
  mounted() {
    console.log(sm2Util.sm2Decrypt("44063a769903ef8845bd5a9b03cdf680c99c961f94d3ac33a32a77dfbd91302a083076335254c2e20cbb3615b3a5cb881c34ee8f2f386e6401cab79bf02e928c2dd0f6553763078de3d5127b22343ee542fc424e45a9c0a1c97d94346d813c22b757ca95b6d123517809c624b1acce0fa6fa00635f7b1bb929fd36a794fffa945a3ff85763c4a97ab4430a91cb719859cbd67859811fe63805548d3080c991e0d0c530246c6ca2cabe39a591703bbda583f6e99c9d813c6033555f7bb8d0c77419a6f2b927"));
    try {
      this.carouselheight = (document.getElementsByClassName('carousel')[0].clientWidth / 2.5) + 'px'
      window.onresize = () => {
        return (() => {
          if (document.getElementById('carousel')) {
            this.carouselheight = (document.getElementById('carousel').clientWidth / 2.5) + 'px'
          }
        })()
      }
    } catch (error) {

    }
  }
}
</script>
<style scoped>
.Header {
  width: 100%;
  background: linear-gradient(180deg, rgb(0, 0, 0) 45%, rgb(83, 80, 123) 39%)
}

.Header_img {
  width: 100%;
  opacity: 0.5;
  height: 860px;
  min-width: 360px;
}

.Header_text1 {
  width: 90%;
  margin: 0 10%;
  text-align: left;
  position: absolute;
  top: 100px;
  font-size: 160px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}

.Header_text2 {
  width: 90%;
  margin: 0 10%;
  text-align: left;
  position: absolute;
  top: 360px;
  font-size: clamp(2.111rem, 2.111rem + ((1vw - 0.2rem) * 3.217), 4.5rem);
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.Header_text3 {
  width: 30%;
  margin: 0 10%;
  text-align: center;
  background-color: rgb(225, 105, 0);
  position: absolute;
  top: 550px;
  font-size: 20px;
  color: #fff;
}

.Header_text3_router {
  text-decoration: none;
  border-bottom: 3px solid #fff;
  color: #fff;
}

.Header_text3_router:hover {
  border: 0px;
}

.Lrarn {
  background-color: rgb(242, 247, 248);
  width: 100%;
}

.Lrarn_text {
  color: black;
  font-size: 35px;
  text-align: left;
  margin: 50px;
}

.span {
  text-align: center;
  position: relative;
  bottom: 100px;
}

.Lrarn_text_lint {
  color: #fff;
  font-size: 15px;
  position: relative;
  border-bottom: 1px solid #fff;
  text-decoration: none;
}

.Lrarn_text_lint:hover {
  border: 0px;
}

.Lrarn_img {
  width: 80%;
  height: 430px;
  min-width: 360px;
  padding: 50px;
}

.What {
  height: 100%;
  margin: 100px 20px;
}

.What_lable>p {
  font-size: 35px;
  text-align: left;
  color: black;
}

.What_text {
  flex: 5;
  text-align: left;
  font-size: 20px;
}

.What_button {
  background-color: rgb(0, 32, 96);
  color: #fff;
  border-radius: 10px;
  height: 50px;
  margin: 2px 0;
  text-decoration: none;
}

.what-introduce_botton {
  text-align: center;
  margin-top: 20px;
  padding-top: 15px;
  line-height: 30px;
  font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.476), 18px);
  width: 40%;
  min-width: 30%;
  height: 60px;
  background-color: #002060;
  color: #fff;
  border-radius: 10px;
}

.value {
  background-color: rgb(242, 247, 248);

}

.value_box {

  overflow: hidden;
  width: 100%;
  margin: 5% auto;
}

.value_img {
  height: 100%;
  min-width: 850px;
  margin: -80px 50%;
  width: 100%;
  transform: translateX(-50%)
}

.value_what {
  width: 60%;
  margin: 26% 29%;
  text-align: left;
  position: absolute;
  font-size: 50px;
  font-weight: 700;
  color: rgb(255, 255, 255);
}

.value_box_p {
  font-size: 65px;
  color: #fff;
  text-align: center;

}

.value_h3 {
  color: black;
  font-size: 20px;
  text-align: left;
  margin: 0 20px;
}

.value_p {
  text-align: left;
  font-size: 18px;
  margin: 20px;
  line-height: 150%;
  padding: 50px 0;
}

.Tabbar {
  width: 100%;
}

.Tabbar_img {
  width: 100%;
  height: 500px;
  min-width: 360px;
}

.Tabbar_text1 {
  width: 80%;
  margin: 0 10%;
  text-align: center;
  position: absolute;
  font-size: clamp(2.111rem, 2.111rem + ((1vw - 0.2rem) * 3.217), 4.5rem);
  color: #fff;
}

.Tabbar_text2 {
  width: 80%;
  margin: 0 10%;
  text-align: center;
  position: absolute;
  top: 180px;
  font-size: clamp(2.111rem, 2.111rem + ((1vw - 0.2rem) * 3.217), 3.0rem);
  color: #fff;
}

.Tabbar_text3 {
  width: 90%;
  margin: 0 5%;
  text-align: left;
  position: absolute;
  top: 330px;
  font-size: clamp(2.111rem, 2.111rem + ((1vw - 0.2rem) * 3.217), 2.0rem);
  text-align: center;
  color: #fff;
  background-color: rgb(225, 105, 0);
}

::v-deep .el-carousel__button {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}

::v-deep .is-active .el-carousel__button {
  background: #3f8ec8;
}
</style>
