import service from "@/utils/request/http";

// 获取过往试卷题目
export function queryBackgroundPastTestPaperInfo(params) {
    return service({
        url: '/previousExamPapers/queryBackgroundPastTestPaper',
        method: 'get',
        params
    })
}


// 修改过往试卷题目
export function updatePastTestPaperInfo(data) {
    return service({
        url: '/previousExamPapers/updatePastTestPaper',
        method: 'post',
        data
    })
}

//删除过往试卷题目
export function deletePastTestPaperInfo(params) {
    return service({
        url: '/previousExamPapers/deletePastTestPaper',
        method: 'get',
        params
    })
}

//添加过往试卷题目
export function addPastTestPaperInfo(data) {
    return service({
        url: '/previousExamPapers/addPastTestPaper',
        method: 'post',
        data
    })
}

//设置混合模式总题数
export function setMixModeCountInfo(params) {
    return service({
        url: '/previousExamPapers/setMixModeCount',
        method: 'get',
        params
    })
}

//查询混合模式总题数
export function getMixModeCountInfo(params) {
    return service({
        url: '/previousExamPapers/getMixModeCount',
        method: 'get',
        params
    })
}







