<template>
    <div class="page">
        <el-row>
            <el-col :span="24" :sm="12">
                <div class="page-left">
                    <div class="page-left-title">{{ $t('resources.title') }}</div>
                    <div class="page-introduce">
                        <span>
                            {{ $t('resources.introduce1') }}
                        </span>
                    </div>
                    <div class="page-introduce_botton" >
                        <a >
                            {{ $t('resources.introduce2') }}
                        </a>
                    </div>
                    <div class="page-introduce_botton">
                        <a>
                            {{ $t('resources.introduce3') }}
                        </a>
                    </div>
                    <div class="page-introduce_botton">
                        <a>
                            {{ $t('resources.introduce4') }}
                        </a>
                    </div>
                </div>
            </el-col>
            <el-col :span="24" :sm="{ span: 10, offset: 2 }">
                <div class="page-right">
                    <div>{{ $t('Search') }}</div>
                    <div style="display: flex;">
                        <input type="text"
                            style="height: 54px;background-color: rgb(204,232,207);border: 0.8px solid rgb(156 156 156);margin-right: 10px;flex:1">
                        <button style="height: 58px;background-color: #002060;width:100px; border:0px; color:#fff">{{
                $t('Search') }}</button>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.page {
    margin: 0 10%;
    margin-top: 80px;
}

.page-left {
    margin-bottom: 60px;
}

.page-left-title {
    font-size: clamp(2.111rem, 2.111rem + ((1vw - 0.2rem) * 3.217), 3.8rem);
    font-weight: 500;
}

.page-introduce {
    margin-top: 20px;
    line-height: 30px;
    font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.476), 18px);

}
.page-introduce_botton{
    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    line-height: 30px;
    font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.476), 18px);
    width: 100%;
    height: 50px;
    background-color: #002060; color:#fff
}
.subjectlist {
    margin-top: 30px;
    font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.476), 18px);
    line-height: 1.6;
}

.page-right {
    width: 100%;
}

.morediv {
    margin-top: 20px;
    width: 225px;
    cursor: pointer;
}

.span1 {
    border-bottom: 1px solid black;
}

.span1:hover {
    border: 0px;
}
</style>