<template>
    <div class="coursepage">
        <Back class="backdiv" />
        <el-scrollbar style="height:100%">
            <div class="content">
                <div class="coursediv" @click="openSubject('add')">
                    <span slot="prefix" class="iconfont icon-tianjiatubiao"
                        style="color: rgb(189 189 189);font-size: 60px;text-align: center;line-height: 350px;"></span>
                </div>
                <div class="coursediv" v-for="(item, index) in courselist" :key="index">
                    <div class="chapterName">
                        <span style="padding: 10px 0;">{{ item.chapterName }}</span>
                    </div>
                    <div class="chapterImgUrl">
                        <img :src="'https://genztutoringnlabs.com/service/' + item.chapterImgUrl" alt=""
                            style="max-height: 230px; max-width: 300px;">
                    </div>
                    <div style="border-top: 1px solid #eee; display: flex; justify-content: center; padding: 10px 0;">
                        <el-tooltip class="item" effect="dark" content="修改章节" placement="top">
                            <el-button size="small" icon="el-icon-edit"
                                @click="openSubject('updata', { chapterId: item.chapterId, chapterName: item.chapterName, chapterImgUrl: item.chapterImgUrl })">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除章节" placement="top">
                            <el-button icon="el-icon-delete" size="small" @click="delCourse(item)"></el-button>
                        </el-tooltip>
                        <el-dropdown @command="(command) => handleCommand(command, item)">
                            <span class="el-dropdown-link" style="margin-left: 10px;">
                                <el-button icon="el-icon-s-promotion" size="small"></el-button>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="/MangenmentVocabularyList">词汇列表</el-dropdown-item>
                                <el-dropdown-item command="/MangenmentTheme">主题管理</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <courseDialog ref="addcourseDialog" @confirmcourse="addCourse" />
    </div>
</template>

<script>
import { getQueryRecentChapterinfo, updateChapterInfo, deleteChapterInfo, addChapterInfo } from './api/index.js'
import courseDialog from './components/courseDialog.vue'
import Back from '../components/index.vue'
export default {
    data() {
        return {
            courselist: [],
            subjectId: ''
        }
    },
    components: {
        courseDialog,
        Back
    },
    methods: {
        // 获取章节信息
        async getCourse() {
            const res = await getQueryRecentChapterinfo({ subjectId: this.subjectId, currentPage: 1, pageSize: 1000 })
            this.courselist = []
            if (res.data.statusCode == 200 && res.data.data.chapterInfoPageData.length > 0) {
                res.data.data?.chapterInfoPageData.forEach(item => {
                    this.courselist.push(item)
                });
            }
        },
        openSubject(type, item = { chapterId: "", chapterName: '', chapterImgUrl: '' }) {
            this.$refs.addcourseDialog.dialogType = type
            this.$refs.addcourseDialog.courseform.chapterId = item.chapterId
            this.$refs.addcourseDialog.courseform.chapterName = item.chapterName
            this.$refs.addcourseDialog.dialogFormVisible = true
            this.$refs.addcourseDialog.subjectId = this.$route.query.id
            if (type == 'updata') {
                this.$refs.addcourseDialog.oldImageUrl = item.chapterImgUrl
            }
        },
        // 提交提交信息
        async addCourse() {
            var courseInfo = {
                chapterName: this.$refs.addcourseDialog.courseform.chapterName,
                chapterImgPath: this.$refs.addcourseDialog.courseform.chapterImgPath,
                chapterId: this.$refs.addcourseDialog.chapterId
            }
            var successMessage = '添加'
            var res = {}
            if (this.$refs.addcourseDialog.dialogType == 'updata') {
                courseInfo.chapterId = this.$refs.addcourseDialog.courseform.chapterId
                courseInfo.chapterImgPath == '' && (courseInfo.chapterImgPath = this.$refs.addcourseDialog.oldImageUrl)
                successMessage = '修改'
                res = await updateChapterInfo(courseInfo)
            } else {
                courseInfo.subjectId = this.$refs.addcourseDialog.subjectId
                res = await addChapterInfo(courseInfo)
            }
            if (res.data.statusCode == 200 && res.data.data) {
                this.$refs.addcourseDialog.dialogFormVisible = false
                this.$refs.addcourseDialog.courseform = {}
                this.$message.success(successMessage + '成功！')
                this.getCourse()
            } else {
                this.$message.error(successMessage + '失败，' + res.data.errors + '!')
            }
        },
        // 删除章节
        async delCourse(item) {
            console.log(item.chapterName);
            this.$confirm(`确认删除${item.chapterName}吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await deleteChapterInfo({ chapterId: item.chapterId })
                if (res.data.statusCode == 200 && res.data.data) {
                    this.$message.success('删除成功!')
                    this.getCourse()
                }
            }).catch(() => { });
        },
        handleCommand(event, item) {
            this.$router.push({
                path: event,
                query: {
                    chapterId: item.chapterId
                }
            })
        }
    },
    mounted() {
        this.subjectId = this.$route.query.id
        this.getCourse()
    }
}
</script>

<style lang="less" scoped>
.coursepage {
    .content {
        height: 80.5vh;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
    }

    .backdiv {
        margin: 20px 0;
    }

    .coursediv {
        width: 350px;
        height: 350px;
        border: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 20px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .chapterName {
            border-bottom: 1px solid #eee;
            display: flex;
            justify-content: center;
        }

        .chapterImgUrl {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .chapterImgUrl:hover {
            cursor: pointer;
        }


    }

    .coursediv:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}
</style>