<template>
  <div>
    <Back class="backdiv" />
    <div style="margin-bottom: 20px; font-size: 25px;">过往试卷题目管理</div>
    <div class="inquire">
      <el-input placeholder="请输入内容" v-model="inquire" class="inquireInput">
        <template slot="append">
          <el-button type="primary" icon="el-icon-search" @click="queryBackgroundPastTestPaper"></el-button>
        </template>
      </el-input>
      <el-button type="primary" @click="openDialog('add')">添加题目</el-button>
      <el-button type="primary" @click="opensetTitleNum">设置混合模式总题目</el-button>
    </div>
    <el-table :data="titleList" border style="width: 100%">
      <el-table-column prop="title" label="题目名称" width="180">
      </el-table-column>
      <el-table-column prop="titleType" label="题目类型" align="center">
        <template slot-scope="scope">
          <el-tag>{{ TypeName[scope.row.titleType] }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="题目内容" width="180" align="center">
        <template slot-scope="scope">
          <el-button @click="viewContent(scope.row)">查看题目</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="titleOrder" sortable label="题目排序" align="center">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" @click="openDialog('updata', scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteInfo(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
      :page-sizes="[5, 10, 15, 20]" :page-size="pageSize" layout="sizes, prev, pager, next" :total="totalCount"
      style="margin-top: 20px;">
    </el-pagination>
    <wangeditor ref="wangeditor" @submission="pushTitleInfo" />
    <topicDialog ref="topicDialog" />
    <setTitleNum ref="setTitleNum" @pushInfo="setMixModeCount" />
  </div>
</template>

<script>
import wangeditor from '../components/wangeditor.vue'
import Back from '../components/index.vue'
import topicDialog from './components/topicDialog.vue'
import setTitleNum from './components/setTitleNum.vue'
import { queryBackgroundPastTestPaperInfo, addPastTestPaperInfo, deletePastTestPaperInfo, updatePastTestPaperInfo, setMixModeCountInfo, getMixModeCountInfo } from './api/index.js'
export default {
  data() {
    return {
      inquire: '',
      keyLearnDirectoryId: '',
      chapterId: '',
      titleList: [],
      LearnDirectoryInfo: [],
      pageSize: 5,
      totalCount: 0,
      currentPage: 1,
      TypeName: ['单选题', '多选题', '填空题', '问答题']
    }
  },
  components: {
    Back,
    wangeditor,
    topicDialog,
    setTitleNum
  },
  methods: {
    openDialog(wangeditorDialogType, item = { titleType: 0, titleContentJson: ``, titleAnswerJson: [{ content: '' }], title: '', titleOrder: '', keyLearnDirectoryId: '', pastTestPaperId: '', titleOrder: 0 }) {
      this.$refs.wangeditor.wangeditorDialogType = wangeditorDialogType
      this.$refs.wangeditor.noteType = 1
      this.$refs.wangeditor.befrom = 'pastPapers'
      this.$refs.wangeditor.themeList = this.LearnDirectoryInfo
      this.$refs.wangeditor.wangeditorForm.titleType = item.titleType
      this.$refs.wangeditor.wangeditorForm.titleContent = item.titleContentJson
      this.$refs.wangeditor.wangeditorForm.titleAnswer = wangeditorDialogType == 'add' ? item.titleAnswerJson : JSON.parse(item.titleAnswerJson)
      this.$refs.wangeditor.wangeditorForm.title = item.title
      this.$refs.wangeditor.wangeditorForm.keyLearnDirectoryId = item.keyLearnDirectoryId
      this.$refs.wangeditor.wangeditorForm.testPaperId = item.pastTestPaperId
      this.$refs.wangeditor.wangeditorForm.titleOrder = item.titleOrder
      this.$refs.wangeditor.innerVisible = true
    },
    async queryBackgroundPastTestPaper() {
      const res = await queryBackgroundPastTestPaperInfo({ keyLearnDirectoryId: this.keyLearnDirectoryId, currentPage: this.currentPage, pageSize: this.pageSize, titleName: this.inquire })
      if (res.data.statusCode == 200) {
        this.titleList = res.data.data.pastTestPaperData
        this.totalCount = res.data.data.pageInfo.totalCount
      }
    },
    viewContent(item) {
      this.$refs.topicDialog.htmlList = item
      this.$refs.topicDialog.dialogFormVisible = true
    },
    async pushTitleInfo() {
      console.log(this.$refs.wangeditor.wangeditorForm.titleContent);
      if (this.$refs.wangeditor.wangeditorForm.title == '' || this.$refs.wangeditor.wangeditorForm.titleOrder == '') {
        this.$message.info('请填写完你的题目信息！')
        return
      }
      var titleInfo = {
        title: this.$refs.wangeditor.wangeditorForm.title,
        titleType: this.$refs.wangeditor.wangeditorForm.titleType,
        titleContentJson: this.$refs.wangeditor.wangeditorForm.titleContent,
        titleAnswerJson: JSON.stringify(this.$refs.wangeditor.wangeditorForm.titleAnswer),
        titleOrder: this.$refs.wangeditor.wangeditorForm.titleOrder
      }
      var res = undefined
      var messageTitle = '添加'

      if (this.$refs.wangeditor.wangeditorDialogType == 'add') {
        titleInfo.chapterId = this.chapterId
        titleInfo.keyLearnDirectoryId = this.keyLearnDirectoryId
        res = await addPastTestPaperInfo(titleInfo)
      }

      if (this.$refs.wangeditor.wangeditorDialogType == 'updata') {
        titleInfo.pastTestPaperId = this.$refs.wangeditor.wangeditorForm.testPaperId
        res = await updatePastTestPaperInfo(titleInfo)
        messageTitle = '修改'
      }

      if (res.data.statusCode == 200 && res.data.data) {
        this.$refs.wangeditor.innerVisible = false
        this.$message.success(messageTitle + '成功！')
        this.queryBackgroundPastTestPaper()
      } else {
        this.$message.error(messageTitle + '失败！')
      }
    },
    deleteInfo(item) {
      this.$confirm(`确认删除${item.title}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deletePastTestPaperInfo({ pastTestPaperId: item.pastTestPaperId })
        if (res.data.statusCode == 200 && res.data.data) {
          this.$message.success('删除成功！')
          this.queryBackgroundPastTestPaper()
        } else {
          this.$message.error('删除失败！')
        }
      }).catch(() => { });
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.queryBackgroundPastTestPaper()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.queryBackgroundPastTestPaper()
    },
    async setMixModeCount() {
      const res = await setMixModeCountInfo({ count: this.$refs.setTitleNum.form.num, chapterId: this.chapterId })
      if (res.data.statusCode == 200 && res.data.data) {
        this.$message.success('设置成功！')
      } else {
        this.$message.error(res.data.errors)
      }
      this.$refs.setTitleNum.dialogFormVisible = false
      this.getMixModeCount()
    },
    opensetTitleNum() {
      this.$refs.setTitleNum.dialogFormVisible = true
    },
    async getMixModeCount() {
      const res = await getMixModeCountInfo({ chapterId: this.chapterId })
      if (res.data.statusCode == 200) {
        this.$refs.setTitleNum.form.num = res.data.data
      }
    }
  },
  mounted() {
    this.chapterId = this.$route.query.chapterId;
    this.keyLearnDirectoryId = this.$route.query.keyLearnDirectoryId;
    this.queryBackgroundPastTestPaper()
    this.getMixModeCount()
  }
}
</script>

<style lang="less" scoped>
.backdiv {
  margin: 20px 0;
}

.inquire {
  display: flex;
  margin-bottom: 40px;

  .inquireInput {
    width: 400px;
    margin-right: 40px;
  }
}
</style>