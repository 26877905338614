import serve from '@/utils/request/http.js'

export function studentside(params){//课程
    return serve({
        url:'/subject/queryPagingSubject?'+ params,
        method:'get',
    })
    
}
export function queryRecentChapter(params){//历史记录
    return serve({
        url:'/chapter/queryRecentChapter?'+ params,
        method:'get',
    })
    
}

export function updateStudentPassword(data){//修改密码
    return serve({
        url:'/userManage/updateStudentPassword',
        method:'post',
        data
    })
    
}
