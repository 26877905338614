import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 导入axios
import axios from "axios";
Vue.prototype.$axios = axios;
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入VueI18n
import VueI18n from 'vue-i18n'
//引入中英文js文件
// import Chinese from './utils/internationalization/zh.js'
import English from '@/utils/internationalization/en.js'
import TraditionalChinese from '@/utils/internationalization/zh-TW.js'
import './assets/font_4491656_zzwmky0jo5/iconfont.css'


Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',  	//默认zh 语言
  messages: {
    en: English,
    // zh: Chinese,
    Tw: TraditionalChinese
  }
})
Vue.prototype.$http = axios;
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
  router,
  store,
  i18n,
  ElementUI,
  render: h => h(App)
}).$mount('#app')
