import service from "@/utils/request/http";

// 获取主题信息
export function getKeyLearnDirectoryInfo(params) {
    return service({
        url: '/refinedNotes/queryKeyLearnDirectory',
        method: 'get',
        params
    })
}

// 添加主题信息
export function addKeyLearnDirectoryInfo(data) {
    return service({
        url: '/refinedNotes/addKeyLearnDirectory',
        method: 'post',
        data
    })
}

// 修改主题信息
export function updataKeyLearnDirectoryInfo(data) {
    return service({
        url: '/refinedNotes/updateKeyLearnDirectory',
        method: 'post',
        data
    })
}

// 删除主题信息
export function delKeyLearnDirectoryInfo(params) {
    return service({
        url: '/refinedNotes/deleteKeyLearnDirectory',
        method: 'get',
        params
    })
}

// 模糊查询
export function queryLikeKeyLearnDirectory(params) {
    return service({
        url: '/refinedNotes/queryLikeKeyLearnDirectory',
        method: 'get',
        params
    })
}

// 查看水平测试成绩
export function getTestTeportForAllStudentInfo(params) {
    return service({
        url: '/proficiencyTest/getTestTeportForAllStudent',
        method: 'get',
        params
    })
}