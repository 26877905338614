<template>
  <el-dialog title="题目详情" :visible.sync="dialogFormVisible" width="30%" @open="openDialog">
      <div class="titleclass">{{ htmlList.title }}</div>
      <div v-html="htmldata" ref="showdiv"></div>
  </el-dialog>
</template>

<script>
export default {
  data() {
      return {
          dialogFormVisible: false,
          refinedNoteData: {},
          htmldata: ``,
          indexNum: 0,
          htmlList: []
      }
  },
  watch: {
      dialogFormVisible(newValue, oldValue) {
      }
  },
  methods: {
      setRadio(questionStr) {
          // 存放数组
          var replacements = [];

          const xuan = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

          var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
          // 声明默认字体大小
          var fontSize = 29

          // 检查是否匹配成功并输出结果
          if (fontSizeMatch) {
              fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + 'px';
          }
          const matches = questionStr.match(/\$radio/g);
          const count = matches ? matches.length : 0;
          for (let index = 0; index <= count; index++) {
              replacements.push(`<input type="radio" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`)
          }

          // 当前替换索引
          let currentIndex = 0;

          // 使用replace方法和正则表达式来逐个替换$checkbox
          const replacedStr = questionStr.replace(/\$radio/g, (match) => {
              // 获取当前匹配项的替换值
              const replacement = replacements[currentIndex];
              // 更新索引以便下次替换
              currentIndex++;
              // 返回替换值
              return replacement;
          });
          return replacedStr
      },
      // 将$checkbox变为多选框
      setCheckbox(questionStr) {
          // 替换顺序数组
          const replacements = [];

          const xuan = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

          // 当前替换索引
          let currentIndex = 0;

          var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
          // 声明默认字体大小
          var fontSize = 29

          // 检查是否匹配成功并输出结果
          if (fontSizeMatch) {
              fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + 'px';
          }


          const matches = questionStr.match(/\$checkbox/g);
          const count = matches ? matches.length : 0;
          for (let index = 0; index <= count; index++) {
              replacements.push(`<input type="checkbox" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`)
          }

          // 使用replace方法和正则表达式来逐个替换$checkbox
          const replacedStr = questionStr.replace(/\$checkbox/g, (match) => {
              // 获取当前匹配项的替换值
              const replacement = replacements[currentIndex];
              // 更新索引以便下次替换
              currentIndex++;
              // 返回替换值
              return replacement;
          });
          return replacedStr
      },
      // 将$Input变为填空框
      setInput(questionStr) {
          // 替换顺序数组
          const replacements = [];

          // 当前替换索引
          let currentIndex = 0;

          var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
          // 声明默认字体大小
          var fontSize = 29

          // 检查是否匹配成功并输出结果
          if (fontSizeMatch) {
              fontSize = fontSizeMatch[1];
          }

          const matches = questionStr.match(/\$Input/g);

          const count = matches ? matches.length : 0;
          for (let index = 0; index <= count; index++) {
              replacements.push(`<input type="text" id="qwe${index}" class="qwe" style="text-align: center; border: 0px solid #fff; border-bottom: 1px solid #ccc;height:${fontSize}; width:${fontSize * 4}; font-size:${fontSize}; margin: 0 15px;"/>`)
          }

          // 使用replace方法和正则表达式来逐个替换$checkbox
          const replacedStr = questionStr.replace(/\$Input/g, (match) => {
              // 获取当前匹配项的替换值
              const replacement = replacements[currentIndex];
              // 更新索引以便下次替换
              currentIndex++;
              // 返回替换值
              return replacement;
          });
          return replacedStr
      },
      // 将$Textarea变为问答框
      setTextarea(questionStr) {
          // 替换顺序数组
          const replacements = [];

          // 当前替换索引
          let currentIndex = 0;

          var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
          // 声明默认字体大小
          var fontSize = 29

          // 检查是否匹配成功并输出结果
          if (fontSizeMatch) {
              fontSize = fontSizeMatch[1];
          }

          const matches = questionStr.match(/\$Textarea/g);

          const count = matches ? matches.length : 0;
          for (let index = 0; index <= count; index++) {
              replacements.push(`<textarea cols="3" id="qwe${index}" class="qwe" rows="3" style="max-width:600px; min-width:300px; font-size:${fontSize}"></textarea>`)
          }

          // 使用replace方法和正则表达式来逐个替换$checkbox
          const replacedStr = questionStr.replace(/\$Textarea/g, (match) => {
              // 获取当前匹配项的替换值
              const replacement = replacements[currentIndex];
              // 更新索引以便下次替换
              currentIndex++;
              // 返回替换值
              return replacement;
          });
          return replacedStr
      },
      // 修改题目
      openDialog() {
          switch (this.htmlList.titleType) {
              //单选题
              case 0:
                  this.htmldata = this.setRadio(this.htmlList.titleContentJson)
                  this.$nextTick(this.getRadioAnswer);
                  break
              //多选题
              case 1:
                  this.htmldata = this.setCheckbox(this.htmlList.titleContentJson)
                  this.$nextTick(this.getCheckboxAnswer);
                  break
              //填空题
              case 2:
                  this.htmldata = this.setInput(this.htmlList.titleContentJson)
                  this.$nextTick(this.getInputAnswer);
                  break
              //问答题
              case 3:
                  this.htmldata = this.setTextarea(this.htmlList.titleContentJson)
                  this.$nextTick(this.getTextareaAnswer);
                  break
              default:
                  this.$message.error('该题型未得到应用')
          }

          // if (this.htmlList[this.indexNum].titleType == '0') {
          //     this.htmldata = this.setRadio(this.htmlList[this.indexNum].titleContent)
          //     this.$nextTick(this.getRadioAnswer);
          // } else if (this.htmlList[this.indexNum].titleType == '1') {
          //     this.htmldata = this.setCheckbox(this.htmlList[this.indexNum].titleContent)
          //     this.$nextTick(this.getCheckboxAnswer);
          // } else if (this.htmlList[this.indexNum].titleType == '2') {
          //     this.htmldata = this.setInput(this.htmlList[this.indexNum].titleContent)
          //     this.$nextTick(this.getInputAnswer);
          // } else if (this.htmlList[this.indexNum].titleType == '3') {
          //     this.htmldata = this.setTextarea(this.htmlList[this.indexNum].titleContent)
          //     this.$nextTick(this.getTextareaAnswer);
          // }

      },
      // 为单选框绑定事件
      getRadioAnswer() {
          var radios = this.$refs.showdiv.querySelectorAll('.qwe')
          radios.forEach(radio => {
              radio.addEventListener('change', () => {
                  var asdlist = []
                  radios.forEach(item => {
                      item.checked && (asdlist.push(item.value))
                  })
              })
          })
      },
      // 为复选框绑定事件
      getCheckboxAnswer() {
          var radios = this.$refs.showdiv.querySelectorAll('.qwe')
          radios.forEach(radio => {
              radio.addEventListener('change', () => {
                  var asdlist = []
                  radios.forEach(item => {
                      item.checked && (asdlist.push(item.value))
                  })
              })
          })
      },
      // 为填空框绑定事件
      getInputAnswer() {
          var inputs = this.$refs.showdiv.querySelectorAll('.qwe')
          inputs.forEach(inputItem => {
              inputItem.addEventListener('change', () => {
                  var asdlist = []
                  inputs.forEach(item => {
                      asdlist.push(item.value)
                  })
              })
          })
      },
      // 为问答框绑定事件
      getTextareaAnswer() {
          var inputs = this.$refs.showdiv.querySelectorAll('.qwe')
          inputs.forEach(inputItem => {
              inputItem.addEventListener('change', () => {
                  var asdlist = []
                  inputs.forEach(item => {
                      asdlist.push(item.value)
                  })
              })
          })
      }
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
.titleclass{
  margin-bottom: 30px;
  font-size: 20px;
}
</style>