<template>
    <div>
        <el-dialog title="分配权限" :visible.sync="dialogFormVisible" width="700px" :before-close="handleClose">
            <el-transfer filterable :filter-method="filterMethod" filter-placeholder="请输入城市拼音" v-model="Permissions"
                :data="alldata">
            </el-transfer>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm">{{ "确认分配" }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogFormVisible: false,
            alldata: [],
            Permissions: [],
            filterMethod(query, item) {
                return item.pinyin.indexOf(query) > -1;
            },
            subjectId:0
        }
    },
    methods: {
        handleClose() {
            this.dialogFormVisible = false
        },
        confirm() {
            this.$emit('assignAuthority')
        }
    }
}
</script>

<style></style>