<template>
  <div style="height: 400px;">
    <el-scrollbar style="height:100%;">
      <el-collapse style=" height: 400px" v-model="activeNames" @change="handleChange">
        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 20px;">
              <span>{{ $t('education.answer') }}</span><br/>
              <span>{{ $t('education.answer_1') }}</span><br/>
              <span>{{ $t('education.answer_2') }}</span><br/>
              <span>{{ $t('education.answer_3') }}</span>
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem1') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 20px;">
              <span>{{ $t('education.answer1') }}</span><br/>
              <span>{{ $t('education.answer1_1') }}</span><br/>
              <span>{{ $t('education.answer1_2') }}</span>
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem2') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 20px;">
              <span>{{ $t('education.answer2') }}</span>
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem3') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 20px;">
              <span>{{ $t('education.answer3') }}</span><br/>
              <span>{{ $t('education.answer3_1') }}</span>
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem4') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 20px;">
              <span>{{ $t('education.answer4') }}</span>
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem5') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 20px;">
              <span>{{ $t('education.answer5') }}</span><br/>
              <span>{{ $t('education.answer5_1') }}</span>
            </span>
          </div>
        </el-collapse-item>

        <el-collapse-item>
          <template slot="title">
            <img src="../../utils/img/tubiao(1).png" alt="" style=" width: 20px; height: 20px;padding:12px 0;">
            <span style="margin-left: 20px;">{{ $t('questions.problem6') }}</span>
          </template>
          <div>
            <img src="../../utils/img/tubiao(2).png" alt="" style=" width: 20px; height: 20px;">
            <span style="margin-left: 20px;">
              <span>{{ $t('education.answer16') }}</span>
            </span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-scrollbar>
  </div>
</template>

<script>
export default {

}
</script>


</style>