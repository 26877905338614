import service from "@/utils/request/http";

// 获取水平测试题目
export function queryFullTestPaperLikeTitleNameInfo(params) {
    return service({
        url: '/proficiencyTest/queryFullTestPaperLikeTitleName',
        method: 'get',
        params
    })
}


// 修改水平测试题目
export function updateTestPaperInfo(data) {
    return service({
        url: '/proficiencyTest/updateTestPaper',
        method: 'post',
        data
    })
}

//删除水平测试题目
export function deleteSingleTestPaperInfo(params) {
    return service({
        url: '/proficiencyTest/deleteSingleTestPaper',
        method: 'get',
        params
    })
}

//添加水平测试题目
export function addTestPaperInfo(data) {
    return service({
        url: '/proficiencyTest/addTestPaper',
        method: 'post',
        data
    })
}


// 查询精致笔记子目录
export function queryKeyLearnChildrenDirectoryInfo(params) {
    return service({
        url: '/refinedNotes/queryKeyLearnChildrenDirectory',
        method: 'get',
        params
    })
}

// 修改是否显示
export function updateTestPaperTitleStatusInfo(params) {
    return service({
        url: '/proficiencyTest/updateTestPaperTitleStatus',
        method: 'get',
        params
    })
}

// 模糊查询



