<template>
    <el-dialog width="80%" :title="wangeditorDialogType == 'add' ? '添加信息' : '修改信息'" :visible.sync="innerVisible"
        append-to-body :before-close="handleClose">
        <el-form :model="wangeditorForm">
            <el-form-item label="题目名称" v-if="befrom == 'levelTest' || befrom == 'pastPapers'" label-width="70px">
                <el-input v-model="wangeditorForm.title" placeholder="请输入题目名称" style="width: 200px;"></el-input>
            </el-form-item>
            <el-form-item label="对应目录" v-if="befrom == 'levelTest'" label-width="70px">
                <el-select v-model="wangeditorForm.keyLearnId" placeholder="请选择">
                    <el-option v-for="(item, index) in themeList" :key="index" :label="item.noteTitle"
                        :value="item.noteId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="题目顺序" v-if="befrom == 'levelTest' || befrom == 'pastPapers'">
                <el-input-number v-model="wangeditorForm.titleOrder" :min="1" :max="1000" :step="1"
                    label="题目顺序"></el-input-number>
            </el-form-item>
            <el-form-item label="题目类型" v-if="noteType != 0">
                <el-radio-group v-model="wangeditorForm.titleType" @change="changeTitleType">
                    <el-radio :label="0">单选题</el-radio>
                    <el-radio :label="1">多选题</el-radio>
                    <el-radio :label="2">填空题</el-radio>
                    <el-radio :label="3" v-if="befrom == 'pastPapers'">问答题</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="题目内容">
                <div style="display: flex;">
                    <div style="border: 1px solid #ccc; flex: 3;" v-if="innerVisible">
                        <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                            :mode="mode" />
                        <Editor style="height: 500px; overflow-y: hidden" v-model="wangeditorForm.titleContent"
                            :defaultConfig="editorConfig" :mode="mode" @onChange="onChange" @onCreated="onCreated" />
                    </div>
                    <div class="showdiv" style="flex: 2;">
                        <div class="showdiv-title">显示区域：</div>
                        <div id="showdiv">
                            <div id="asd"></div>
                        </div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="题目答案" v-if="noteType != 0" label-width="70px">
                <el-input v-model="wangeditorForm.titleAnswer[0].content" v-if="wangeditorForm.titleType == '0'"
                    placeholder="答案请用大写字母" style="width: 200px;"></el-input>
                <el-input v-model="wangeditorForm.titleAnswer[0].content" v-if="wangeditorForm.titleType == '1'"
                    placeholder="答案请用大写字母并且用','隔开" style="width: 200px;"></el-input>
                <div v-if="wangeditorForm.titleType == '2'" style="display: flex;">
                    <div v-for="(item, index) in wangeditorForm.titleAnswer" :key="index"
                        style="width: 120px; margin: 10px;">
                        <el-input v-model="item.content"></el-input>
                    </div>
                </div>
                <el-input type="textarea" placeholder="请输入内容" v-model="wangeditorForm.titleAnswer[0].content"
                    v-if="wangeditorForm.titleType == '3'">
                </el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="confirmSubmission">确 认</el-button>
        </div>

    </el-dialog>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { Boot, IModuleConf } from '@wangeditor/editor'
import axios from "axios";
import { BaseURL } from "@/utils/request/baseURL.js";
export default {
    data() {
        return {
            innerVisible: false,
            editor: null,
            html: "",
            currentValue: '',
            // 工具栏配置（注意结构，否则不起作用）
            toolbarConfig: {
                excludeKeys: [
                    'todo',
                    'codeBlock',
                    'group-video',
                    'insertImage'
                ],
                insertKeys: {
                    keys: []
                }
            },
            // 菜单配置（注意结构，否则不起作用）
            editorConfig: {
                placeholder: "请输入内容...",
                MENU_CONF: {
                    uploadImage: {
                        // 自定义上传图片 方法
                        customUpload: this.uploadImg,
                        // 上传接口设置文件名
                        fieldName: "file",
                        meta: {
                            token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6NTI1NzQxMjM5OTg0MTk3LCJOYW1lIjoiYWRtaW4iLCJSb2xlSWQiOjUyNTc0MTIzOTc5NTc4MSwiSnd0VmVyc2lvbiI6IjE3MTE5NTY2MjMyODciLCJpYXQiOjE3MTE5NTY2MjMsIm5iZiI6MTcxMTk1NjYyMywiZXhwIjoxNzExOTU4NDIzLCJpc3MiOiJXZWJFZHVjYXRpb24uTmV0IiwiYXVkIjoiV2ViRWR1Y2F0aW9uLk5ldCJ9.a1TljKrfTV39GLGSKdqv2ODwkBB3tiBKpxcLaLqcAhg',
                        },
                    },
                    editorConfig: {
                        customInsert(res, insertFn) {                 // JS 语法
                            insertFn(url, alt, href)
                        },
                    }
                },
            },
            mode: "default",
            // or 'simple'
            // 图片、视频上传服务器地址
            uploadFileUrl: `${BaseURL}/file/uploadImgFile`,
            noteType: 0,
            wangeditorForm: {
                title: '',
                titleType: 0,
                titleContent: ``,
                titleAnswer: [
                    {
                        content: ''
                    }
                ],
                keyLearnId: '',
                titleOrder: 1
            },
            wangeditorDialogType: 'add',
            // 来自哪里
            befrom: 'refinedNote',
            // 主题数据
            themeList: []
        }
    },
    props: {
        /* 编辑器的内容 */
        value: {
            type: String,
            default: "",
        },
        /* 高度 */
        height: {
            type: Number,
            default: null,
        },
        /* 最小高度 */
        minHeight: {
            type: Number,
            default: null,
        },
        /* 只读 */
        readOnly: {
            type: Boolean,
            default: false,
        },
        // 上传文件大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        /* 类型（base64格式、url格式） */
        type: {
            type: String,
            default: "url",
        },
        activeValue: ''
    },
    watch: {
        // 监听父组件中editor值的变化
        value: {
            handler(val) {
                this.html = val
                this.currentValue = val
            },
            immediate: true,
        },
    },
    components: {
        Editor,
        Toolbar
    },
    methods: {
        handleClose(done) {
            this.innerVisible = false
            this.wangeditorForm.titleType = 0
            this.wangeditorForm.titleContent = ``
            this.wangeditorForm.titleAnswer = [{ content: '' }]
            this.befrom = 'refinedNote'
            this.themeList = []
        },
        addCheckbox() {
            class Radio {
                constructor() {
                    this.title = '单选框'
                    // this.iconSvg = '<svg >...</svg>'
                    this.tag = 'button'
                }
                getValue(editor) {
                    return '$radio'
                }
                isActive(editor) {
                    return false
                    // or true
                }
                isDisabled(editor) {
                    return false
                    // or true
                }
                exec(editor, value) {
                    editor.insertText(value)
                    // value 即 this.getValue(editor) 的返回值
                }
            }

            class Checkbox {
                constructor() {
                    this.title = '多选框'
                    // this.iconSvg = '<svg >...</svg>'
                    this.tag = 'button'
                }
                getValue(editor) {
                    return '$checkbox'
                }
                isActive(editor) {
                    return false // or true
                }
                isDisabled(editor) {
                    return false // or true
                }
                exec(editor, value) {
                    editor.insertText(value) // value 即 this.getValue(editor) 的返回值
                }
            }

            class Gapfilling {
                constructor() {
                    this.title = '填空框'
                    // this.iconSvg = '<svg >...</svg>'
                    this.tag = 'button'
                }
                getValue(editor) {
                    return '$Input'
                }
                isActive(editor) {
                    return false // or true
                }
                isDisabled(editor) {
                    return false // or true
                }
                exec(editor, value) {
                    editor.insertText(value) // value 即 this.getValue(editor) 的返回值
                }
            }


            class Textarea {
                constructor() {
                    this.title = '问答框'
                    // this.iconSvg = '<svg >...</svg>'
                    this.tag = 'button'
                }
                getValue(editor) {
                    return '$Textarea'
                }
                isActive(editor) {
                    return false // or true
                }
                isDisabled(editor) {
                    return false // or true
                }
                exec(editor, value) {
                    editor.insertText(value) // value 即 this.getValue(editor) 的返回值
                }
            }

            const myRadio = {
                key: 'myRadio',
                factory() {
                    return new Radio()
                }
            }

            const myCheckbox = {
                key: 'myCheckbox',
                factory() {
                    return new Checkbox()
                }
            }

            const myGapfilling = {
                key: "myGapfilling",
                factory() {
                    return new Gapfilling()
                }
            }

            const myTextarea = {
                key: "myTextarea",
                factory() {
                    return new Textarea()
                }
            }

            const module = {
                menus: [myRadio, myCheckbox, myGapfilling, myTextarea],
            }
            Boot.registerModule(module)
            localStorage.setItem('falg', true)

        },
        onCreated(editor) {
            const falg = localStorage.getItem('falg')
            if (falg == 'false') {
                this.addCheckbox()
            }
            // 一定要用 Object.seal() ，否则会报错
            this.editor = Object.seal(editor);
            // 假设editor是你的WangEditor实例
            this.toolbarConfig.insertKeys = {
                index: 5, // 插入的位置，基于当前的 toolbarKeys
                keys: ['myRadio', 'myCheckbox', 'myGapfilling', 'myTextarea']
            }
        },
        onChange(editor) {
            this.$emit('input', this.wangeditorForm.titleContent)
            var htmldata = this.wangeditorForm.titleContent.replace(/<br>/g, "<br/>");
            this.wangeditorForm.titleType == '0' && (htmldata = this.setRadio(htmldata))
            this.wangeditorForm.titleType == '1' && (htmldata = this.setCheckbox(htmldata))
            this.wangeditorForm.titleType == '2' && (htmldata = this.setInput(htmldata))
            this.wangeditorForm.titleType == '3' && (htmldata = this.setTextarea(htmldata))
            document.getElementById('showdiv').innerHTML = htmldata
        },
        //自定义上传图片
        uploadImg(file, insertFn) {
            var token = localStorage.getItem('token')
            let imgData = new FormData();
            imgData.append("file", file);
            axios({
                url: this.uploadFileUrl,
                data: imgData,
                method: "post",
                headers: {
                    Authorization: "Bearer " + token,
                },
            }).then((response) => {
                if (response.data.statusCode === 200) {
                    // 插入后端返回的url
                    insertFn('https://genztutoringnlabs.com/service/' + response.data.data);
                    this.$message({
                        type: "success",
                        message: "上传成功",
                    });
                } else {
                }
            });
        },
        // 将$radio变为单选框
        setRadio(questionStr) {
            // 存放数组
            var replacements = [];

            const xuan = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + 'px';
            }
            const matches = questionStr.match(/\$radio/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(`<input type="radio" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`)
            }

            // 当前替换索引
            let currentIndex = 0;

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$radio/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr
        },
        // 将$checkbox变为多选框
        setCheckbox(questionStr) {
            // 替换顺序数组
            const replacements = [];

            const xuan = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + 'px';
            }


            const matches = questionStr.match(/\$checkbox/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(`<input type="checkbox" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`)
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$checkbox/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr
        },
        // 将$Input变为填空框
        setInput(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Input/g);

            if (matches?.length > this.wangeditorForm.titleAnswer.length) {
                this.wangeditorForm.titleAnswer.push({
                    content: ''
                })
            }

            if (matches?.length < this.wangeditorForm.titleAnswer.length) {
                this.wangeditorForm.titleAnswer.pop()
            }

            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(`<input type="text" id="qwe${index}" class="qwe" style="text-align: center; border: 0px solid #fff; border-bottom: 1px solid #ccc;height:${fontSize}; width:${fontSize * 4}; font-size:${fontSize}; margin: 0 15px;"/>`)
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Input/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr
        },
        // 将$Textarea变为问答框
        setTextarea(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Textarea/g);

            if (matches?.length > this.wangeditorForm.titleAnswer.length) {
                this.wangeditorForm.titleAnswer.push({
                    content: ''
                })
            }

            if (matches?.length < this.wangeditorForm.titleAnswer.length) {
                this.wangeditorForm.titleAnswer.pop()
            }

            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(`<textarea cols="3" id="qwe${index}" class="qwe" rows="3" style="max-width:600px; min-width:300px; font-size:${fontSize}"></textarea>`)
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Textarea/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr
        },
        confirmSubmission() {
            this.$emit('submission')
        },
        changeTitleType() {
            this.wangeditorForm.titleAnswer = [{
                content: ''
            }]
        }
    },
    mounted() {
    },
    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁编辑器
    }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.showdiv {
    margin-left: 20px;
    flex: 2;

    .showdiv-title {
        font-size: 20px;
    }

    #showdiv {
        border: 1px solid #ccc;
        min-height: 500px;
    }
}
</style>