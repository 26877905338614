<template>
    <div class="body">
        <div class="header">
            <div class="conteritme">
                <img src="@/utils/img/logo.png" @click="Returns()" style="width: 150px;height: 66px;">
            </div>
            <div>
                <div class="conteritmes">
                    <span class="conteritme_span1">{{ userData.account }}</span><br />
                    <span class="conteritme_span2">{{ userData.id }}</span>

                </div>
                <!-- <el-dropdown   style="float: right;">
            <span class="el-dropdown-link" @click="handleCommand()">
              <el-avatar icon="el-icon-back"></el-avatar>
            </span>
          </el-dropdown>-->
            </div>

        </div>
        <div class="conter">
            <div class="conteritme">
                <h3 style="font-size: 35px;text-align: center">{{ chapterInfoPageData.chapterName }}</h3>
            </div>
            <div class="logindiv">
                <div class="swiper">
                    <div class="right">
                        <el-col :span="20">
                            <el-container>
                                <el-aside width="600px" style="background-color: #fff; margin-right: 20px; height:75vh">
                                    <el-menu :default-active="activeIndex" @select="handleSelect" class="el-menu-vertical-demo">
                                        <el-menu-item :class="[ asdindex==0?'changeel-demo':'']" index="0" @click="navigateTo('/Undirectory')">
                                            <span slot="title">Key-learning</span>
                                        </el-menu-item>
                                        <el-menu-item :class="[ asdindex==1?'changeel-demo':'']" index="1" @click="navigateTo('/Vocabularies')">
                                            <span slot="title">Vocabularies</span>
                                        </el-menu-item>
                                        <el-menu-item :class="[ asdindex==2?'changeel-demo':'']" index="2" @click="navigateTo('/Greenlightest')">
                                            <span slot="title">Greenlight Test</span>
                                        </el-menu-item>
                                        <el-menu-item :class="[ asdindex==3?'changeel-demo':'']" index="3" @click="navigateTo('/Pastpapers')">
                                            <span slot="title">Past Papers</span>
                                        </el-menu-item>
                                    </el-menu>
                                </el-aside>
                                <el-main style="background-color: #fff; margin-top:20px">
                                    <router-view></router-view>
                                </el-main>
                            </el-container>
                        </el-col>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
  <script>
export default {
    data() {
        return {
            userData: "",
            chapterInfoPageData: "",
            chapterId: "",
            chapterName: "",
            activeIndex: "", // 初始高亮的菜单项
            asdindex: "", // 自定义的索引，用于条件判断
        };
    },
    mounted() {
        this.getinfo();
        // 在组件挂载后获取本地存储的数据
        const user = localStorage.getItem("user");
        this.userData = JSON.parse(user);
        const chapterInfo = localStorage.getItem("chapterInfoPageData");
        this.chapterInfoPageData = JSON.parse(chapterInfo);
    },
    methods: {
        navigateTo(route) {
            this.$router.push(route); // 使用编程方式导航到指定的路由
        },
        handleCommand() {
            this.$router.push("/CourseHomePage");
        },
        handleSelect(index) {
            this.activeIndex = index; // 更新高亮的菜单项
            this.asdindex = index; // 更新自定义索引
            console.log(this.activeIndex, "this.activeIndex");
            console.log((this.asdindex = index), "this.asdindex =index");
        },
        Returns() {
            this.$router.push("/studentside");
        }, //返回
        async getinfo() {
            const chapterIds = localStorage.getItem("chapterId");
            this.chapterId = JSON.parse(chapterIds);
            const chapterName = localStorage.getItem("chapterName");
        },
    },
};
</script>
  <style lang="less" scoped>
.body {
    min-height: 80vh;
    position: relative;
}
.header {
    width: 100%;
    height: 15vh;
    background-color: rgb(10, 32, 97);
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .spanitem {
        color: #fff;
        font-size: 35px;
        padding: 5px 0;
    }
    .spanitem_lable {
        color: #fff;
        font-size: 15px;
    }
}
.conter {
    background: linear-gradient(180deg, rgb(214, 226, 254), rgb(255, 255, 255));
    width: 100%;
    height: 85vh;
    .conteritme {
        padding-top: 4%;
        .conteritme_span1 {
            font-size: 30px;
            line-height: 10svh;
            float: left;
        }
        .conteritme_span2 {
            font-size: 20px;
            float: right;
        }
    }
    .logindiv {
        width: 100%;
        min-width: 300px;
        height: 70%;
        background-color: #fff;
        box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        top: 5%;
        .swiper {
            height: 100%;
            display: flex;
            .right {
                flex: 2;
                height: 100%;
                .el-menu-vertical-demo {
                    margin: 10% 15%;
                    text-align: center;
                    font-size: 25px;
                }
                .box {
                    height: 50%;
                    font-size: 25px;
                }
                .changeel-demo {
                    font-size: 20px;
                    background-color: rgb(10, 32, 97);
                    span {
                        color: #fff;
                    }
                }
            }
            .left {
                flex: 3;
                height: 100%;
                border: 1px solid black;
            }
        }
    }
    .Header_text3 {
        width: 30%;
        height: 50px;
        margin: 0 12%;
        text-align: center;
        background-color: rgb(210, 233, 254);
        position: absolute;
        top: 50px;
        font-size: 20px;
        color: #fff;
        border: 1px solid red;
    }

    .Header_text3_router {
        text-decoration: none;
        border-bottom: 3px solid #fff;
        color: #561717;
        border: 1px solid red;
    }
}
.el-menu-vertical-demo .el-menu-item:hover {
    background-color: #fff; /* 设置鼠标悬停时的背景色 */
}
.conteritmes {
    float: right;
    .conteritme_span1 {
        float: right;
        font-size: 20px;
        color: #fff;
    }
    .conteritme_span2 {
        float: right;
        color: #fff;
    }
}
</style>
  