import serve from '@/utils/request/http.js'

export function queryKeyLearn(params) {
    return serve({
        url: '/refinedNotes/queryKeyLearn?' + params,
        method: 'get',
    })

}
export function quizAnswerJudgment(data) {
    return serve({
        url: '/refinedNotes/quizAnswerJudgment',
        method: 'POST',
        data
    })

}
