var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm}},[_c('el-row',[_c('el-col',{attrs:{"span":24,"sm":12}},[_c('p',{staticClass:"demo-title"},[_vm._v(_vm._s(_vm.$t("contact.demo")))]),_c('el-form-item',{attrs:{"prop":"text"}},[_c('p',{},[_vm._v(_vm._s(_vm.$t("contact.title")))])]),_c('el-form-item',{attrs:{"rules":[
          {
            required: true,
            message: this.$t('contact.demo6'),
            trigger: 'blur',
          },
        ],"prop":"name"}},[_vm._v(_vm._s(_vm.$t("contact.demo1"))),_c('strong',{staticClass:"demo-strong"},[_vm._v(_vm._s(_vm.$t("contact.required")))]),_c('el-input',{model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('el-form-item',{attrs:{"rules":[
          {
            required: true,
            message: this.$t('contact.demo7'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: this.$t('contact.demo8'),
            trigger: ['blur', 'change'],
          },
        ],"prop":"email"}},[_vm._v(_vm._s(_vm.$t("contact.demo2"))),_c('strong',{staticClass:"demo-strong"},[_vm._v(_vm._s(_vm.$t("contact.required")))]),_c('el-input',{model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", $$v)},expression:"ruleForm.email"}})],1),_c('el-form-item',{attrs:{"prop":"text"}},[_vm._v(_vm._s(_vm.$t("contact.demo3"))+" "),_c('el-input',{model:{value:(_vm.ruleForm.text),callback:function ($$v) {_vm.$set(_vm.ruleForm, "text", $$v)},expression:"ruleForm.text"}})],1),_c('el-form-item',{staticClass:"comments",attrs:{"prop":"desc"}},[_vm._v(_vm._s(_vm.$t("contact.demo4"))+" "),_c('el-input',{attrs:{"type":"textarea","rows":8},model:{value:(_vm.ruleForm.desc),callback:function ($$v) {_vm.$set(_vm.ruleForm, "desc", $$v)},expression:"ruleForm.desc"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"demo_button",attrs:{"type":""},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(_vm._s(_vm.$t("contact.demo5")))])],1)],1),_c('el-col',{staticStyle:{"left":"auto"},attrs:{"span":24,"sm":{ span: 10, offset: 2 }}},[_c('div',{staticClass:"page-right"},[_c('div',[_vm._v(_vm._s(_vm.$t("Search")))]),_c('div',{staticStyle:{"display":"flex"}},[_c('input',{staticStyle:{"height":"54px","background-color":"rgb(204, 232, 207)","border":"0.8px solid rgb(156 156 156)","margin-right":"10px","flex":"1"},attrs:{"type":"text"}}),_c('button',{staticStyle:{"height":"58px","background-color":"#002060","width":"100px","border":"0px","color":"#fff"}},[_vm._v(" "+_vm._s(_vm.$t("Search"))+" ")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }