import service from "@/utils/request/http";

// 获取章节信息
export function getQueryRecentChapterinfo(params){
    return service({
        url:'/chapter/queryPagingChapter',
        method:'get',
        params
    })
}

//修改章节信息
export function updateChapterInfo(data){
    return service({
        url:'/chapter/updateChapter',
        method:'post',
        data
    })
}

//删除章节信息
export function deleteChapterInfo(params){
    return service({
        url:'/chapter/deleteChapter',
        method:'get',
        params
    })
}

//添加章节信息
export function addChapterInfo(data){
    return service({
        url:'/chapter/addChapter',
        method:'post',
        data
    })
}