<template>
  <div>
    <Back class="backdiv" />
    <div class="inquire">
      <el-input placeholder="请输入内容" v-model="inquire" class="inquireInput">
        <template slot="append">
          <el-button type="primary" icon="el-icon-search" @click="queryLikekeyTeacher"></el-button>
        </template>
      </el-input>
      <el-button type="primary" @click="openDialog('add')">添加老师</el-button>
    </div>
    <div class="tablediv">
      <el-table :data="teacherList" border style="width: 100%" max-height="447">
        <el-table-column prop="account" label="账号名称">
        </el-table-column>
        <el-table-column prop="nickName" label="老师昵称">
        </el-table-column>
        <el-table-column label="老师性别">
          <template slot-scope="scope">
            <el-tag :type="scope.row.userSex == 0 ? 'success' : ''">{{ scope.row.userSex == 0 ? '女' : '男' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="position" label="老师职位">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="openDialog('update', scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteTeacher(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <teacherDialog ref="teacherDialog" @confirmTeacher="pushTeacher" />
  </div>
</template>

<script>
import { selectTeacherInfo, addVocabularyInfo, updateTeacherInfo, deleteTeacherInfo, queryLikekeyTeacherInfo } from './api/index.js'
import Back from '../components/index.vue'
import teacherDialog from './components/teacherDialog.vue'
export default {
  data() {
    return {
      teacherList: [],
      inquire: ''
    }
  },
  components: {
    teacherDialog,
    Back
  },
  methods: {
    // 查询老师信息
    async selectTeacher() {
      const res = await selectTeacherInfo()
      if (res.data.statusCode == 200) {
        this.teacherList = res.data.data
      }
    },
    // 打开弹框
    openDialog(dialogType, item = { account: '', nickName: '', userSex: 1, position: '' }) {
      this.$refs.teacherDialog.dialogType = dialogType
      this.$refs.teacherDialog.teacherform = { ...item }
      if (dialogType == 'updata') {
        this.$refs.teacherDialog.teacherId = item.teacherId
      }
      this.$refs.teacherDialog.dialogFormVisible = true
    },
    // 删除老师信息
    deleteTeacher(item) {
      this.$confirm(`确认删除${item.nickName}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteTeacherInfo({ teacherId: item.teacherId })
        if (res.data.statusCode == 200 && res.data.data) {
          this.$message.success('删除成功！')
          this.selectTeacher()
        } else {
          this.$message.error('删除失败！')
        }
      }).catch(() => { });
    },
    // 模糊查询
    async queryLikekeyTeacher() {
      const res = await queryLikekeyTeacherInfo({ where: this.inquire })
      if (res.data.statusCode == 200) {
        this.teacherList = res.data.data
      }
    },
    async pushTeacher() {
      var teacherform = { ...this.$refs.teacherDialog.teacherform }
      var res = undefined
      var messageTitle = '添加'
      if (this.$refs.teacherDialog.dialogType == 'add') {
        res = await addVocabularyInfo(teacherform)
      }

      if (this.$refs.teacherDialog.dialogType == 'update') {
        res = await updateTeacherInfo(teacherform)
        messageTitle = '修改'
      }

      this.$refs.teacherDialog.dialogFormVisible = false
      if (res.data.statusCode == 200 && res.data.data) {
        this.$message.success(messageTitle + '成功！')
        this.selectTeacher()
      } else {
        this.$message.error(messageTitle + '失败！')
      }
    }
  },
  mounted() {
    this.selectTeacher()
  }
}
</script>

<style lang="less" scoped>
.backdiv {
  margin: 20px 0;
}

.inquire {
  display: flex;
  margin-bottom: 40px;

  .inquireInput {
    width: 400px;
    margin-right: 40px;
  }
}
</style>