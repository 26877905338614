const English = {
    homepage: {
        header: "TUTORING AND LABS",
        language1: "Confidence starts here",
        navigation1: "About us",
        navigation2: "Academic support",
        navigation3: "Consultancy",
        navigation4: "Contact us",
        navigation5: "Resources",
        navigation6: "Entrance preparation",
        navigation7:"Login",
    },
    homeview: {
        header1: "Confidence starts here",
        header2: "Contact Us Now",
        Lrarn: "Learn with us",
        Lrarn1: "Academic support",
        Lrarn2: "Entrance preparation",
        Lrarn3: "Consultancy",
        What: "What we do…",
        What1: "GenZ believes in the power of education and that every student has potential. We focus on students, tailoring tutoring to their needs. Our tutors aim to empower students with knowledge and a growth mindset. We offer guidance on study strategies, time management, and goal setting. Our goal is to help students succeed academically and in life.",
        What2: "Learn more about us",
        value: "What we value…",
        value1: "Empowerment",
        value2: "We believe in empowering students to take charge of their own education and personal growth. We aim to instill self-confidence, resilience, and a growth mindset in our students, enabling them to overcome challenges and reach their full potential.",
        value3: "Professionalism",
        value4: "By placing experienced and A* graded tutors at the forefront, we provide our students with the highest level of expertise, personalized mentoring, and guidance. With our team of exceptional tutors, we are committed to upholding the utmost professionalism in delivering top-quality education to our students.",
        value5: "Student-centric",
        value6: "We prioritize the needs and aspirations of our students above all else. Our tutoring approach is tailored to cater to individual learning styles, allowing us to provide personalized support and guidance.",
        Tabbar: "FREE CONSULTATION",
        Tabbar1: "Book now!",
        Tabbar2: "Get in touch now",
    },
    contact: {
        demo: "Contact us",
        required: '(required)',
        title:"Please feel free to contact us if you are interested in our 1:1 tuition or online learningplatform.",
        demo1: "Name",
        demo2: "Email",
        demo3: "Subject",
        demo4: "Message",
        demo5: "Contact Us",
        demo6: "Name is required",
        demo7: "Email is required ",
        demo8: "Email requires a valid email address",
    },
    academic: {
        title: 'Academic support',
        introduce: 'We offer academic support to students at all stages, from bridging between Hong Kong and UK studies, Key stage 3, GCSEs, to A-levels. A large range of subjects are offered, most common subjects are listed but not limited by below:',
        li1: 'Biology',
        li2: 'Chemistry',
        li3: 'Computer science',
        li4: 'Physics',
        li5: 'English',
        li6: 'English literature',
        li7: 'Psychology',
        li8: 'Mathematics',
        morediv: '[Contact us] to know more now!'
    },
    Search: 'Search',
    examination: {
        title: 'Entrance preparations',
        introduce1: 'The education system in the UK consists of several stages: Key Stage 1 (5-7 years old), Key Stage 2 (7-11 years old), Key Stage 3 (11–14 years old), Key Stage 4 (GCSE) and Key Stage 5 (A-level).',
        introduce2: 'Parents may consider transferring schools between these key stages. Schools commonly require candidates to complete  mathematics and English tests and an interview to testify candidates’ abilities.',
        introduce3:'As for university admissions, all candidates are required to apply through UCAS to five of their interested universities. Candidates must also prepare a personal statement demonstrating their strengths and passion  for the subject. For some programmes, universities may require candidates to  complete extra tests, such as UCAT/ BMAT  for medicine and LNAT for law degrees. For more details, please visit the university  website.',
        introduce4:'We offer preparations for all admissions stages while specialising in Year 9, sixth form entrance exams, and university  admissions. We aim to support students at  every milestone by assisting their preparation and accompanying families through the critical stage of life.',
        support: 'Tests that we provide support for include:',
        li1: 'UCAT',
        li2: 'BMAT',
        li3: 'MAT',
        li4: 'TSA'
    },
    Consultancy:{
        title: 'Consultancy',
        introduce1: 'We understand the anxiety and panic students, or even parents may feel when  moving and studying in a foreign country. We are here to support and offer advice for international students and families, with stories we have witnessed and experienced.',
        introduce2: 'GenZ also provides tailored consultancy for university choices and support throughout the whole UCAS process, including personal statement writing and interview preparation.',
        content1:'Contact us',
        content2:'  to know more now!'
    },
    about:{
        title:'About us',
        introduce1:'GenZ Tutoring and Labs (aka GenZ) was founded in 2023 to provide tailored assistance to the student community in academic and personal aspects, thus fostering a sense of achievement and confidence for their future endeavours.',
        introduce2:'Founder Felicia, who herself completed her GCSE and GCE A-Level exams with 8 As and 5 A*s, respectively, and wasawarded The Chancellor’s Scholarship to study Psychology at a prestigious university, leveraged her firsthand experience of studying abroad in the UK to organise and train the GenZ tutoring team. This initiative aimed to help students learn more effectively and integrate into local life quickly.',
        introduce3:'Despite being established for less than  half a year, GenZ has already successfully provided over 700 hours of  consistent online tuition to more than  60 individuals. These tuition include Key Stage 3, GCSE, GCE A-Level, foundation year and Advanced diploma courses.',
        introduce4:'he GenZ tutoring team is well-versed in  the content and requirements of  different courses. By understanding the  challenges students face in their daily  lives, they provide learning experiences  and tips and guidance to navigate each  stage with confidence, thereby reducing  unnecessary worries for both students and parents.',
        introduce5:'GenZ hopes to assist more individuals  with different learning needs, helping to build a sense of achievement and  confidence and laying the foundation for future societal pillars.'
    },
    resources:{
        title:'Resources',
        introduce1:'We are working hard to provide more revision resources for our students. Please look forward to our full set of condensed notes down below.',
        introduce2:'Physics',
        introduce3:'Chemistry',
        introduce4:'Biology (coming soon)',
        },
        questions:{
            title:'FAQ',
            lable:'1:1 Tutoring',
            problem:'How does the tutoring process work?',
            answer:'You (or your child) would first be required to answer entrance questions to help us better understand your current level. We will then arrange a trial session with a suitable tutor. You can always contact us for any feedback or administrative issues, whereas the tutor would focus on preparing for lessons to ensure each session is top-quality. Once regular lessons are confirmed, you will be added to a Whatsapp group or whichever social media is most convenient for you with the tutor. Payments should be made before each lesson to confirm  before each session.',
            problem1:'What qualifications and experience do your tutors have?',
            answer1:'Our tutors have obtained relevant qualifications in the courses they specialise in. We are happy to provide proof upon request.',
            problem2:'What subjects and levels do you offer tutoring for?',
            answer2:'We offer a wide range of subjects, including but not limited to Physics, Chemistry, Biology,  Mathematics and English in GCSEs and A-levels. Please see [Academic Support] for more  details.',
            problem3:'How personalized are the tutoring sessions?',
            answer3:'All sessions are entirely personalised, meaning that our tutor will prepare specific content  tailored for you (or your child) based on your progress. This may include the style of teaching, content and practice composition, exam-board-specified content, etc. It should be noted that our tutors would adjust the lesson plan based on the student’s needs as the final  decision for lesson arrangement. For example, our tutor could focus on clearing up concepts  with the student first if the student was uncertain, even when they requested to work on practice questions.',
            problem4:'What is the bimonthly progress report?',
            answer4:'Tutors write the bimonthly progress report to update parents on how the students are doing.  This report could include specific content they have covered in the two months, areas of  improvement, and learning attitude. It is communicated to construct more effective sessions  and allow parents to assist in learning where needed.',
        },
        education:{
            lable:'Education platform',
            problem:'What are the key features of the platform?',
            answer:'Our platform consists of notes and diagrams for key-learning, with quizzes constructed in  between to scaffold understanding of each topic.',
            answer_1:'Under each topic, you may complete greenlight tests for a quick knowledge test. This gives you objective grading that highlights weaker areas that require more work.',
            answer_2:'As the platform is designed explicitly for Chinese-speaking overseas students, we have   prepared vocabulary lists allowing students to focus on understanding keywords with definitions and translations available.',
            answer_3:'After gaining a thorough understanding per concept, you may further test your knowledge in  our past paper question section, with mixed or topical modes to be completed at your own pace, with model answers provided.',
            problem1:'How is my progress tracked and assessed?',
            answer1:'Your progress in key-learning will be automatically saved and shown on the left-hand side of the page.',
            answer1_1:'Greenlight tests consist of multiple-choice and short questions, and they will be automatically assessed, producing an objective score. Past attempts in greenlight tests will also be saved for future reference.',
            answer1_2:'Past paper questions will be automatically marked or self-assessed, depending on the question style.',
            problem2:'What if the course I want is not available?',
            answer2:'We are working hard to provide as many courses and subjects as possible. If your subject is currently unavailable, please contact us, and we will notify you once the course is ready.',
            problem3:'How does the platform work?',
            answer3:'If you are interested, please click here to get in touch. Leave a short message about your course of interest, and we will get back to you as soon as possible. Once the payment is  through, you will receive our login credentials, and you will be set to go!',
            answer3_1:'Depending on your plan, you will have full access to the course for varying durations. You are free to start your learning anytime, anywhere.',
            problem4:'How much does each course cost?',
            answer4:'The cost depends on the duration of access, starting from as low as £20.8 per month,  roughly equal to a 1:1 one-hour lesson. Please click here for more information.',
            problem5:'Will I need to buy anything else?',
            answer5:'No. We provide everything you need for the course, including notes, a topical vocabulary list, and past paper questions.',
            answer5_1:'If you require access to another course, please click here to contact us.',
            problem6:'Can I cancel my monthly plans anytime?',
            answer6:'Yes, please click here to contact us on cancellation of plans. We will have it done with no  questions asked.',
        },
    login: {
        language: 'Eng',
        title: "Login Portal",
        username: 'Username',
        userpassword: 'Password',
        btn1: 'Login',
        btn2: "Tutors' Portal",
        usernameRules:'Username cannot be empty',
        userpasswordRules:'Password cannot be empty'
    }   
}
export default English