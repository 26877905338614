<template>
    <div class="coursepage">
        <el-scrollbar style="height:100%">
            <div class="content">
                <div class="coursediv" @click="openSubject('add')">
                    <span slot="prefix" class="iconfont icon-tianjiatubiao"
                        style="color: rgb(189 189 189);font-size: 60px;text-align: center;line-height: 350px;"></span>
                </div>
                <div class="coursediv" v-for="(item, index) in courselist" :key="index">
                    <div class="subjectName">
                        <el-tooltip class="item" effect="dark" :content="'创建时间：' + item.subjectVersion" placement="top">
                            <span style="padding: 10px 0;">{{ item.subjectName }}</span>
                        </el-tooltip>
                    </div>
                    <div class="subjectImgUrl" @click="tourl('/MangenmentChapter', item.subjectId)">
                        <img :src="'https://genztutoringnlabs.com/service/' + item.subjectImgUrl" alt=""
                            style="max-height: 230px; max-width: 300px;">
                    </div>
                    <div style="border-top: 1px solid #eee; display: flex; justify-content: center; padding: 10px 0;">
                        <el-tooltip class="item" effect="dark" content="修改课程" placement="top">
                            <el-button size="small" icon="el-icon-edit"
                                @click="openSubject('updata', { subjectId: item.subjectId, subjectName: item.subjectName, imageUrl: item.subjectImgUrl })"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除课程" placement="top">
                            <el-button icon="el-icon-delete" size="small" @click="delCourse(item)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="复制课程" placement="top">
                            <el-button icon="el-icon-document-copy" size="small" @click="copyCourse(item)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="分配权限" placement="top">
                            <el-button icon="el-icon-setting" size="small"
                                @click="openassidnCoursesDialog(item)"></el-button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
        </el-scrollbar>
        <courseDialog ref="addcourseDialog" @confirmcourse="addCourse" />
        <assidnCoursesDialog ref="assidnCoursesDialog" @assignAuthority="assignAuthority" />
    </div>
</template>

<script>
import courseDialog from './components/courseDialog.vue'
import assidnCoursesDialog from './components/assidnCoursesDialog.vue'
import { getCourseinfo, addCourseinfo, updataCourseinfo, delCourseinfo, copyCourseinfo, assignCourses, getStudentIdsBySubjectId, getStudentinfo } from "./api/index.js"
export default {
    data() {
        return {
            courselist: []
        }
    },
    components: {
        courseDialog,
        assidnCoursesDialog
    },
    methods: {
        // 获取课程信息
        async getCourse() {
            const res = await getCourseinfo({ currentPage: 1, pageSize: 1000 })
            if (res.data.statusCode == 200) {
                this.courselist = []
                res.data.data?.subjectInfoPageData.forEach(item => {
                    this.courselist.push(item)
                });
            }
        },
        openSubject(type, item = { subjectId: "", subjectName: '', imageUrl: '' }) {
            this.$refs.addcourseDialog.dialogType = type
            this.$refs.addcourseDialog.courseform.subjectId = item.subjectId
            this.$refs.addcourseDialog.courseform.subjectName = item.subjectName
            this.$refs.addcourseDialog.dialogFormVisible = true
            if (type == 'updata') {
                this.$refs.addcourseDialog.oldImageUrl = item.imageUrl
            }
        },
        // 提交课程信息
        async addCourse() {
            var courseInfo = {
                subjectName: this.$refs.addcourseDialog.courseform.subjectName,
                subjectImgPath: this.$refs.addcourseDialog.courseform.imageUrl
            }
            var messageTitle = '添加'
            var res = {}
            if (this.$refs.addcourseDialog.dialogType == 'updata') {
                courseInfo.subjectId = this.$refs.addcourseDialog.courseform.subjectId
                courseInfo.subjectImgPath == '' && (courseInfo.subjectImgPath = this.$refs.addcourseDialog.oldImageUrl)
                messageTitle = '修改'
                res = await updataCourseinfo(courseInfo)
            } else {
                res = await addCourseinfo(courseInfo)
            }
            if (res.data.statusCode == 200 && res.data.data) {
                this.$refs.addcourseDialog.dialogFormVisible = false
                this.$refs.addcourseDialog.courseform = {}
                this.$message.success(messageTitle + '成功！')
                this.getCourse()
            } else {
                this.$message.error(messageTitle + '失败，' + res.data.errors + '!')
            }
        },
        // 删除课程
        async delCourse(item) {
            this.$confirm(`确认删除${item.subjectName}吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await delCourseinfo({ subjectId: item.subjectId })
                if (res.data.statusCode == 200 && res.data.data) {
                    this.$message.success('删除成功!')
                    this.getCourse()
                } else {
                    this.$message.error('删除失败!')
                }
            }).catch(() => { });
        },
        // 复制课程
        async copyCourse(item) {
            const res = await copyCourseinfo({ subjectId: item.subjectId })
            if (res.data.statusCode == 200 && res.data.data) {
                this.$message.success('复制成功!')
                this.getCourse()
            } else {
                this.$message.error('复制失败!')
            }
        },
        //打开分配权限弹框
        async openassidnCoursesDialog(item) {
            this.$refs.assidnCoursesDialog.alldata = []
            this.$refs.assidnCoursesDialog.Permissions = []
            this.$refs.assidnCoursesDialog.subjectId = item.subjectId
            const res1 = await getStudentinfo({ currentPage: 1, pageSize: 1000 })
            const userinfo = res1.data.data.userInfoPageData;
            userinfo.forEach(item => {
                this.$refs.assidnCoursesDialog.alldata.push({
                    label: item.account,
                    key: item.id,
                    pinyin: item.account
                })
            })
            const res = await getStudentIdsBySubjectId({ subjectId: item.subjectId })
            res.data.data.forEach(item => {
                this.$refs.assidnCoursesDialog.Permissions.push(item.id)
            })
            this.$refs.assidnCoursesDialog.dialogFormVisible = true
        },
        // 分配权限
        async assignAuthority() {
            console.log('subjectId', this.$refs.assidnCoursesDialog.subjectId);
            console.log('studentIdList', this.$refs.assidnCoursesDialog.Permissions);
            const res = await assignCourses({ subjectId: this.$refs.assidnCoursesDialog.subjectId, studentIdList: this.$refs.assidnCoursesDialog.Permissions })
            if (res.data.statusCode == '200' && res.data.data) {
                this.$refs.assidnCoursesDialog.dialogFormVisible = false
                this.$message.success('配置成功!')
                this.getCourse()
            } else {
                this.$message.error('配置失败!')
            }
        },
        tourl(path, subjectId) {
            this.$router.push({
                path,
                query: {
                    id: subjectId
                }
            })
        }
    },
    mounted() {
        this.getCourse()
    }
}
</script>

<style lang="less">
.coursepage {
    .content {
        height: 82vh;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
    }

    .coursediv {
        width: 350px;
        height: 350px;
        border: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 20px;
        border-radius: 10px;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

        .subjectName {
            border-bottom: 1px solid #eee;
            display: flex;
            justify-content: center;
        }

        .subjectImgUrl {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .subjectImgUrl:hover {
            cursor: pointer;
        }


    }

    .coursediv:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
}
</style>