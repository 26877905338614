<template>
  <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
    <el-row>
      <el-col :span="24" :sm="12">
        <p class="demo-title">{{ $t("contact.demo") }}</p>
        <el-form-item prop="text">
          <p class="">{{ $t("contact.title") }}</p>
        </el-form-item>
        <el-form-item
          :rules="[
            {
              required: true,
              message: this.$t('contact.demo6'),
              trigger: 'blur',
            },
          ]"
          prop="name"
          >{{ $t("contact.demo1")
          }}<strong class="demo-strong">{{ $t("contact.required") }}</strong>
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            {
              required: true,
              message: this.$t('contact.demo7'),
              trigger: 'blur',
            },
            {
              type: 'email',
              message: this.$t('contact.demo8'),
              trigger: ['blur', 'change'],
            },
          ]"
          prop="email"
          >{{ $t("contact.demo2")
          }}<strong class="demo-strong">{{ $t("contact.required") }}</strong>
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item prop="text"
          >{{ $t("contact.demo3") }}
          <el-input v-model="ruleForm.text"></el-input>
        </el-form-item>
        <el-form-item prop="desc" class="comments"
          >{{ $t("contact.demo4") }}
          <el-input
            type="textarea"
            v-model="ruleForm.desc"
            :rows="8"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type=""
            class="demo_button"
            @click="submitForm('ruleForm')"
            >{{ $t("contact.demo5") }}</el-button
          >
        </el-form-item>
      </el-col>
      <el-col :span="24" :sm="{ span: 10, offset: 2 }" style="left: auto">
        <div class="page-right">
          <div>{{ $t("Search") }}</div>
          <div style="display: flex">
            <input
              type="text"
              style="
                height: 54px;
                background-color: rgb(204, 232, 207);
                border: 0.8px solid rgb(156 156 156);
                margin-right: 10px;
                flex: 1;
              "
            />
            <button
              style="
                height: 58px;
                background-color: #002060;
                width: 100px;
                border: 0px;
                color: #fff;
              "
            >
              {{ $t("Search") }}
            </button>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      isshow: false,
      languageType: "English",
      ruleForm: {
        name: "",
        email: "",
        text: "",
        desc: "",
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios({
            method: 'post',
            url: 'https://genztutoringnlabs.com/service/api/mail/sendMail',
            data: {
              sendMail: this.ruleForm.email,
              sendName: this.ruleForm.name,
              sendSubject: this.ruleForm.text,
              sendMessage: this.ruleForm.desc
            },
          }).then((res) => {
            console.log(res)
            if (res.status === 200) {
              alert("已发至邮箱，请查看!");
              this.showEmail = false;
            } else {
              alert("异常");
              throw e;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.demo-ruleForm {
  margin: 5% 10%;
}
.demo-title {
  font-size: 50px;
  color: black;
}
.demo_button {
  color: #fff;
  background-color: rgb(236, 90, 48);
}
.demo-strong {
  font-size: 85%;
  font-weight: 400;
  margin-left: 0.25em;
  opacity: 0.6;
}
</style>
