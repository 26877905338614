<template>
    <div class="loginpage">
        <div class="brand"   @click=" tourl ()">
            <span class="span1" >FAQ</span>
        </div>
        <div class="logindiv">
            <div>
                <el-menu  :default-active="this.$route.path" router class="el-menu-demo" mode="horizontal" >
                <el-menu-item index="/Tutoring" style="margin: 0 25%;" > {{ $t('questions.lable') }}</el-menu-item>
                <el-menu-item index="/education"  >{{ $t('education.lable') }}</el-menu-item>
                </el-menu>
                <el-scrollbar style="height:100%;">
                <router-view ></router-view>
              </el-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      isshow: false,
      languageType: 'English'
    }
  },
  methods: {

    tourl () {
      if (this.$route.path == '/' || this.$route.path == '/home') {
        return false
      }
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
.loginpage {
    width: 100vw;
    height: 100vh;
    background:linear-gradient(180deg,rgb(212,224,254), #fff );
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand {
    height: 160px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background: #0A2061;
}

.span1 {
    font-size: 30px;
    margin-left: 8%;
    margin-bottom: 3%;
}

.logindiv {
    width: 80%;
    min-width: 300px;
    height:70%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    padding: 20px 40px;
}
</style>