<template>
    <div>
        <Back class="backdiv" />
        <div style="margin-bottom: 20px; font-size: 25px;">水平测试题目管理</div>
        <div class="inquire">
            <el-input placeholder="请输入内容" v-model="inquire" class="inquireInput">
                <template slot="append">
                    <el-button type="primary" icon="el-icon-search"
                        @click="queryFullTestPaperLikeTitleName"></el-button>
                </template>
            </el-input>
            <el-button type="primary" @click="openDialog('add')">添加题目</el-button>
        </div>
        <el-table :data="titleList" border style="width: 100%">
            <el-table-column prop="title" label="题目名称" width="180">
            </el-table-column>
            <el-table-column prop="titleType" label="题目类型" align="center">
                <template slot-scope="scope">
                    <el-tag>{{ TypeName[scope.row.titleType] }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="题目内容" width="180" align="center">
                <template slot-scope="scope">
                    <el-button @click="viewContent(scope.row)">查看题目</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="titleOrder" sortable label="题目排序" align="center">
            </el-table-column>
            <el-table-column label="是否显示">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.titleStatus" active-color="#13ce66" inactive-color="#ff4949"
                        @change="updateTestPaperTitleStatus(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                    <el-button size="mini" @click="openDialog('updata', scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="deleteInfo(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[5, 10, 15, 20]" :page-size="pageSize" layout="sizes, prev, pager, next" :total="totalCount"
            style="margin-top: 20px;">
        </el-pagination>
        <wangeditor ref="wangeditor" @submission="pushTitleInfo" />
        <topicDialog ref="topicDialog" />
    </div>
</template>

<script>
import wangeditor from '../components/wangeditor.vue'
import Back from '../components/index.vue'
import topicDialog from './components/topicDialog.vue'
import { queryFullTestPaperLikeTitleNameInfo, queryKeyLearnChildrenDirectoryInfo, addTestPaperInfo, deleteSingleTestPaperInfo, updateTestPaperInfo, updateTestPaperTitleStatusInfo } from './api/index.js'
export default {
    data() {
        return {
            inquire: '',
            keyLearnDirectoryId: '',
            chapterId: '',
            titleList: [],
            LearnDirectoryInfo: [],
            pageSize: 5,
            totalCount: 0,
            currentPage: 1,
            TypeName: ['单选题', '多选题', '填空题', '问答题']
        }
    },
    components: {
        Back,
        wangeditor,
        topicDialog
    },
    methods: {
        async queryKeyLearnChildrenDirectory() {
            this.LearnDirectoryInfo = []
            const res = await queryKeyLearnChildrenDirectoryInfo({ chapterId: this.chapterId, keyLearnDirectoryId: this.keyLearnDirectoryId })
            if (res.data.statusCode == 200) {
                res.data.data.forEach(item => {
                    if (item.noteType == 0) {
                        this.LearnDirectoryInfo.push({
                            noteTitle: item.noteTitle,
                            noteId: item.noteId
                        })
                    }
                });
            }
        },
        queryLikeKeyLearnByNote() {

        },
        openDialog(wangeditorDialogType, item = { titleType: 0, titleContentJson: ``, titleAnswerJson: [{ content: '' }], title: '', titleOrder: '', keyLearnId: '', titleId: '', titleOrder: 0 }) {
            this.$refs.wangeditor.wangeditorDialogType = wangeditorDialogType
            this.$refs.wangeditor.noteType = 1
            this.$refs.wangeditor.befrom = 'levelTest'
            this.$refs.wangeditor.themeList = this.LearnDirectoryInfo
            this.$refs.wangeditor.wangeditorForm.titleType = item.titleType
            this.$refs.wangeditor.wangeditorForm.titleContent = item.titleContentJson
            this.$refs.wangeditor.wangeditorForm.titleAnswer = wangeditorDialogType == 'add' ? item.titleAnswerJson : JSON.parse(item.titleAnswerJson)
            this.$refs.wangeditor.wangeditorForm.title = item.title
            this.$refs.wangeditor.wangeditorForm.keyLearnId = item.keyLearnId
            this.$refs.wangeditor.wangeditorForm.testPaperId = item.titleId
            this.$refs.wangeditor.wangeditorForm.titleOrder = item.titleOrder
            this.$refs.wangeditor.innerVisible = true
        },
        async queryFullTestPaperLikeTitleName() {
            const res = await queryFullTestPaperLikeTitleNameInfo({ keyLearnDirectoryId: this.keyLearnDirectoryId, currentPage: this.currentPage, pageSize: this.pageSize, titleName: this.inquire })
            if (res.data.statusCode == 200) {
                this.titleList = res.data.data.testPaperPageData
                this.totalCount = res.data.data.pageInfo.totalCount
            }
        },
        viewContent(item) {
            this.$refs.topicDialog.htmlList = item
            this.$refs.topicDialog.dialogFormVisible = true
        },
        async pushTitleInfo() {
            if (this.$refs.wangeditor.wangeditorForm.title == '' || this.$refs.wangeditor.wangeditorForm.titleOrder == '' || this.$refs.wangeditor.wangeditorForm.keyLearnId == "") {
                this.$message.info('请填写完你的题目信息！')
                return
            }
            var titleInfo = {
                title: this.$refs.wangeditor.wangeditorForm.title,
                titleType: this.$refs.wangeditor.wangeditorForm.titleType,
                titleContentJson: this.$refs.wangeditor.wangeditorForm.titleContent,
                titleAnswerJson: JSON.stringify(this.$refs.wangeditor.wangeditorForm.titleAnswer),
                titleOrder: this.$refs.wangeditor.wangeditorForm.titleOrder,
                keyLearnId: this.$refs.wangeditor.wangeditorForm.keyLearnId
            }
            var res = undefined
            var messageTitle = '添加'

            if (this.$refs.wangeditor.wangeditorDialogType == 'add') {
                titleInfo.keyLearnDirectoryId = this.keyLearnDirectoryId
                res = await addTestPaperInfo(titleInfo)
            }

            if (this.$refs.wangeditor.wangeditorDialogType == 'updata') {
                titleInfo.testPaperId = this.$refs.wangeditor.wangeditorForm.testPaperId
                res = await updateTestPaperInfo(titleInfo)
                messageTitle = '修改'
            }

            if (res.data.statusCode == 200 && res.data.data) {
                this.$refs.wangeditor.innerVisible = false
                this.$message.success(messageTitle + '成功！')
                this.queryFullTestPaperLikeTitleName()
            } else {
                this.$message.error(messageTitle + '失败！')
            }
        },
        deleteInfo(item) {
            this.$confirm(`确认删除${item.title}吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await deleteSingleTestPaperInfo({ testPaperId: item.titleId })
                if (res.data.statusCode == 200 && res.data.data) {
                    this.$message.success('删除成功！')
                    this.queryFullTestPaperLikeTitleName()
                } else {
                    this.$message.error('删除失败！')
                }
            }).catch(() => { });
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.currentPage = 1
            this.queryFullTestPaperLikeTitleName()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.queryFullTestPaperLikeTitleName()
        },
        async updateTestPaperTitleStatus(item) {
            const res = await updateTestPaperTitleStatusInfo({ titleId: item.titleId })
            if (res.data.statusCode == 200 && res.data.data) {
                this.$message.success('修改成功！')
            }
        }
    },
    mounted() {
        this.chapterId = this.$route.query.chapterId;
        this.keyLearnDirectoryId = this.$route.query.keyLearnDirectoryId;
        this.queryFullTestPaperLikeTitleName()
        this.queryKeyLearnChildrenDirectory()
    }
}
</script>

<style lang="less" scoped>
.backdiv {
    margin: 20px 0;
}

.inquire {
    display: flex;
    margin-bottom: 40px;

    .inquireInput {
        width: 400px;
        margin-right: 40px;
    }
}
</style>