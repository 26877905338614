<template>
    <div>
        <Back style="margin: 20px 0;" />
        <div style="margin-bottom: 20px; font-size: 25px;">笔记管理</div>
        <div class="inquire">
            <el-input placeholder="请输入内容" v-model="inquire" class="inquireInput">
                <template slot="append">
                    <el-button type="primary" icon="el-icon-search" @click="getrefinedNoteList"></el-button>
                </template>
            </el-input>
            <el-button type="primary" @click="openDialog('add')">添加笔记</el-button>
        </div>
        <div class="tablediv">
            <el-table :data="refinedNote" border style="width: 100%" max-height="447">
                <el-table-column prop="noteTitle" label="笔记标题">
                </el-table-column>
                <el-table-column prop="noteType" label="笔记类型">
                </el-table-column>
                <el-table-column prop="noteContentJson" label="笔记内容">
                    <template slot-scope="scope">
                        <el-button @click="viewDetails(scope.row)">查看详情</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="noteOrder" sortable label="笔记排序">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="openDialog('updata', scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger" @click="deleteKeyLearn(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <refinedNoteDialog ref="refinedNote" @pushRefinedNoteForm="pushRefinedNote" />
        <detailDialog ref="detail" />
    </div>
</template>

<script>
import refinedNoteDialog from './components/refinedNoteDialog.vue'
import detailDialog from './components/detailDialog.vue'
import Back from '../components/index.vue'
import { queryKeyLearnBychapterIdInfo, updateVocabularyInfo, deleteKeyLearnInfo, addKeyLearnInfo } from './api/index.js'
export default {
    data() {
        return {
            inquire: '',
            refinedNote: [],
            chapterId: '',
            keyLearnDirectoryId: ''
        }
    },
    components: {
        Back,
        refinedNoteDialog,
        detailDialog
    },
    methods: {
        async getrefinedNoteList() {
            const res = await queryKeyLearnBychapterIdInfo({ chapterId: this.chapterId, keyLearnDirectoryId: this.keyLearnDirectoryId })
            if (res.data.statusCode == 200) {
                this.refinedNote = res.data.data
            }

        },
        viewDetails(item) {
            this.$refs.detail.indexNum = 0
            this.$refs.detail.htmlList = []
            this.$refs.detail.htmlList = JSON.parse(item.noteContentJson)
            this.$refs.detail.dialogFormVisible = true
            // this.$refs.detail.$emit("sing")
        },
        openDialog(DialogType, item = { noteTitle: '', noteType: 0, noteOrder: 1, keyLearnId: '', noteContentJson: [] }) {
            this.$refs.refinedNote.DialogType = DialogType
            this.$refs.refinedNote.dialogFormVisible = true
            this.$refs.refinedNote.refinedNoteForm.noteTitle = item.noteTitle
            this.$refs.refinedNote.refinedNoteForm.noteType = item.noteType
            this.$refs.refinedNote.refinedNoteForm.noteOrder = item.noteOrder
            this.$refs.refinedNote.refinedNoteForm.keyLearnId = item.keyLearnId
            this.$refs.refinedNote.refinedNoteForm.noteContentJson = DialogType == 'updata' ? [...JSON.parse(item.noteContentJson)] : item.noteContentJson
            this.$refs.refinedNote.maxTitleNum = (DialogType == 'updata' && item.noteType == 1) ? 100 : 1
        },
        async pushRefinedNote() {
            if (this.$refs.refinedNote.refinedNoteForm.noteTitle == '') {
                this.$message.info('请填写完你的题目信息！')
                return
            }
            var messagedata = '添加成功！'
            var refinedNote = {
                keyLearnDirectoryId: this.keyLearnDirectoryId,
                noteTitle: this.$refs.refinedNote.refinedNoteForm.noteTitle,
                noteType: this.$refs.refinedNote.refinedNoteForm.noteType,
                noteContentJson: JSON.stringify(this.$refs.refinedNote.refinedNoteForm.noteContentJson),
                noteOrder: this.$refs.refinedNote.refinedNoteForm.noteOrder
            }
            var res = undefined
            if (this.$refs.refinedNote.DialogType == 'add') {
                res = await addKeyLearnInfo(refinedNote)
            }

            if (this.$refs.refinedNote.DialogType == 'updata') {
                refinedNote.keyLearnId = this.$refs.refinedNote.refinedNoteForm.keyLearnId
                res = await updateVocabularyInfo(refinedNote)
                messagedata = '修改成功！'
            }

            if (res.data.statusCode == 200 && res.data.data) {
                this.$message.success(messagedata)
                this.$refs.refinedNote.dialogFormVisible = false
                this.getrefinedNoteList()
            }
        },
        deleteKeyLearn(item) {
            this.$confirm(`确认删除${item.noteTitle}吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const res = await deleteKeyLearnInfo({ keyLearnId: item.keyLearnId })
                if (res.data.statusCode == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });
                    this.getrefinedNoteList()
                }
            }).catch(() => { });
        }
    },
    mounted() {
        this.keyLearnDirectoryId = this.$route.query.keyLearnDirectoryId;
        this.chapterId = this.$route.query.chapterId
        this.getrefinedNoteList()
    }
}
</script>

<style lang="less" scoped>
.backdiv {
    margin: 20px 0;
}

.inquire {
    display: flex;
    margin-bottom: 40px;

    .inquireInput {
        width: 400px;
        margin-right: 40px;
    }
}
</style>