<template>
    <div class="body">
        <div class="header">
            <div class="">
            <img src="@/utils/img/logo.png" @click="Returns()"  style="width: 150px;height: 66px;">
            </div>
            <div>
                <div class="conteritmes">
                    <span class="conteritme_span1">{{ userData.account }}</span><br />
                    <span class="conteritme_span2">{{ userData.id }}</span>
                </div>
            </div>
        </div>
        <div class="conter">
            <div class="conteritme">
                <span style=" font-size: 25px;color: #fff;">{{ chapterInfoPageData.chapterName }}</span><br>
            </div>
            <div class="logindiv">
                <div class="swiper">
                    <div class="right">
                        <div style="width: 80%; display: flex;justify-content: right;">
                            <p class="right_P">Question {{ indexNum + 1 }} out of {{ htmlList.length }}</p>
                        </div>
                        <div class="showdiv">
                            <el-scrollbar style="height: 100%;">
                                <div v-if="indexNum >= 0" style="font-size: 30px;">{{ htmlList[indexNum].title }}</div>
                                <div v-html="htmldata" ref="showdiv" style="width: 500px; min-height: 400px;height: 100%;">
                                </div>
                            </el-scrollbar>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: right;width: 80%;margin-top: 20px;">
                        <div class="btnClass" style="margin-right: 20px;" @click="previousQuestion" v-if="indexNum >= 1">previous</div>
                        <div class="btnClass" @click="nextQuestion" v-if="indexNum < htmlList.length - 1">Next</div>
                        <div class="btnClass" @click="pushtitle" v-if="indexNum >= htmlList.length - 1">Finish</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { queryTestPaperInfo, answerJudgmentInfo } from "./api/index.js";
export default {
    data() {
        return {
            userData: "",
            chapterInfoPageData: "",
            chapterId: "",
            chapterName: "",
            keyLearnDirectoryId: "",
            htmlList: [],
            htmldata: "",
            indexNum: -1,
            AnswerList: [],
        };
    },
    mounted() {
        this.keyLearnDirectoryId = this.$route.query.keyLearnDirectoryId;
        this.userData = JSON.parse(localStorage.getItem("user"));
        this.chapterInfoPageData = JSON.parse(
            localStorage.getItem("chapterInfoPageData")
        );
        this.queryTestPaper();
    },
    methods: {
        async queryTestPaper() {
            const res = await queryTestPaperInfo({
                keyLearnDirectoryId: this.keyLearnDirectoryId,
            });
            if (res.data.statusCode == 200) {
                this.htmlList = res.data.data;
                this.htmlList = this.htmlList.sort(
                    (a, b) => a.titleOrder - b.titleOrder
                );
                this.nextQuestion();
                this.htmlList.forEach((item, index) => {
                    this.AnswerList.push({
                        testPaperId: this.htmlList[index].titleId,
                        titleType: this.htmlList[index].titleType,
                        titleAnswer: [
                            {
                                content: "",
                            },
                        ],
                    });
                });
            }
        },
        setRadio(questionStr) {
            // 存放数组
            var replacements = [];

            const xuan = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ];

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
            }
            const matches = questionStr.match(/\$radio/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="radio" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
                );
            }

            // 当前替换索引
            let currentIndex = 0;

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$radio/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$checkbox变为多选框
        setCheckbox(questionStr) {
            // 替换顺序数组
            const replacements = [];

            const xuan = [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
            }

            const matches = questionStr.match(/\$checkbox/g);
            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="checkbox" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$checkbox/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$Input变为填空框
        setInput(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Input/g);

            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<input type="text" id="qwe${index}" class="qwe" style="text-align: center; border: 0px solid #fff; border-bottom: 1px solid #ccc;height:${fontSize}; width:${
                        fontSize * 4
                    }; font-size:${fontSize}; margin: 0 15px;"/>`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Input/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 将$Textarea变为问答框
        setTextarea(questionStr) {
            // 替换顺序数组
            const replacements = [];

            // 当前替换索引
            let currentIndex = 0;

            var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
            // 声明默认字体大小
            var fontSize = 29;

            // 检查是否匹配成功并输出结果
            if (fontSizeMatch) {
                fontSize = fontSizeMatch[1];
            }

            const matches = questionStr.match(/\$Textarea/g);

            const count = matches ? matches.length : 0;
            for (let index = 0; index <= count; index++) {
                replacements.push(
                    `<textarea cols="3" id="qwe${index}" class="qwe" rows="3" style="max-width:600px; min-width:300px; font-size:${fontSize}"></textarea>`
                );
            }

            // 使用replace方法和正则表达式来逐个替换$checkbox
            const replacedStr = questionStr.replace(/\$Textarea/g, (match) => {
                // 获取当前匹配项的替换值
                const replacement = replacements[currentIndex];
                // 更新索引以便下次替换
                currentIndex++;
                // 返回替换值
                return replacement;
            });
            return replacedStr;
        },
        // 修改题目
        openDialog() {
            switch (this.htmlList[this.indexNum].titleType) {
                //单选题
                case 0:
                    this.htmldata = this.setRadio(
                        this.htmlList[this.indexNum].titleContentJson
                    );
                    this.$nextTick(this.getRadioAnswer);
                    this.$nextTick(this.setRadioAnswer);
                    break;
                //多选题
                case 1:
                    this.htmldata = this.setCheckbox(
                        this.htmlList[this.indexNum].titleContentJson
                    );
                    this.$nextTick(this.getCheckboxAnswer);
                    this.$nextTick(this.setCheckboxAnswer);
                    break;
                //填空题
                case 2:
                    this.htmldata = this.setInput(
                        this.htmlList[this.indexNum].titleContentJson
                    );
                    this.$nextTick(this.getInputAnswer);
                    this.$nextTick(this.setInputAnswer);
                    break;
                //问答题
                case 3:
                    this.htmldata = this.setTextarea(
                        this.htmlList[this.indexNum].titleContentJson
                    );
                    this.$nextTick(this.getTextareaAnswer);
                    this.$nextTick(this.setTextareaAnswer);
                    break;
                default:
                    this.$message.error("该题型未得到应用");
            }
        },
        // 单选框回显
        setRadioAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((item) => {
                if (
                    item.value ==
                    this.AnswerList[this.indexNum].titleAnswer[0].content
                ) {
                    item.checked = true;
                }
            });
        },
        // 复选框回显
        setCheckboxAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            var answerlist =
                this.AnswerList[this.indexNum].titleAnswer[0].content.split(
                    ","
                );
            radios.forEach((item) => {
                answerlist.forEach((answeritem) => {
                    answeritem == item.value && (item.checked = true);
                });
            });
        },
        // 填空框回显
        setInputAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem, i) => {
                inputItem.value =
                    this.AnswerList[this.indexNum].titleAnswer[i].content;
            });
        },
        // 问答框回显
        setTextareaAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.value =
                    this.AnswerList[this.indexNum].titleAnswer[0].content;
            });
        },
        // 为单选框绑定事件
        getRadioAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    radios.forEach((item) => {
                        item.checked &&
                            (this.AnswerList[
                                this.indexNum
                            ].titleAnswer[0].content = item.value);
                    });
                });
            });
        },
        // 为复选框绑定事件
        getCheckboxAnswer() {
            var radios = this.$refs.showdiv.querySelectorAll(".qwe");
            radios.forEach((radio) => {
                radio.addEventListener("change", () => {
                    var asdlist = [];
                    radios.forEach((item) => {
                        item.checked && asdlist.push(item.value);
                    });
                    this.AnswerList[this.indexNum].titleAnswer[0].content =
                        asdlist.join(",");
                });
            });
        },
        // 为填空框绑定事件
        getInputAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.addEventListener("change", () => {
                    var asdlist = [];
                    inputs.forEach((item) => {
                        asdlist.push(item.value);
                    });
                    this.AnswerList[this.indexNum].titleAnswer = [];
                    asdlist.forEach((item) => {
                        this.AnswerList[this.indexNum].titleAnswer.push({
                            content: item,
                        });
                    });
                });
            });
        },
        // 为问答框绑定事件
        getTextareaAnswer() {
            var inputs = this.$refs.showdiv.querySelectorAll(".qwe");
            inputs.forEach((inputItem) => {
                inputItem.addEventListener("change", () => {
                    inputs.forEach((item) => {
                        this.AnswerList[this.indexNum].titleAnswer[0].content =
                            item.value;
                    });
                });
            });
        },
        //下一题的操作
        nextQuestion() {
            this.indexNum++;
            this.openDialog();
        },
        previousQuestion() {
            this.indexNum--;
            this.openDialog();
        },
        Returns(){
            this.$router.push("/studentside");
        },
        async pushtitle() {
            const AnswerInfo = {
                studentId: this.userData.id,
                keyLearnDirectoryId: this.keyLearnDirectoryId,
                answerData: this.AnswerList,
            };
            this.$confirm(`Do you confirm to submit the test?`, "Hint", {
                confirmButtonText: "Confirm",
                cancelButtonText: "Cancel",
                type: "warning",
            })
                .then(async () => {
                    const res = await answerJudgmentInfo(AnswerInfo);
                    if (res.data.statusCode == 200 && res.data.data) {
                        this.$message.success("提交成功!");
                        this.$router.push({
                            path: "/evaluationPaperview",
                            query: {
                                keyLearnDirectoryId: this.keyLearnDirectoryId,
                                score: res.data.data.score,
                                vulnerabilityAnalysis: res.data.data.vulnerabilityAnalysis
                            },
                        });
                    } else {
                        this.$message.error("提交失败!");
                    }
                })
                .catch(() => {});
        },
    },
};
</script>
<style lang="less" scoped>
.body {
    min-height: 80vh;
    position: relative;
}

.header {
    width: 100%;
    height: 15vh;
    background-color: rgb(10, 32, 97);
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .spanitem {
        color: #fff;
        font-size: 25px;
        padding: 5px 0;
    }

    .spanitem_lable {
        color: #fff;
        font-size: 15px;
    }
}

.conter {
    background-color: rgb(10, 32, 97);
    width: 100%;
    height: 85vh;

    .conteritme {
        margin-left: 5%;

        .conteritme_span1 {
            font-size: 30px;
            line-height: 10svh;
            float: left;
        }

        .conteritme_span2 {
            font-size: 20px;
            float: right;
        }
    }

    .logindiv {
        width: 100%;
        min-width: 300px;
        height: 77.3vh;
        background-color: #d2dffe;
        box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        top: 5%;

        .swiper {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .right {
                width: 100%;
                min-height: 400px;
                max-height: 500px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                .right_P {
                    font-size: 20px;
                    font-weight: 700;
                }

                .showdiv {
                    box-sizing: border-box;
                    padding: 30px;
                    width: 80%;
                    max-height: 450px;
                    background-color: #fff;
                }
            }

            .btnClass {
                width: 100px;
                height: 40px;
                background-color: #0a1f62;
                cursor: pointer;
                text-align: center;
                line-height: 40px;
                color: #fff;
                border-radius: 5px;
            }
        }
    }
}

.cell-text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.conteritmes {
    float: right;
    margin-right: 45%;
    .conteritme_span1 {
        float: right;
        font-size: 20px;
        color: #fff;
    }
    .conteritme_span2 {
        float: right;
        color: #fff;
    }
}
</style>