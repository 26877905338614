<template>
    <div>
        <el-dialog :title="dialogType == 'add' ? '添加词汇列表信息' : '修改词汇列表信息'" :visible.sync="dialogFormVisible"
            width="500px" :before-close="handleClose">
            <el-form :model="vocabularyform" label-width="120px" ref="vocabularyform" :rules="rules">
                <el-form-item label="词汇原文：" prop="originalText">
                    <el-input v-model="vocabularyform.originalText" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="词汇译文：" prop="translatedText">
                    <el-input v-model="vocabularyform.translatedText" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="词汇定义：" prop="definition">
                    <el-input v-model="vocabularyform.definition" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="confirm">{{ dialogType == 'add' ? '确认添加' : '确认修改' }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            vocabularyform: {
                originalText: '',
                translatedText: '',
                definition: ''
            },
            dialogFormVisible: false,
            rules: {
                originalText: [
                    { required: true, message: '请输入词汇原文', trigger: 'blur' }
                ],
                translatedText: [
                    { required: true, message: '请输入词汇译文', trigger: 'blur' }
                ],
                definition: [
                    { required: true, message: '请输入词汇定义', trigger: 'blur' }
                ]
            },
            dialogType: '',
            chapterId: ''
        }
    },
    methods: {
        confirm() {
            this.$refs.vocabularyform.validate((valid) => {
                if (valid) {
                    this.$emit('pushVocabularyform')
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
            
        },
        handleClose() {
            this.dialogFormVisible = false
            this.$refs.vocabularyform.resetFields();
        }
    }
}
</script>

<style lang="less" scoped>
.uploaddiv {
    width: 120px;
    height: 120px;
    border: 1px solid #eee;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar {
    max-height: 100px;
    max-width: 100px;
}
</style>