<template>
    <el-dialog title="笔记详情" :visible.sync="dialogFormVisible" width="50%" :before-close="handleClose" center>
        <el-form :model="refinedNoteForm" label-width="100px" ref="refinedNote">
            <el-form-item label="笔记标题：" prop="noteTitle">
                <el-input v-model="refinedNoteForm.noteTitle"></el-input>
            </el-form-item>
            <el-form-item label="笔记类型：">
                <el-radio-group v-model="refinedNoteForm.noteType" @change="radioChange()">
                    <el-radio :label="0">笔记</el-radio>
                    <el-radio :label="1">小测</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="笔记顺序：" prop="noteOrder">
                <el-input-number v-model="refinedNoteForm.noteOrder" :min="1" :max="100" :step="1" label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item label="笔记内容：" prop="noteContentJson">
                <div class="noteContentJson-div">
                    <div class="noteContentJson-item" v-if="refinedNoteForm.noteContentJson.length < maxTitleNum">
                        <div style="width: 100%;display: flex;justify-content: center;align-items: center; cursor: pointer;"
                            @click="addNoteContentJson('add')">Add</div>
                    </div>
                    <div class="noteContentJson-item" v-for="(item, index) in refinedNoteForm.noteContentJson"
                        :key="index">
                        <div>{{ item.title }}</div>
                        <div class="btndiv">
                            <el-button size="mini" icon="el-icon-edit"
                                @click="addNoteContentJson('updata', item)"></el-button>
                            <el-button size="mini" icon="el-icon-delete"
                                @click="deleteNoteContentJson(item)"></el-button>
                        </div>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
        <wangeditor ref="wangeditor" @submission="pushnoteContentJson" />
    </el-dialog>
</template>

<script>
import wangeditor from '../../components/wangeditor.vue'
export default {
    data() {
        return {
            dialogFormVisible: false,
            refinedNoteForm: {
                noteTitle: '',
                noteType: 0,
                noteContentJson: [],
                noteOrder: 1
            },
            DialogType: 'add',
            maxTitleNum: 1
        };
    },
    components: {
        wangeditor
    },
    methods: {
        submit() {
            this.$emit('pushRefinedNoteForm')
        },
        // 关闭弹框时清空数据
        handleClose() {
            this.dialogFormVisible = false
            this.$refs.refinedNote.resetFields();
        },
        // 监听切换笔记类型
        radioChange() {
            this.refinedNoteForm.noteContentJson = []
            this.refinedNoteForm.noteType == '0' && (this.maxTitleNum = 1)
            this.refinedNoteForm.noteType == '1' && (this.maxTitleNum = 10)
        },
        // 打开富文本弹框
        addNoteContentJson(wangeditorDialogType, item = {}) {
            this.$refs.wangeditor.wangeditorDialogType = wangeditorDialogType
            this.$refs.wangeditor.noteType = this.refinedNoteForm.noteType
            this.$refs.wangeditor.innerVisible = true
            if (wangeditorDialogType == 'updata') {
                this.$refs.wangeditor.wangeditorForm.titleType = item.titleType
                this.$refs.wangeditor.wangeditorForm.titleContent = item.titleContent
                this.$refs.wangeditor.wangeditorForm.titleAnswer = item.titleAnswer
                this.$refs.wangeditor.wangeditorForm.title = item.title
            }
        },
        pushnoteContentJson() {
            const noteContentJsonItem = {
                titleId: '',//题目ID
                titleType: '',//题目类型
                titleContent: ``,//题目内容
                titleAnswer: '',//题目答案
                title: ''
            }
            noteContentJsonItem.titleType = this.$refs.wangeditor.wangeditorForm.titleType
            noteContentJsonItem.titleContent = this.$refs.wangeditor.wangeditorForm.titleContent
            noteContentJsonItem.titleAnswer = this.$refs.wangeditor.wangeditorForm.titleAnswer
            this.$refs.wangeditor.wangeditorForm.titleType = 0
            this.$refs.wangeditor.wangeditorForm.titleContent = ``
            this.$refs.wangeditor.wangeditorForm.titleAnswer = [{ content: '' }]
            if (this.$refs.wangeditor.wangeditorDialogType == 'add') {
                if (this.$refs.wangeditor.noteType == '0') {
                    noteContentJsonItem.title = '笔记'
                } else {
                    noteContentJsonItem.title = `题目${this.refinedNoteForm.noteContentJson.length + 1}`
                }
                this.refinedNoteForm.noteContentJson.push(noteContentJsonItem)
            } else {
                noteContentJsonItem.title = this.$refs.wangeditor.wangeditorForm.title
                var xiabiao = 0
                this.refinedNoteForm.noteContentJson.forEach((item, index) => {
                    if (item.title == noteContentJsonItem.title) {
                        xiabiao = index
                        return
                    }
                })
                this.refinedNoteForm.noteContentJson[xiabiao].titleType = noteContentJsonItem.titleType
                this.refinedNoteForm.noteContentJson[xiabiao].titleContent = noteContentJsonItem.titleContent
                this.refinedNoteForm.noteContentJson[xiabiao].titleAnswer = noteContentJsonItem.titleAnswer
            }

            this.$refs.wangeditor.innerVisible = false
        },
        deleteNoteContentJson(noteItem) {
            var notenum = undefined
            this.refinedNoteForm.noteContentJson.forEach((item, index) => {
                if (noteItem.title == item.title) {
                    notenum = index
                }
            })
            this.refinedNoteForm.noteContentJson.splice(notenum, 1)
        }
    },
    mounted() {
    }
}
</script>
<style lang="less" scoped>
.noteContentJson-div {
    min-height: 100px;

    .noteContentJson-item {
        border: 1px solid #ccc;
        border-radius: 5px;
        display: flex;
        align-content: center;
        justify-content: space-between;
        padding: 0 10px;
        margin: 10px 0;

        .btndiv {
            margin-left: 10px;
        }
    }
}
</style>