<template>
    <div class="body">
        <div class="header">
            <div class="">
            <img src="@/utils/img/logo.png" @click="Returns()"  style="width: 150px;height: 66px;">
            </div>
            <div>
                <div class="conteritmes">
                    <span class="conteritme_span1">{{ userData.account }}</span><br />
                    <span class="conteritme_span2">{{ userData.id }}</span>
                </div>
            </div>
        </div>
        <div class="conter">
            <div class="conteritme">
                <span style=" font-size: 25px;color: #fff;">{{ chapterInfoPageData.chapterName }}</span><br>
            </div>
            <div class="logindiv">
                <div class="swiper">
                    <div class="right">
                        <div id="app">
                            <i class="el-icon-arrow-left" @click="chose"></i>
                            <el-button class="content1" @click="toggleContent('content1')">Mixed</el-button>
                            <el-button class="content1" @click="toggleContent('content2')">Topical</el-button>
                        </div>
                    </div>
                    <div class="coursediv" v-if="activeContent === 'content1'">

                        <div class="subjectImgUrl" @click="tourl()">
                            <img :src="'https://genztutoringnlabs.com/service/' +  this.user.subjectImgUrl" alt="" style="max-height: 230px; max-width: 300px;">
                        </div>
                        <div class="subjectName">
                            <span style="padding: 10px 0;">Mixed</span>

                        </div>
                    </div>

                    <div v-else-if="activeContent === 'content2'" class="content1_box">
                        <div class="box1">

                            <div v-for="(item, index) in users" :key="index.keyLearnDirectoryId" class="box" style="overflow: hidden;">
                                <el-button @click="check(item.keyLearnDirectoryId,item.keyLearnDirectoryName)" class="elbutton">{{ item.keyLearnDirectoryName }}
                                </el-button>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
  <script>
import { getSubjectImgPath } from "@/views/Userside/Undirectory/Vocabularies/api/index.js";
import { queryKeyLearnDirectory } from "@/views/Userside/Undirectory/Undirectory/api/index.js";
export default {
    data() {
        return {
            userData: "",
            chapterInfoPageData: "",
            chapterId: "",
            chapterName: "",
            user: [],
            users: [],
            activeContent: "content1",
        };
    },
    mounted() {
        this.getinfo();
        this.getinforsider();
        // 在组件挂载后获取本地存储的数据
        const user = localStorage.getItem("user");
        this.userData = JSON.parse(user);
        const chapterInfo = localStorage.getItem("chapterInfoPageData");
        this.chapterInfoPageData = JSON.parse(chapterInfo);
    },
    methods: {
        async getinfo() {
            const user = localStorage.getItem("user");
            this.userData = JSON.parse(user);
            const chapterIds = localStorage.getItem("chapterId");
            this.chapterId = JSON.parse(chapterIds);
            const subjectIds = localStorage.getItem("subjectId");
            this.subjectId = JSON.parse(subjectIds);
            const num = "subjectId=" + this.subjectId;
            getSubjectImgPath(num).then((res) => {
                this.user = res.data.data;
            });
        },
        async getinforsider() {
            const chapterIds = localStorage.getItem("chapterId");
            this.chapterId = JSON.parse(chapterIds);
            const num = "chapterId=" + this.chapterId;
            queryKeyLearnDirectory(num).then((res) => {
                this.users = res.data.data;
            });
        },
        chose() {
            this.$router.push("/Directory");
        },
        toggleContent(content) {
            this.activeContent = content;
        },
        Returns() {
            this.$router.push("/studentside");
        }, //返回
        tourl() {
            localStorage.setItem("chapterId", this.chapterId);
            this.$router.push({
                path: "/Aftindex",
                query: {
                    chapterId: this.chapterId,
                },
            });

        },
        check(keyLearnDirectoryId,keyLearnDirectoryName) {
  
            localStorage.setItem("keyLearnDirectoryId", keyLearnDirectoryId);
            localStorage.setItem("keyLearnDirectoryName", keyLearnDirectoryName);
            this.$router.push({
                name: "Aftermode",
                params: { keyLearnDirectoryId,keyLearnDirectoryName },
            });
        },
    },
};
</script>
  <style lang="less" scoped>
.body {
    min-height: 80vh;
    position: relative;
}
.header {
    width: 100%;
    height: 15vh;
    background-color: rgb(10, 32, 97);
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    .spanitem {
        color: #fff;
        font-size: 25px;
        padding: 5px 0;
    }
    .spanitem_lable {
        color: #fff;
        font-size: 15px;
    }
}
.conter {
    background-color: rgb(10, 32, 97);
    width: 100%;
    height: 85vh;
    .conteritme {
        margin-left: 5%;

        .conteritme_span1 {
            font-size: 30px;
            line-height: 10svh;
            float: left;
        }
        .conteritme_span2 {
            font-size: 20px;
            float: right;
        }
    }
    .logindiv {
        width: 100%;
        min-width: 300px;
        height: 90%;
        background-color: #fff;
        box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
        position: relative;
        top: 5%;
        .swiper {
            height: 100%;
            display: flex;
            .right {
                border: 1xp solid red;
                height: 100%;
            }
        }
    }
}
.cell-text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#app {
    width: 500px;
    position: relative;
    top: 5%;
    left: 15%;
    padding: 0 15%;
}
.el-icon-arrow-left {
    right: 5%;
    padding: 0 5%;
}
.content1:active {
    background-color: rgb(10, 32, 97);
}
.content1_box {
    position: relative;
    right: 15%;
    top: 13%;
    height: 400px;
    width: 1000px;
}
.content1_box_img {
    height: 80%;
    width: 50%;
    padding: 3% 25%;
}

.coursediv {
    position: relative;
    right: 10%;
    top: 5%;
    width: 650px;
    height: 350px;
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 100px 0px;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    .subjectName {
        border-bottom: 1px solid #eee;
        display: flex;
        justify-content: center;
    }

    .subjectImgUrl {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5% 0;
    }

    .subjectImgUrl:hover {
        cursor: pointer;
    }
}

.coursediv:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.box1 {
    height: 80%;
    width: 50%;
    padding: 3% 25%;
    overflow-y: auto; /* 启用垂直滚动条，但会隐藏滚动条本身 */
}

/* 隐藏滚动条，但保持滚动功能 */
.box1::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.elbutton {
    margin: 3% 0%;
    background-color: rgb(211, 233, 254);
    color: black;
    width: 100%;
    height: 50px;
}
.elbutton:hover {
    background-color: rgb(10, 32, 97);
    color: #fff;
}
.conteritmes {
    float: right;
    margin-right: 45%;
    .conteritme_span1 {
        float: right;
        font-size: 20px;
        color: #fff;
    }
    .conteritme_span2 {
        float: right;
        color: #fff;
    }
}
</style>
  