<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script >
export default {
  created() {
    localStorage.setItem('falg', false)
  }
}
</script>

<style lang="less">
body {
  margin: 0px;
  padding: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-scrollbar .el-scrollbar__wrap {
 overflow-x: hidden;
}

// 上面隐藏横向滚动条会导致下拉框底部遮盖问题
.el-select-dropdown .el-scrollbar {
 padding-bottom: 17px;
}
</style>
