/**
 * 描述：国密(SM2)加解密工具类
 * 创建人：十一
 * 创建时间：2024/04/05 13:46:02
 */
import smCrypto from 'sm-crypto'

const { sm2 } = smCrypto
// TODO:这三个变量需要移动到配置文件，不能采用硬编码的形式
const cipherMode = 0 // 1 - C1C3C2，0 - C1C2C3，默认为1
const publicKey = '0403843F404D345E141B093C0E06737ED79F87B23FCC00541B616CAADD9E6626F324C7C60F38EFF09E320E19ED0072C120D4C6BEFCF182783EB339C39DE0D1A492'
const privateKey = '00CC67DC3D30CF4D89D541F754988D432C787C8A48A2AB85F2279313A022980450'

export default {
  /**
   * @description SM2加密
   * @returns {string} 密文
   * @author 十一
   * @date 2024/4/5 14:33
   */
  sm2Encrypt (msgString) {
    return sm2.doEncrypt(msgString, publicKey, cipherMode)
  },

  /**
   * @description SM2解密
   * @returns {string} 明文
   * @author 十一
   * @date 2024/4/5 14:34
   */
  sm2Decrypt (msgString) {
    return sm2.doDecrypt(msgString, privateKey, cipherMode)
  }
}
