import service from "@/utils/request/http";

// 获取课程信息
export function getCourseinfo(params){
    return service({
        url:'/subject/queryPagingSubject',
        method:'get',
        params
    })
}

// 添加课程
export function addCourseinfo(data){
    return service({
        url:'/subject/addSubject',
        method:'post',
        data
    })
}

// 修改课程
export function updataCourseinfo(data){
    return service({
        url:'/subject/updateSubject',
        method:'post',
        data
    })
}

// 删除课程
export function delCourseinfo(params){
    return service({
        url:'/subject/delectSubject',
        method:'get',
        params
    })
}

// 复制课程
export function copyCourseinfo(params){
    return service({
        url:'/subject/copySubject',
        method:'get',
        params
    })
}

// 分配课程权限
export function assignCourses(data){
    return service({
        url:'/subject/assignCourses',
        method:'post',
        data
    })
}

//通过课程ID获取学生形象
export function getStudentIdsBySubjectId(params){
    return service({
        url:'/userManage/getStudentIdsBySubjectId',
        method:'get',
        params
    })
}

//获取全部学生信息
export function getStudentinfo(params){
    return service({
        url:'/userManage/queryPagingStudent',
        method:'get',
        params
    })
}
