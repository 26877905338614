import serve from '@/utils/request/http.js'

export function Glossary(params) {
    return serve({
        url: '/vocabulary/frontEndQueryVocabulariesByChapterId?' + params,
        method: 'get',
    })

}
//点击修改三色
export function selfAssessment(data) {
    return serve({
        url: '/vocabulary/selfAssessment',
        method: 'post',
        data
    })

}
