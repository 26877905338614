<template>
    <div>
        <el-dialog :title="dialogType == 'add' ? '添加学生信息' : '修改学生信息'" :visible.sync="dialogFormVisible" width="400px"
            :before-close="handleClose">
            <el-form :model="studentform" label-width="100px" ref="studentform" :rules="rules">
                <el-form-item label="学生名称：" prop="nickName">
                    <el-input v-model="studentform.nickName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="学生性别：">
                    <el-radio-group v-model="studentform.userSex">
                        <el-radio :label=1>男</el-radio>
                        <el-radio :label=0>女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="账号名称：" prop="account">
                    <el-input v-model="studentform.account" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="confirm">{{ dialogType == 'add' ? '确认添加' : '确认修改' }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            studentform: {
                nickName: '',
                userSex: 1,
                account: ''
            },
            dialogFormVisible: false,
            dialogType: '',
            rules: {
                nickName: [
                    { required: true, message: '请输入学生名称', trigger: 'blur' }
                ],
                account: [
                    { required: true, message: '请输入账号名称', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        confirm() {
            this.$refs.studentform.validate((valid) => {
                if (valid) {
                    this.$emit('confirmStudent')
                } else {
                    return false;
                }
            });
        },
        handleClose() {
            this.$refs.studentform.resetFields();
            this.dialogFormVisible = false
        }
    }
}
</script>

<style></style>