<template>
    <el-dialog :title="DialogType == 'add' ? '添加主题' : '修改主题'" :visible.sync="dialogFormVisible" :rules="rules"
        width="30%" :before-close="handleClose">
        <el-form :model="LearnDirectoryInfo" ref="themeInfo" :rules="rules">
            <el-form-item label="主题名称：" label-width="100px" prop="directoryName">
                <el-input v-model="LearnDirectoryInfo.directoryName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="主题顺序：" label-width="100px" prop="directoryOrder">
                <el-input-number v-model="LearnDirectoryInfo.directoryOrder" :min="1" :max="100"
                    label="描述文字"></el-input-number>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="submitInfo">{{ DialogType == 'add' ? '确认添加' : '确认修改'
                }}</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            DialogType: 'add',
            LearnDirectoryInfo: {
                directoryName: '',
                directoryOrder: 1
            },
            dialogFormVisible: false,
            rules: {
                directoryName: [
                    { required: true, message: '请输入主题名称', trigger: 'blur' }
                ],
                directoryOrder: [
                    { required: true, message: '请输入主题顺序', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        submitInfo() {
            this.$refs.themeInfo.validate((valid) => {
                if (valid) {
                    this.$emit('submitInfo')
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        handleClose() {
            this.dialogFormVisible = false
            this.$refs.themeInfo.resetFields();
        }
    }
}
</script>

<style></style>