<template>
  <div class="body">
    <div class="header">
      <div class="">
        <img
          src="@/utils/img/logo.png"
          @click="Returns()"
          style="width: 150px; height: 66px"
        />
      </div>
      <div>
        <div class="conteritmes">
          <span class="conteritme_span1">{{ userData.account }}</span
          ><br />
          <span class="conteritme_span2">{{ userData.id }}</span>
        </div>
      </div>
    </div>
    <div class="conter">
      <div class="conteritme">
        <span style="font-size: 25px; color: #fff">{{
          chapterInfoPageData.chapterName
        }}</span
        ><br />
      </div>
      <div class="logindiv">
        <div class="swiper">
          <div class="right">
            <div class="feedbackdiv">
              <div class="feedback">
                <div class="qualifieddiv">
                  <p style="margin: 0px">80% Pass</p>
                  <img
                    src="@/utils/img/tupiao(3).jpg"
                    alt=""
                    style="width: 150px; height: 20px"
                  />
                </div>
                <div class="progress_p">
                  <h1 style="margin-bottom: 10px">{{ score + "%" }}</h1>
                  <img src="@/utils/img/cross.png" alt="" v-if="score < 80" />
                  <img src="@/utils/img/scuess.jpg" alt="" v-if="score >= 80" />
                </div>
                <el-progress
                  type="circle"
                  :percentage="score"
                  :width="200"
                  color="#132968"
                  :show-text="false"
                  define-back-color="#fff"
                ></el-progress>
              </div>
              <div style="color: #e7a7b6; margin-top: 10px; font-size: 18px;">{{ vulnerabilityAnalysis }}</div>
            </div>
            <div class="titlediv">
              <div
                v-for="(item, index) in showdivList"
                :key="index"
                class="titleitem"
              >
                <div style="width: 500px; padding: 40px 0">
                  <h2>{{ htmlList[index].title }}</h2>
                  <div :ref="`showdiv${index}`" v-html="item"></div>
                  <div style="display: flex; justify-content: right">
                    <div style="display: flex; align-items: center">
                      <span
                        class="isshwospan"
                        v-if="!(htmlList[index].colorOfJudgment == 0)"
                        @click="showAnswer(index)"
                        >Correct answer</span
                      >
                      <img
                        src="@/utils/img/colorOfJudgment1.png"
                        v-if="htmlList[index].colorOfJudgment == 0"
                      />
                      <img
                        src="@/utils/img/colorOfJudgment2.png"
                        v-if="htmlList[index].colorOfJudgment == 1"
                      />
                      <img
                        src="@/utils/img/colorOfJudgment3.png"
                        v-if="htmlList[index].colorOfJudgment == 2"
                      />
                    </div>
                  </div>
                  <div v-if="htmlList[index].colorOfJudgment != 0">
                    <div v-if="showIndex == index">
                      <div v-if="htmlList[index].titleType != 2">
                        {{
                          "Correct answer:" +
                          htmlList[index].correctAnswerJson[0].content
                        }}
                      </div>
                      <div
                        v-if="htmlList[index].titleType == 2"
                        style="display: flex"
                      >
                        <div>Correct answer:</div>
                        <div
                          v-for="(item, index) in htmlList[index]
                            .correctAnswerJson"
                          :key="index"
                          style="margin-right: 10px"
                        >
                          {{ item.content }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="height: 100px; width: 100%; background-color: #d2dffe"
              >
                <el-button
                  type="primary"
                  @click="chose()"
                  style="
                    margin-left: 47%;
                    margin-top: 2%;
                    background-color: rgb(10, 32, 97);
                  "
                  >Back</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTestPaperCallbackInfo } from "./api/index.js";
export default {
  data() {
    return {
      userData: "",
      chapterInfoPageData: "",
      chapterId: "",
      chapterName: "",
      keyLearnDirectoryId: "",
      htmlList: [],
      htmldata: "",
      indexNum: -1,
      AnswerList: [],
      score: 0,
      showdivList: [],
      showIndex: -1,
      vulnerabilityAnalysis: "",
    };
  },
  created() {
    this.keyLearnDirectoryId = this.$route.query.keyLearnDirectoryId
    this.score = this.$route.query.score * 1
    this.vulnerabilityAnalysis = this.$route.query.vulnerabilityAnalysis
    this.userData = JSON.parse(localStorage.getItem("user"))
    this.chapterInfoPageData = JSON.parse(
      localStorage.getItem("chapterInfoPageData")
    );
    this.getTestPaperCallback();
  },
  methods: {
    async getTestPaperCallback() {
      const res = await getTestPaperCallbackInfo({
        studentId: this.userData.id,
        keyLearnDirectoryId: this.keyLearnDirectoryId,
      });
      if (res.data.statusCode == 200) {
        this.htmlList = res.data.data;
        // this.htmlList = this.htmlList.sort((a, b) => a.titleOrder - b.titleOrder)
        this.htmlList.map((item, index) => {
          this.indexNum = index;
          this.openDialog();
          this.showdivList.push(this.htmldata);
        });
      }
    },
    setRadio(questionStr, indexs) {
      // 存放数组
      var replacements = [];

      const xuan = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

      var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
      // 声明默认字体大小
      var fontSize = 29;

      // 检查是否匹配成功并输出结果
      if (fontSizeMatch) {
        fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
      }
      const matches = questionStr.match(/\$radio/g);
      const count = matches ? matches.length : 0;
      for (let index = 0; index <= count; index++) {
        replacements.push(
          `<input type="radio" class='qwe' name="asd${indexs}" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
        );
      }

      // 当前替换索引
      let currentIndex = 0;

      // 使用replace方法和正则表达式来逐个替换$checkbox
      const replacedStr = questionStr.replace(/\$radio/g, (match) => {
        // 获取当前匹配项的替换值
        const replacement = replacements[currentIndex];
        // 更新索引以便下次替换
        currentIndex++;
        // 返回替换值
        return replacement;
      });
      return replacedStr;
    },
    // 将$checkbox变为多选框
    setCheckbox(questionStr) {
      // 替换顺序数组
      const replacements = [];

      const xuan = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

      // 当前替换索引
      let currentIndex = 0;

      var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
      // 声明默认字体大小
      var fontSize = 29;

      // 检查是否匹配成功并输出结果
      if (fontSizeMatch) {
        fontSize = fontSizeMatch[1].slice(0, 2) * 0.86 + "px";
      }

      const matches = questionStr.match(/\$checkbox/g);
      const count = matches ? matches.length : 0;
      for (let index = 0; index <= count; index++) {
        replacements.push(
          `<input type="checkbox" class='qwe' name="asd" value='${xuan[index]}' style="width:${fontSize}; height:${fontSize}">`
        );
      }

      // 使用replace方法和正则表达式来逐个替换$checkbox
      const replacedStr = questionStr.replace(/\$checkbox/g, (match) => {
        // 获取当前匹配项的替换值
        const replacement = replacements[currentIndex];
        // 更新索引以便下次替换
        currentIndex++;
        // 返回替换值
        return replacement;
      });
      return replacedStr;
    },
    // 将$Input变为填空框
    setInput(questionStr) {
      // 替换顺序数组
      const replacements = [];

      // 当前替换索引
      let currentIndex = 0;

      var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
      // 声明默认字体大小
      var fontSize = 29;

      // 检查是否匹配成功并输出结果
      if (fontSizeMatch) {
        fontSize = fontSizeMatch[1];
      }

      const matches = questionStr.match(/\$Input/g);

      const count = matches ? matches.length : 0;
      for (let index = 0; index <= count; index++) {
        replacements.push(
          `<input type="text" id="qwe${index}" class="qwe" style="text-align: center; border: 0px solid #fff; border-bottom: 1px solid #ccc;height:${fontSize}; width:${
            fontSize * 4
          }; font-size:${fontSize}; margin: 0 15px;"/>`
        );
      }

      // 使用replace方法和正则表达式来逐个替换$checkbox
      const replacedStr = questionStr.replace(/\$Input/g, (match) => {
        // 获取当前匹配项的替换值
        const replacement = replacements[currentIndex];
        // 更新索引以便下次替换
        currentIndex++;
        // 返回替换值
        return replacement;
      });
      return replacedStr;
    },
    // 将$Textarea变为问答框
    setTextarea(questionStr) {
      // 替换顺序数组
      const replacements = [];

      // 当前替换索引
      let currentIndex = 0;

      var fontSizeMatch = questionStr.match(/font-size:\s*([\d.]+px)/);
      // 声明默认字体大小
      var fontSize = 29;

      // 检查是否匹配成功并输出结果
      if (fontSizeMatch) {
        fontSize = fontSizeMatch[1];
      }

      const matches = questionStr.match(/\$Textarea/g);

      const count = matches ? matches.length : 0;
      for (let index = 0; index <= count; index++) {
        replacements.push(
          `<textarea cols="3" id="qwe${index}" class="qwe" rows="3" style="max-width:600px; min-width:300px; font-size:${fontSize}"></textarea>`
        );
      }

      // 使用replace方法和正则表达式来逐个替换$checkbox
      const replacedStr = questionStr.replace(/\$Textarea/g, (match) => {
        // 获取当前匹配项的替换值
        const replacement = replacements[currentIndex];
        // 更新索引以便下次替换
        currentIndex++;
        // 返回替换值
        return replacement;
      });
      return replacedStr;
    },
    // 修改题目
    openDialog() {
      switch (this.htmlList[this.indexNum].titleType) {
        //单选题
        case 0:
          this.htmldata = this.setRadio(
            this.htmlList[this.indexNum].titleContentJson,
            this.indexNum
          );
          break;
        //多选题
        case 1:
          this.htmldata = this.setCheckbox(
            this.htmlList[this.indexNum].titleContentJson
          );
          break;
        //填空题
        case 2:
          this.htmldata = this.setInput(
            this.htmlList[this.indexNum].titleContentJson
          );
          break;
        //问答题
        case 3:
          this.htmldata = this.setTextarea(
            this.htmlList[this.indexNum].titleContentJson
          );
          break;
        default:
          this.$message.error("该题型未得到应用");
      }
    },
    // 为单选框绑定事件
    getRadioAnswer(index) {
      var radios = this.$refs[`showdiv${index}`][0].querySelectorAll(".qwe");
      radios.forEach((item) => {
        if (item.value == this.htmlList[index].selectedAnswer[0].content) {
          console.log(index, item);
          item.checked = true;
          console.log(item.checked);
        }
      });
    },
    // 为复选框绑定事件
    getCheckboxAnswer(index) {
      var radios = this.$refs[`showdiv${index}`][0].querySelectorAll(".qwe");
      var answerlist =
        this.htmlList[index].selectedAnswer[0].content.split(",");
      radios.forEach((item) => {
        answerlist.forEach((answeritem) => {
          answeritem == item.value && (item.checked = true);
        });
      });
    },
    // 为填空框绑定事件
    getInputAnswer(index) {
      var inputs = this.$refs[`showdiv${index}`][0].querySelectorAll(".qwe");
      inputs.forEach((inputItem, i) => {
        inputItem.value = this.htmlList[index].selectedAnswer[i].content;
      });
    },
    // 为问答框绑定事件
    getTextareaAnswer(index) {
      var inputs = this.$refs[`showdiv${index}`][0].querySelectorAll(".qwe");
      inputs.forEach((inputItem) => {
        inputItem.value = this.htmlList[index].selectedAnswer[0].content;
      });
    },
    setanswer(index) {
      switch (this.htmlList[index].titleType) {
        //单选题
        case 0:
          this.$nextTick(this.getRadioAnswer(index));
          break;
        //多选题
        case 1:
          this.$nextTick(this.getCheckboxAnswer(index));
          break;
        //填空题
        case 2:
          this.$nextTick(this.getInputAnswer(index));
          break;
        //问答题
        case 3:
          this.$nextTick(this.getTextareaAnswer(index));
          break;
        default:
          this.$message.error("该题型未得到应用");
      }
    },
    // 显示答案
    showAnswer(index) {
      this.showIndex = index;
    },
    Returns() {
      this.$router.push("/studentside");
    },
    chose() {
      this.$router.push("/Undirectory");
    },
  },
  updated() {
    this.htmlList.forEach((item, index) => {
      this.setanswer(index);
    });
  },
};
</script>

<style lang="less" scoped>
.body {
  min-height: 80vh;
  position: relative;
}

.header {
  width: 100%;
  height: 15vh;
  background-color: rgb(10, 32, 97);
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .spanitem {
    color: #fff;
    font-size: 25px;
    padding: 5px 0;
  }

  .spanitem_lable {
    color: #fff;
    font-size: 15px;
  }
}

.conter {
  background-color: rgb(10, 32, 97);
  width: 100%;
  height: 85vh;

  .conteritme {
    margin-left: 5%;

    .conteritme_span1 {
      font-size: 30px;
      line-height: 10svh;
      float: left;
    }

    .conteritme_span2 {
      font-size: 20px;
      float: right;
    }
  }

  .logindiv {
    width: 100%;
    min-width: 300px;
    height: 75%;
    background-color: #fff;
    box-shadow: 4px 0px 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    top: 5%;

    .swiper {
      height: 100%;
      display: flex;
      justify-content: center;
      background-color: #d2dffe;

      .right {
        width: 100%;
        background-color: #d2dffe;

        .feedbackdiv {
          width: 100%;
          height: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .feedback {
            width: 200px;
            position: relative;

            .qualifieddiv {
              position: absolute;
              top: 30px;
              left: -141px;
            }

            .progress_p {
              position: absolute;
              top: 15px;
              left: 60px;
              text-align: center;

              img {
                width: 80px;
                height: 80px;
              }
            }
          }
        }

        .titlediv {
          width: 100%;
          min-height: 440px;
          background-color: #fff;
          border-top: 1px solid #afb0b5;

          .titleitem {
            min-height: 200px;
            border: 1px solid #afb0b5;
            border-top: 0px;
            display: flex;
            justify-content: center;

            .isshwospan {
              font-size: 16px;
              color: #cdcdcd;
              cursor: pointer;
              margin-right: 10px;
            }

            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.cell-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.conteritmes {
  float: right;
  margin-right: 45%;
  .conteritme_span1 {
    float: right;
    font-size: 20px;
    color: #fff;
  }
  .conteritme_span2 {
    float: right;
    color: #fff;
  }
}
</style>