<template>
    <el-dialog title="水平测试成绩" :visible.sync="dialogTableVisible">
        <el-table :data="gradeInfo" border>
            <el-table-column property="studentAccount" label="学生账号"></el-table-column>
            <el-table-column property="studentName" label="学生名称"></el-table-column>
            <el-table-column property="highestAttemptedScore" label="最高得分" sortable>
                <template slot-scope="scope">
                    <el-tag type="success" effect="dark" v-if="scope.row.highestAttemptedScore >= 80.0 ">{{ scope.row.highestAttemptedScore }}</el-tag>
                    <el-tag type="warning" effect="dark" v-if="scope.row.highestAttemptedScore >= 40.0 && scope.row.highestAttemptedScore < 80.0">{{ scope.row.highestAttemptedScore }}</el-tag>
                    <el-tag type="danger" effect="dark" v-if="scope.row.highestAttemptedScore < 40.0">{{ scope.row.highestAttemptedScore }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column property="lastAttemptedScore" label="最近得分" sortable>
                <template slot-scope="scope">
                    <el-tag type="success" effect="dark" v-if="scope.row.lastAttemptedScore >= 80.0 ">{{ scope.row.lastAttemptedScore }}</el-tag>
                    <el-tag type="warning" effect="dark" v-if="scope.row.lastAttemptedScore >= 40.0 && scope.row.lastAttemptedScore < 80.0">{{ scope.row.lastAttemptedScore }}</el-tag>
                    <el-tag type="danger" effect="dark" v-if="scope.row.lastAttemptedScore < 40.0">{{ scope.row.lastAttemptedScore }}</el-tag>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            gradeInfo: [],
            dialogTableVisible: false
        }
    },
    methods: {

    }
}
</script>

<style></style>