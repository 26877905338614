import service from "@/utils/request/http";

// 获取老师信息
export function selectTeacherInfo(params) {
    return service({
        url: '/teacherManage/selectTeacher',
        method: 'get',
        params
    })
}

// 添加老师信息
export function addVocabularyInfo(data) {
    return service({
        url: '/teacherManage/addVocabulary',
        method: 'post',
        data
    })
}


// 修改老师信息
export function updateTeacherInfo(data) {
    return service({
        url: '/teacherManage/updateTeacher',
        method: 'post',
        data
    })
}


// 删除老师信息
export function deleteTeacherInfo(params) {
    return service({
        url: '/teacherManage/deleteTeacher',
        method: 'get',
        params
    })
}


// 模糊查询老师信息
export function queryLikekeyTeacherInfo(params) {
    return service({
        url: '/teacherManage/queryLikekeyTeacher',
        method: 'get',
        params
    })
}
