<template>
  <div class="body">
    <div class="header">
      <div>
        <img src="@/utils/img/logo.png" @click="Returns()"  style="width: 150px;height: 66px;"> 
        <div>
         
            </div>

      </div>
      <el-dropdown @command="handleCommand" style="float: right;">
            <span class="el-dropdown-link">
              <el-avatar icon="el-icon-user-solid"></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="b">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
    </div>
    <div class="conter">
      <div class="conteritme">
        <span class="conteritme_span1">{{ userData.account }}</span
        ><br />
        <span class="conteritme_span2">{{ userData.id }}</span>
 
      </div>
      <div class="logindiv">
        <div style="height: 330px;">

  <div> <h5 style="font-size: 30px; margin: 0 5%">Learning Portal ({{ this.totalCount }})</h5>
    <el-pagination
      :total="totalCount"
      :current-page="currentPage"
      :page-size="5"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="prev,->,next "
      style="
      position: relative;
      top: 150px;
      left: 40px;
      width: 100%;
      "
      class="custom-pagination">
    </el-pagination>
  </div>

          <div
            style="
              width: 81%;
              margin-left: 8%;
              position: relative;
              bottom: 110px;
              height: 100%;
         
            "
          >
            <div
              v-for="(item, index) in subjectInfoPageData"
              :key="index.subjectId"
              class="box1"
            >
            <div style="width: 100%; height: 70%;"> 
              <img
                :src="
                  'https://genztutoringnlabs.com/service/' + item.subjectImgUrl
                "
                @click="chose(item.subjectId)"
                style="width: 100%; height: 100%; border-radius: 10px 10px 0 0"
              />
            </div>
             
              <h5>{{ item.subjectName }}</h5>
            </div>

          </div>

        </div>

        <div style="height: 350px; margin-top: 50px">
          <div
            @touchend="end"
            @touchstart="start"
            @touchmove="move"
            class="swiper"
          >
            <h5 style="font-size: 30px; margin: 0 5%">Recent Activities</h5>
            <div
              v-for="(item, index) in queryRecentChapter"
              :style="config5[index]"
              :key="index.chapterId"
              class="box"
            >
              <img
                :src="
                  'https://genztutoringnlabs.com/service/' + item.chapterImgUrl
                "
                 @click="choses(item.chapterId,item.chapterName)"
              />
              <h5 class="text">{{ item.chapterName }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
import { studentside } from "@/views/Userside/Usersidehome/api/index";
import { queryRecentChapter } from "@/views/Userside/Usersidehome/api/index";

export default {
  data() {
    return {
      user: null, // 初始化 user 数据为 null
      userData: null,
      totalCount: 0,
      currentPage: 1,
      pageSize: 5,
      count: 5,
      stuId: null,
      loading: true,
      startX: null,
      endX: null,
      subjectInfoPageData: [],
      queryRecentChapter: [],
      previous: 0,
      config5: [
        {
          id: "A",
          position: "absolute",
          width: "14%",
          height: "250px",
          left: "7%",
          opacity: 1,
          zIndex: 1,
          transition: ".4s",
        },
        {
          id: "B",
          position: "absolute",
          width: "14%",
          height: "250px",
          left: "23%",
          opacity: 1,
          zIndex: 2,
          transition: ".4s",
        },
        {
          id: "c",
          position: "absolute",
          width: "14%",
          height: "250px",
          left: "63%",
          marginLeft: "-22.5%",
          opacity: 1,
          zIndex: 4,
          transition: ".4s",
        },
        {
          id: "D",
          position: "absolute",
          width: "14%",
          height: "250px",
          left: "55%",
          opacity: 1,
          zIndex: 2,
          transition: ".4s",
        },
        {
          id: "E",
          position: "absolute",
          width: "14%",
          height: "250px",
          left: "71%",
          opacity: 1,
          zIndex: 1,
          transition: ".4s",
        },
      ],
    };
  },
  mounted() {
    // 在组件挂载后获取本地存储的数据
  },
  methods: {
    handleCommand(command) {//退出登录
      if(command == 'b'){
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        this.$router.push('/login')
      }else if(command == 'a'){
        this.updatadialogTableVisible = true;
            this.updatapaymentfrom = row;
      }
    },
    // 获取数据
    async getData() {
      const user = localStorage.getItem("user");
      this.userData = JSON.parse(user);
      const data =
        "currentPage=" +
        this.currentPage +
        "&pageSize=" +
        this.pageSize +
        "&stuId=" +
        this.userData.id;
      studentside(data).then((res) => {
        this.subjectInfoPageData = res.data.data.subjectInfoPageData;
        this.totalCount=res.data.data.pageInfo.totalCount;
      });
    },
    chose(subjectId) {
      localStorage.setItem('subjectId', subjectId);
      this.$router.push({
        name: "CourseHomePage",
        params: { subjectId: subjectId },
      });
    },
    choses(chapterId,chapterName) {
      localStorage.setItem('chapterId', chapterId);
      localStorage.setItem('chapterInfoPageData', JSON.stringify({ chapterId: chapterId, chapterName: chapterName }))
      this.$router.push({ name: 'Directory', params: {chapterId, chapterName }});
    },
    handleSizeChange(val) {
      this.pageSize = val;

      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },

    query() {
      const nums =
        "count=" + this.count + "&stuId=" + this.userData.id + "&isOrder=" + true;
      queryRecentChapter(nums).then((res) => {
        this.queryRecentChapter = res.data.data;
      });
    },    
      
    handleClose1() {
            this.updatadialogTableVisible = false;
        },
        cancel1() {
            this.updatadialogTableVisible = false;
        },
          //点击编辑弹框
   
    // 开始移动端滑动屏幕
    start(event) {
      this.startX = event.changedTouches[0].clientX;
      this.startY = event.changedTouches[0].clientY;
    },
    // 连续滑动
    move(event) {
      this.endY = event.changedTouches[0].clientY;
      this.endX = event.changedTouches[0].clientX;
      this.stopDefault(event);
      // 如果是滑动，注解（223行到231行）这段。如果是连续滑动，放开（223行到231行）注解
      this.interval = this.endX - this.startX;
      if (this.interval > 40) {
        this.startX = this.endX;
        this.prev();
      }
      if (this.interval < -40) {
        this.startX = this.endX;
        this.next();
      }
    },
    // 滑动
    end(event) {
      // 如果是滑动，放开（236行到238行）的注解。如果是连续滑动，注解（236行到238行）
      // this.endY = event.changedTouches[0].clientY;
      // this.endX = event.changedTouches[0].clientX;
      // this.formatSwiper();
    },
  },
  created() {
    this.getData();
    this.query();
  },
};
</script>

<style lang="less" scoped>
.body {
  min-height: 100vh;
  position: relative;
}

.header {
  width: 100%;
  height: 15vh;
  background-color: rgb(10, 32, 97);
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .spanitem {
    color: #fff;
    font-size: 35px;
    padding: 5px 0;
  }
  .spanitem_lable {
    color: #fff;
    font-size: 15px;
  }
}
.conter {
  background: linear-gradient(180deg, rgb(214, 226, 254), rgb(255, 255, 255));
  width: 100%;
  height: 100%;
  .conteritme {
    float: right;
    margin-right: 7%;
    padding-bottom: 4%;
    .conteritme_span1 {
      font-size: 30px;
      float: right;
      line-height: 13svh;
    }
    .conteritme_span2 {
      font-size: 20px;
      float: right;
    }
  }
  .logindiv {
    width:87%;
    min-width: 300px;
    height: 100%;
    background-color: #fff;
    box-shadow: 2px 0px 0px 0 rgba(218, 215, 215, 0.1);
    position: relative;
    margin: 0 5%;
    position: relative;
    top:200px;
    padding-top: 2%;
  }
  .swiper {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    bottom: 70px;
    .box {
      width: 20%;
      height: 180px;
      margin: 30px 20px;
      border: 1px solid rgb(96, 188, 171);
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      transition: transform 0.2s ease; /* 添加过渡效果 */
      margin-right: 50px;
      float: right; /* 设置子元素向右浮动 */
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 70%;
        border-radius: 10px 10px 0 0;
      }
      h5 {
        text-align: center;
      }
    }
    .box:hover{
      transform: scale(1.1); 
    }

    div {
      opacity: 0;
    }
    .arrow {
      border: none;
      outline: 0;
      padding: 0;
      margin: 0;
      height: 36px;
      width: 36px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
      background-color: rgba(31, 45, 61, 0.11);
      color: #140303;
      border: 1px solid red;
      position: fixed; /* 让箭头悬停在中间 */
      top: 50%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      font-size: 12px;
    }
    .el-button--info {
      background-color: rgba(31, 45, 61, 0.11);
      border: 1px solid red;
    }
    .el-button--info:hover {
      background-color: rgba(31, 45, 61, 0.23);
      border: 1px solid red;
    }

    /* 过渡动画 */
    .arrow-left-enter,
    .arrow-left-leave-active {
      transform: translateY(-50%) translateX(-10px);
      opacity: 0;
      border: 1px solid red;
    }
    .arrow-right-enter,
    .arrow-right-leave-active {
      transform: translateY(-50%) translateX(10px);
      opacity: 0;
      border: 1px solid red;
    }
  }
}
.box1 {
  width: 17%;
  height: 250px;
  margin: 30px 13px;
  border: 1px solid rgb(96, 188, 171);
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease; /* 添加过渡效果 */
  text-align: center;
  float: left; /* 设置子元素向右浮动 */
  overflow: hidden;
}
.box1:hover{
  transform: scale(1.1); 
}

    .el-pagination .el-pagination_rightwrapper {
  float: right;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.text{
  width: 100%; /* 设置容器宽度 */
      overflow: hidden; /* 超出容器部分隐藏 */
      white-space: nowrap; /* 避免文本换行 */
      text-overflow: ellipsis; /* 超出部分用省略号表示 */

      padding: 5px; /* 内边距 */
}
.text:hover{
  overflow: visible; /* 鼠标悬停时显示全部文本 */
      white-space: normal; /* 鼠标悬停时允许文本换行 */
}
</style>
