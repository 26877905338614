<template>
  <div>
    <Back class="backdiv" />
    <div style="margin-bottom: 20px; font-size: 25px;">主题管理</div>
    <div class="inquire">
      <el-input placeholder="请输入内容" v-model="inquire" class="inquireInput">
        <template slot="append">
          <el-button type="primary" icon="el-icon-search" @click="queryLikeKeyLearnByNote"></el-button>
        </template>
      </el-input>
      <el-button type="primary" @click="openDialog('add')">添加主题</el-button>
    </div>
    <el-table :data="LearnDirectoryfrom" border style="width: 100%">
      <el-table-column prop="keyLearnDirectoryId" label="主题ID" width="180">
      </el-table-column>
      <el-table-column prop="keyLearnDirectoryName" label="主题名称" width="180">
      </el-table-column>
      <el-table-column prop="directoryOrder" label="主题顺序" width="180">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="openDialog('updata', scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteInfo(scope.row)">删除</el-button>
          <el-button size="mini" type="success" @click="toUrl(scope.row, '/MangenmentPastPapers')">过往试卷</el-button>
          <el-button size="mini" type="success" @click="toUrl(scope.row, '/MangenmentLevelTest')">水平测试</el-button>
          <el-button size="mini" type="success" @click="toUrl(scope.row, '/MangenmentRefinednote')">精致笔记</el-button>
          <el-button size="mini" type="success" @click="openGradeDialog(scope.row)">查看成绩</el-button>
        </template>
      </el-table-column>
    </el-table>
    <themeInfoDialog ref="themeInfoDialog" @submitInfo="submitInfo" />
    <gradeDialog ref="gradeDialog"/>
  </div>
</template>

<script>
import Back from '../components/index.vue'
import { getKeyLearnDirectoryInfo, delKeyLearnDirectoryInfo, addKeyLearnDirectoryInfo, updataKeyLearnDirectoryInfo, queryLikeKeyLearnDirectory, getTestTeportForAllStudentInfo } from './api/index.js'
import themeInfoDialog from './components/themeInfoDialog.vue'
import gradeDialog from './components/gradeDialog.vue'
export default {
  data() {
    return {
      LearnDirectoryfrom: [],
      inquire: '',
      chapterId: ''
    }
  },
  components: {
    Back,
    themeInfoDialog,
    gradeDialog
  },
  methods: {
    toUrl(item, pathinfo) {
      this.$router.push({
        path: pathinfo,
        query: {
          keyLearnDirectoryId: item.keyLearnDirectoryId,
          chapterId: this.chapterId
        }
      })
    },
    deleteInfo(item) {
      this.$confirm(`确认删除${item.keyLearnDirectoryName}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await delKeyLearnDirectoryInfo({ keyLearnDirectoryId: item.keyLearnDirectoryId })
        if (res.data.statusCode == 200 && res.data.data) {
          this.$message.success('删除成功！')
          this.getKeyLearnDirectory()
        } else {
          this.$message.error('删除失败！')
        }
      }).catch(() => { });
    },
    async getKeyLearnDirectory() {
      const res = await getKeyLearnDirectoryInfo({ chapterId: this.chapterId })
      if (res.data.statusCode == 200) {
        this.LearnDirectoryfrom = res.data.data
      }
    },
    openDialog(DialogType, item = { directoryName: '', directoryOrder: 1, keyLearnDirectoryId: '', keyLearnDirectoryName: '' }) {
      this.$refs.themeInfoDialog.dialogFormVisible = true
      this.$refs.themeInfoDialog.DialogType = DialogType
      this.$refs.themeInfoDialog.LearnDirectoryInfo.directoryName = item.keyLearnDirectoryName
      this.$refs.themeInfoDialog.LearnDirectoryInfo.keyLearnDirectoryId = item.keyLearnDirectoryId
      this.$refs.themeInfoDialog.LearnDirectoryInfo.directoryOrder = item.directoryOrder
    },
    async submitInfo() {
      var res = ''
      var LearnDirectoryInfo = {
        chapterId: this.chapterId,
        directoryName: this.$refs.themeInfoDialog.LearnDirectoryInfo.directoryName,
        directoryOrder: this.$refs.themeInfoDialog.LearnDirectoryInfo.directoryOrder
      }
      if (this.$refs.themeInfoDialog.DialogType == 'add') {
        res = await addKeyLearnDirectoryInfo(LearnDirectoryInfo)
        if (res.data.statusCode == 200 && res.data.data) {
          this.$message.success('添加成功!')
        } else {
          this.$message.error('添加失败！')
        }
        this.$refs.themeInfoDialog.dialogFormVisible = false
        this.getKeyLearnDirectory()
      } else {
        LearnDirectoryInfo.keyLearnDirectoryId = this.$refs.themeInfoDialog.LearnDirectoryInfo.keyLearnDirectoryId
        res = await updataKeyLearnDirectoryInfo(LearnDirectoryInfo)
        if (res.data.statusCode == 200 && res.data.data) {
          this.$message.success('修改成功!')
        } else {
          this.$message.error('修改失败！')
        }
        this.$refs.themeInfoDialog.dialogFormVisible = false
        this.getKeyLearnDirectory()
      }
    },
    async queryLikeKeyLearnByNote() {
      const res = await queryLikeKeyLearnDirectory({
        chapterId: this.chapterId,
        keyLearnDirectoryName: this.inquire
      })
      if (res.data.statusCode == 200) {
        this.LearnDirectoryfrom = res.data.data
      }
    },
    async openGradeDialog(item){
      const res = await getTestTeportForAllStudentInfo({keyLearnDirectoryId: item.keyLearnDirectoryId})
      if(res.data.statusCode == 200){
        this.$refs.gradeDialog.dialogTableVisible = true
        this.$refs.gradeDialog.gradeInfo = res.data.data
      }
    }
  },
  mounted() {
    this.chapterId = this.$route.query.chapterId
    this.getKeyLearnDirectory()
  }
}
</script>

<style lang="less" scoped>
.backdiv {
  margin: 20px 0;
}

.inquire {
  display: flex;
  margin-bottom: 40px;

  .inquireInput {
    width: 400px;
    margin-right: 40px;
  }
}
</style>